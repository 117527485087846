import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { LightbulbIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Header from './components/Header'
import Todos from './components/Todos'
import Casting from './components/Casting'
import Network from './components/Network'
import TopPortfolios from './components/TopPortfolios'
import DirectorsCarousel from './components/DirectorsCarousel'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useUserStore } from 'store'
import { PATHS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
// import Charts from './components/Charts'

const Dashboard = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)

	useEffect(() => {
		const sectionId = hash?.slice(1)
		if (sectionId) {
			const section = document.getElementById(sectionId)
			section?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [hash])

	return (
		<Card
			step="dashboard"
			noHint
			headerChildrens={
				userRole !== CUSTOMER_ROLE.CONTRIBUTOR ? (
					<div>
						<Button
							onClick={() => {
								navigate('/' + PATHS.TIPS + '/' + userRole)
							}}
							style={{
								alignItems: 'center',
								gap: '8px',
							}}>
							{t('APP_TIPS_DROPDOWN')}
							<LightbulbIcon color="var(--mono400)" />
						</Button>
					</div>
				) : undefined
			}>
			<>
				<Header />
				<Todos />
				{/* <Charts /> */}
				<Casting />
				<Network />
				{userRole !== CUSTOMER_ROLE.AGENCY ? <TopPortfolios /> : null}
				<DirectorsCarousel />
			</>
		</Card>
	)
}

export default Dashboard
