import { CSSProperties } from 'react'
import cn from 'classnames'
import { PlusIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	label: string
	onClick?: () => void
	style?: CSSProperties
}

const CreateCardPlaceholder = ({
	label,
	onClick,
	style,
}: Props): JSX.Element => (
	<div onClick={onClick} className={cn('card', s.addItemCard)} style={style}>
		{onClick ? <PlusIcon color="var(--mono400)" /> : null}
		<span style={{ color: 'var(--mono400)' }}>{t(label)}</span>
	</div>
)

export default CreateCardPlaceholder
