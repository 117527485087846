import { useState } from 'react'
import { ArrowShortIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	contentKey: string
	type?: 'collapsing'
}

const ContentPageLayout = ({ contentKey, type }: Props): JSX.Element => {
	const text = t(`APP_${contentKey?.toUpperCase()}_DESCRIPTION`)
		?.replaceAll('h3', 'h4')
		?.replaceAll('h2', 'h3')
		?.replaceAll('h1', 'h2')

	const parsed =
		type === 'collapsing'
			? text
					?.split('<h3>')
					?.slice(1)
					?.map((text) => {
						const [title, desc] = text.split('</h3>')

						return { title, desc }
					})
			: null

	return (
		<div className={s.container}>
			<Card style={{ backgroundColor: 'transparent' }}>
				<>
					<h1 className="h1-m" style={{ paddingBottom: '16px' }}>
						{t(`APP_${contentKey?.toUpperCase()}_TITLE`)}
					</h1>
					{type === 'collapsing' && parsed ? (
						<>
							{parsed.map((text) => (
								<CollapseItem key={text.title} {...text} />
							))}
						</>
					) : (
						<div
							className={s.text}
							dangerouslySetInnerHTML={{
								__html: text,
							}}
						/>
					)}
				</>
			</Card>
		</div>
	)
}

export default ContentPageLayout

const CollapseItem = ({
	title,
	desc,
}: {
	title: string
	desc: string
}): JSX.Element => {
	const [collapse, setCollapse] = useState(true)

	return (
		<div className={s.text}>
			<div
				onClick={() => setCollapse((prev) => !prev)}
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
					cursor: 'pointer',
				}}>
				<div
					style={{
						display: 'flex',
					}}>
					<ArrowShortIcon
						style={{ transform: `rotate(${collapse ? '90deg' : '-90deg'})` }}
					/>
				</div>
				<h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
			</div>
			<div
				style={{
					height: collapse ? '0px' : 'auto',
					overflow: 'hidden',
				}}
				dangerouslySetInnerHTML={{ __html: desc }}></div>
		</div>
	)
}
