import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { RepeatIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Scenes from './components/Scenes'
import ProductionCard from 'components/ProductionCard'
import RoleCard from 'components/RoleCard'
import StepWrapper from 'components/Wizard/StepWrapper'
import SceneUploadSidebar from './components/SceneUploadSidebar'
import SubmitCastingSidebar from './components/SubmitCastingSidebar'
import ReapplyMessageSidebar from './components/ReapplyMessageSidebar'
import Button from 'components/UI/Button'
import Controls from 'components/Wizard/Controls'
import UploadTutorial from './components/UploadTutorial'
import useContent from 'hooks/api/useContent'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useRelation from 'hooks/api/useRelation'
import { getUser } from 'helpers/storage'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useUserStore } from 'store'
import { CASTING_STATUS, PATHS, RELATIONS } from 'types/enums'

const CastingUpload = (): JSX.Element => {
	const [showMessage, setShowMessage] = useState(false)
	const navigate = useNavigate()
	const { roleId } = useParams()
	const { content } = useContent(roleId)

	const { relation } = useRelation(
		RELATIONS.ACCESS,
		getUser()?.['Custom:User:Id'] || '',
		roleId || '',
	)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const daysLeft = dayjs(content?.ContentEventDeadline).diff(new Date(), 'days')

	return (
		<>
			<SceneUploadSidebar />
			<SubmitCastingSidebar />
			{relation?.extraData ? (
				<ReapplyMessageSidebar
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={relation?.extraData}
				/>
			) : null}
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<StepWrapper style={{ display: 'flex', position: 'relative' }}>
					<>
						<Card
							step="actor_role_details"
							className="cardWrapperSize"
							title={t('APP_ACTOR_ROLE_DETAILS_TITLE')?.replace(
								'{RoleName}',
								content?.['Globalizationen-USContentTitle'] || '',
							)}
							headerChildrens={
								relation?.extraData &&
								relation?.status === CASTING_STATUS.REAPPLIED ? (
									<Button
										style={{
											display: 'flex',
											gap: '10px',
											height: 'fit-content',
											whiteSpace: 'nowrap',
											color: 'var(--theme-primary)',
										}}
										onClick={() => setShowMessage(true)}>
										<div style={{ display: 'flex' }}>
											<RepeatIcon color="var(--theme-primary)" />
										</div>
										{t('APP_REAPPLY_MESSAGE_LABEL')}
									</Button>
								) : undefined
							}
							noHint
							style={{
								// height: 'calc(var(--contentCardHeight) + 64px)',
								marginRight: 'calc(var(--tutorialWidth) + 12px)',
							}}>
							<>
								<div className="grid3column" style={{ height: '100%' }}>
									<RoleCard
										content={content}
										type="card"
										showDescription
										showInfo
										hideDeadline
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'space-between',
											gap: '10px',
											gridColumn: '2 span',
										}}>
										<Scenes content={content} />
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '10px',
											}}>
											<span>{t('APP_DEADLINE_LABEL')}</span>
											<div
												style={{
													display: 'flex',
													gap: '10px',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}>
												<h4 style={{ textAlign: 'center' }}>
													{daysLeft + ' ' + t('APP_CASTING_DAYS_LEFT')}
												</h4>
												<UploadTutorial />
											</div>
										</div>
									</div>
								</div>
							</>
						</Card>
						<ProductionCard content={prod} type="aside" showDescription />
					</>
				</StepWrapper>
				<Controls
					nextClick={
						daysLeft < 0 && relation?.status === CASTING_STATUS.UPLOADING
							? undefined
							: () => EventBus.$emit('submitCastingScenes')
					}
					nextText="APP_SUBMIT"
					nextIcon={false}
					prevClick={() => {
						const { user } = useUserStore.getState()
						navigate(`/${user?.CustomerRole?.toLowerCase()}/${PATHS.DASHBOARD}`)
					}}
					prevText="APP_BACK"
				/>
			</div>
		</>
	)
}

export default CastingUpload
