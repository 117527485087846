import { useLocation } from 'react-router-dom'
import s from './index.module.scss'
import { useMemo } from 'react'
import { CUSTOMER_ROLE } from 'types/user'

import ActorCastingDashboard from './components/ActorCastingDashboard'
import AgencyCastingDashboard from './components/AgencyCastingDashboard'
import CastingDirectorCastingDashboard from './components/CastingDirectorCastingDashboard'
import ContributorCastingDashboard from './components/ContributorCastingDashboard'
import { DASHBOARD_SECTIONS } from 'types/enums'

const Casting = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const component = useMemo(() => {
		const components = {
			[CUSTOMER_ROLE.ACTOR]: <ActorCastingDashboard />,
			[CUSTOMER_ROLE.AGENCY]: <AgencyCastingDashboard />,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: <CastingDirectorCastingDashboard />,
			[CUSTOMER_ROLE.CONTRIBUTOR]: <ContributorCastingDashboard />,
		}

		return userRole ? components[userRole as CUSTOMER_ROLE] : null
	}, [userRole])

	return (
		<div className={s.container} id={DASHBOARD_SECTIONS.CASTING}>
			{component}
		</div>
	)
}

export default Casting
