import { createWithEqualityFn } from 'zustand/traditional'
import { PlayerStore } from 'types/store/playerStore'

const initialStore = {
	playing: false,
	muted: false,
	speed: 1,
	volume: 0.5,
	visible: true,
	fullscreen: false,
	caption: 'off',
}

const useStore = createWithEqualityFn<PlayerStore>(
	(set) => ({
		...initialStore,
		setValue: (value) =>
			set((state) => ({
				...state,
				...value,
			})),
		resetControls: () => set(initialStore),
	}),
	Object.is,
)

export default useStore
