import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PlusIcon } from 'assets/icons'
import RoleCard from 'components/RoleCard'
import Select from 'components/UI/Select'
import useAccessRelation from 'hooks/api/useAccessRelation'
import useCollection from 'hooks/api/useCollection'
import useContentSearch from 'hooks/api/useContentSearch'
import { t } from 'helpers'
import { Content, SelectOption } from 'types/app'

interface Props {
	roleSearch: string
	openMessage: (c: Content, p?: Content) => void
}

const CDList = ({ roleSearch, openMessage }: Props): JSX.Element => {
	const [prodSearch, setProdSearch] = useState('')

	const { contents } = useContentSearch(true, 'Collection')

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return (
		<>
			<Select
				name="productions"
				options={productions}
				error=""
				label="APP_PRODUCTION_SELECT_LABEL"
				onChange={(e) => {
					setProdSearch(e.target.value)
				}}
				inputProps={{
					value: prodSearch,
				}}
			/>
			{contents
				?.filter((content) =>
					prodSearch ? content?.ContentId === prodSearch : true,
				)
				?.map((content) => (
					<RoleList
						key={content.ContentId}
						production={content}
						roleSearch={roleSearch}
						openMessage={openMessage}
					/>
				))}
		</>
	)
}

export default CDList

const RoleList = ({
	production,
	roleSearch,
	openMessage,
}: {
	production: Content
	roleSearch: string
	openMessage: (c: Content, p?: Content) => void
}): JSX.Element => {
	const { collection } = useCollection(production)
	const { profileId } = useParams()

	const { data } = useAccessRelation()

	const alreadyInvitedRoles = data
		?.filter((rel) => rel.targetId === profileId)
		?.map((rel) => rel.sourceId)

	return (
		<>
			{collection
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						?.includes(roleSearch?.toLowerCase()),
				)
				?.map((content) => (
					<RoleCard
						key={content?.ContentId}
						content={content}
						type="horizontal"
						showProd
						children={
							<>
								{alreadyInvitedRoles?.includes(content.ContentId) ? (
									<div
										className="hoverButtonWrapper"
										style={{ cursor: 'default' }}>
										<PlusIcon style={{ transform: 'rotate(45deg)' }} />
										<span>{t('APP_ALREADY_INVITED_TO_ROLE')}</span>
									</div>
								) : (
									<div
										className="hoverButtonWrapper"
										onClick={() => openMessage(content, production)}>
										<PlusIcon />
										<span>{t('APP_INVITE_TO_ROLE')}</span>
									</div>
								)}
							</>
						}
					/>
				))}
		</>
	)
}
