import classNames from 'classnames'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EditIcon, PlusIcon, RepeatIcon } from 'assets/icons'
import { contentDelete, copyContent } from 'api/content'
import { multiRelationUpdate } from 'api/relations'
import Card from 'components/Wizard/Card'
import SceneCard from 'components/SceneCard'
import Button from 'components/UI/Button'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useCollection from 'hooks/api/useCollection'
import useContentAccess from 'hooks/useContentAccess'
import { t } from 'helpers'
import { orderScenes } from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useModalStore, useProductionStore } from 'store'
import { Content } from 'types/app'
import { PROJECT_ACCESS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import { notif } from 'helpers/notif'

const RoleScenes = (): JSX.Element => {
	const { productionRoleId, productionId } = useParams()

	const { collection: contents = [], refresh } = useCollection(
		{ ContentId: productionRoleId } as Content,
		true,
	)

	const { access, role: userRole } = useContentAccess(productionId)
	const disabled = access === PROJECT_ACCESS.READ
	const full = access === PROJECT_ACCESS.FULLACCESS

	useEffect(() => {
		EventBus.$on('refreshScenes', () => {
			setTimeout(() => {
				refresh()
			}, 1000)
		})

		return () => {
			EventBus.$off('refreshScenes')
		}
	}, [refresh])

	return (
		<Card step="role_scenes">
			<>
				<div className={classNames('grid2column')}>
					{full || userRole === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
						<CreateCardPlaceholder
							label="APP_INTRO_LABEL"
							onClick={() => {
								const { setOpenAddSceneSidebar } = useAppStore.getState()
								setOpenAddSceneSidebar(true)
							}}
						/>
					) : null}
					{orderScenes([], contents)?.map((scene) => (
						<SceneItem
							scene={scene}
							key={scene?.ContentId}
							disabled={disabled}
						/>
					))}
				</div>
			</>
		</Card>
	)
}

export default RoleScenes

const SceneItem = ({
	scene,
	disabled,
}: {
	scene: Content
	disabled?: boolean
}): JSX.Element => {
	const { productionRoleId, productionId } = useParams()

	const { access, role } = useContentAccess(productionId)
	const full = access === PROJECT_ACCESS.FULLACCESS

	const editScene = (content: Content): void => {
		const { setCurrentScene } = useProductionStore.getState()
		const { setOpenAddSceneSidebar, addSceneSidebar } = useAppStore.getState()
		setOpenAddSceneSidebar(true)
		setCurrentScene(content.ContentId)
		addSceneSidebar.setScene({
			title: content?.['Globalizationen-USContentTitle'],
			code: content?.ContentTapeType,
			dress: content?.ContentRoleDress,
			instructions: content?.ContentLabelInstruction?.split(',') || [],
			duration: content?.Duration,
		})
	}

	const deleteScene = (content: Content): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_SCENE_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									const { setLoading } = useProductionStore.getState()
									try {
										await contentDelete(content.ContentId)
									} catch (error) {
										console.log('error:', error)
										notif('error', t('APP_DELETE_SCENE_ERROR'))
									} finally {
										setLoading(false)
										setModal(null)
										EventBus.$emit('refreshScenes')
									}
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	const copyScene = async (content: Content): Promise<void> => {
		const { setLoading } = useProductionStore.getState()
		setLoading(true)
		try {
			const response = await copyContent(content.ContentId, {
				keepAssets: true,
				ownerId: content?.ContentOwnerId || '',
			})
			if (response?.id) {
				multiRelationUpdate(productionRoleId || '', response.id)
			}
		} catch (error) {
			console.log('error:', error)
			notif('success', t('APP_COPY_SCENE_ERROR'))
		} finally {
			setLoading(false)
			EventBus.$emit('refreshScenes')
		}
	}

	return (
		<SceneCard
			content={scene}
			style={{
				height: '100%',
				maxWidth: 'unset',
				minHeight: '96px',
			}}
			children={
				<>
					{disabled ? null : (
						<div
							className="hoverButtonWrapper hoverButtonIcon"
							onClick={() => editScene(scene)}>
							<EditIcon />
						</div>
					)}
					{full || role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
						<>
							<div
								className="hoverButtonWrapper hoverButtonIcon"
								onClick={() => copyScene(scene)}>
								<RepeatIcon />
							</div>
							<div
								className="hoverButtonWrapper hoverButtonIcon"
								onClick={() => deleteScene(scene)}>
								<PlusIcon style={{ transform: 'rotate(45deg)' }} />
							</div>
						</>
					) : undefined}
				</>
			}
		/>
	)
}
