import { useLocation, useNavigate } from 'react-router-dom'
import { t } from 'helpers'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const Footer = (): JSX.Element => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const home = pathname === '/' || pathname?.includes(PATHS.HOME)

	const links = [
		{
			link: PATHS.TERMS,
			label: t('APP_FOOTER_TERMS'),
		},
		{
			link: PATHS.PRIVACY,
			label: t('APP_FOOTER_PRIVACY'),
		},
		{
			link: PATHS.COOKIES,
			label: t('APP_FOOTER_COOKIES'),
		},
		{
			link: PATHS.ABOUT,
			label: t('APP_FOOTER_ABOUT_US'),
		},
		{
			link: PATHS.CONTACT,
			label: t('APP_FOOTER_CONTACT_US'),
		},
	]

	return (
		<div className={s.footer}>
			<div className={s.wrapper} style={home ? { display: 'flex' } : {}}>
				{home ? (
					<div className={s.link}>
						<span className="body2-m">{`© ${t('APP_COPYRIGHT_LABEL')}`}</span>
					</div>
				) : (
					links?.map(({ label, link }) => (
						<div key={label} className={s.link} onClick={() => navigate(link)}>
							<span className="body2-m">{label}</span>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default Footer
