export const UploadIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M7.87109 9.75391C7.51562 9.42578 7.51562 8.85156 7.87109 8.52344L11.3711 5.02344C11.5352 4.85938 11.7539 4.75 12 4.75C12.2188 4.75 12.4375 4.85938 12.6016 5.02344L16.1016 8.52344C16.457 8.85156 16.457 9.42578 16.1016 9.75391C15.7734 10.1094 15.1992 10.1094 14.8711 9.75391L12.875 7.75781V14.375C12.875 14.8672 12.4648 15.25 12 15.25C11.5078 15.25 11.125 14.8672 11.125 14.375V7.75781L9.10156 9.75391C8.77344 10.1094 8.19922 10.1094 7.87109 9.75391ZM18.125 14.375C18.5898 14.375 19 14.7852 19 15.25V17.875C19 18.3672 18.5898 18.75 18.125 18.75H5.875C5.38281 18.75 5 18.3672 5 17.875V15.25C5 14.7852 5.38281 14.375 5.875 14.375H10.25C10.25 15.3594 11.0156 16.125 12 16.125C12.957 16.125 13.75 15.3594 13.75 14.375H18.125ZM16.8125 17.2188C17.168 17.2188 17.4688 16.9453 17.4688 16.5625C17.4688 16.207 17.168 15.9062 16.8125 15.9062C16.4297 15.9062 16.1562 16.207 16.1562 16.5625C16.1562 16.9453 16.4297 17.2188 16.8125 17.2188Z"
			fill={color}
		/>
	</svg>
)
