import { useShallow } from 'zustand/react/shallow'
import { ArrowSmallIcon } from 'assets/icons'
import { t } from 'helpers'
import { useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

type DataType = {
	value: number
	label: string
	changePercents: number
	changeLabel: string
}

const KPI = (): JSX.Element => {
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)

	const actorData: DataType[] = [
		{
			value: 326,
			label: 'Profile views',
			changeLabel: 'Last week',
			changePercents: 251,
		},
		{
			value: 12,
			label: 'Invitations',
			changeLabel: 'Last week',
			changePercents: 118,
		},
	]

	const cdData: DataType[] = [
		{
			value: 30,
			label: 'Members',
			changeLabel: 'Last week',
			changePercents: 14,
		},
		{
			value: 12,
			label: 'Projects',
			changeLabel: 'Last week',
			changePercents: 4,
		},
		{
			value: 29,
			label: 'Roles',
			changeLabel: 'Last week',
			changePercents: 6,
		},
		{
			value: 12,
			label: 'Invitations',
			changeLabel: 'Last week',
			changePercents: 118,
		},
		{
			value: 2,
			label: 'Auditioness',
			changeLabel: 'Last week',
			changePercents: 0,
		},
	]
	const contributorsData: DataType[] = [
		{
			value: 12,
			label: 'Projects',
			changeLabel: 'Last week',
			changePercents: 4,
		},
	]
	const agencyData: DataType[] = [
		{
			value: 30,
			label: 'Members',
			changeLabel: 'Last week',
			changePercents: 14,
		},
		{
			value: 12,
			label: 'Invitations',
			changeLabel: 'Last week',
			changePercents: 118,
		},
		{
			value: 2,
			label: 'Auditioness',
			changeLabel: 'Last week',
			changePercents: 0,
		},
	]

	const values: { [key in CUSTOMER_ROLE]: DataType[] } = {
		[CUSTOMER_ROLE.ACTOR]: actorData,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: cdData,
		[CUSTOMER_ROLE.CONTRIBUTOR]: contributorsData,
		[CUSTOMER_ROLE.AGENCY]: agencyData,
	}

	const data = values[userRole as CUSTOMER_ROLE]

	return (
		<div className={s.dataWrapper}>
			{data?.map((item) => (
				<Item item={item} key={item.value + item.label} />
			))}
			{/* {actor ? <Director /> : null} */}
			{/* <Director /> */}
		</div>
	)
}

// const Director = (): JSX.Element => {
// 	const user = useUserStore(useShallow((state) => state.user))

// 	const { data } = useRelations(
// 		RELATIONS.ORGANIZATION,
// 		user?.ContentId || '',
// 		'Customer',
// 	)
// 	console.log('data?.[0]?.targetId:', data?.[0]?.targetId)
// 	const { data: orgs } = useRelations(
// 		RELATIONS.ORGANIZATION,
// 		data?.[0]?.targetId || '',
// 		'Customer',
// 	)
// 	console.log('data:', data)
// 	console.log('orgs:', orgs)

// 	return user ? <NetworkCard data={user} /> : <></>
// }

const Item = ({ item }: { item: DataType }): JSX.Element => {
	const color = item.changePercents > 0 ? 'var(--success)' : 'var(--error)'

	return (
		<div className={s.dataItem}>
			<h3>{item.value}</h3>
			<span className="caption-s" style={{ color: 'var(--mono200)' }}>
				{t(item.label)}
			</span>
			<div className={s.change}>
				<div>
					<ArrowSmallIcon
						color={color}
						style={
							color === 'var(--error)' ? { transform: 'rotate(180deg)' } : {}
						}
					/>
				</div>
				<span className="caption-r" style={{ color, fontSize: '11px' }}>
					{item.changePercents?.toString()?.replace('-', '') + '%'}
				</span>
				<span
					className="caption-r"
					style={{
						fontSize: '11px',
						color: 'var(--mono400)',
						marginLeft: '8px',
					}}>
					{t(item.changeLabel)}
				</span>
			</div>
		</div>
	)
}

export default KPI
