import { useNavigate } from 'react-router-dom'
import { ShowreelsIcon } from 'assets/icons'
import RelationContentList from 'components/RelationContentList'
import { getUser } from 'helpers/storage'
import { useCastingStore, useUserStore } from 'store'
import { Content, Relation } from 'types/app'
import { PATHS } from 'types/enums'

interface Props {
	relations: Relation[]
	place?: 'sidebar' | 'default'
}

const Audition = ({ place, relations }: Props): JSX.Element => {
	const navigate = useNavigate()

	const openCasting = (
		content: Content,
		_: string | undefined,
		prodId: string | undefined,
	): void => {
		const { user } = useUserStore.getState()
		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${prodId}/${
				content.ContentId
			}/${getUser()?.['Custom:User:Id'] || ''}`,
		)
	}

	return (
		<RelationContentList
			relations={relations}
			showUser={false}
			place={place}
			actions={[
				{
					label: 'APP_OPEN_OVERVIEW',
					icon: <ShowreelsIcon />,
					onClick: openCasting,
				},
			]}
		/>
	)
}

export default Audition
