import { useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import { ArrowShortIcon } from 'assets/icons'
import FieldsSelector from 'components/FieldsSelector'
import { capitalize, t } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { usePortfolioStore } from 'store'
import { AttributeSelector, FilterSection, ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES, UPDATE_STRATEGY } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	section: FilterSection
	searchFields: string
}

const Section = ({ section, searchFields }: Props): JSX.Element => {
	const [expanded, setExpanded] = useState(!section?.hideByDefault)
	const headerHeight = useRef(24)
	const [values] = usePortfolioStore(
		useShallow((state) => [
			(state.cardValues[state.modalType] as { [key: string]: string }) || {},
		]),
	)
	const fields = section?.fields?.map((field) => {
		return field?.name
	})

	const filledCount = ((values?.tags as unknown as string[][]) || [[]])?.filter(
		(tags) => fields?.includes(tags?.[0]),
	)?.length

	const onExpand = (): void => {
		setExpanded((prev) => !prev)
	}

	useEffect(() => {
		if (section?.hideByDefault !== undefined)
			setExpanded(!section?.hideByDefault)
	}, [section?.hideByDefault])

	useEffect(() => {
		if (searchFields) setExpanded(true)
	}, [searchFields])

	return (
		<div
			id={section.title + '_wrapper'}
			className={s.section}
			style={{
				height: expanded ? '100%' : headerHeight.current,
				transition: 'none',
			}}>
			{section?.title ? (
				<div className={s.header} onClick={onExpand}>
					<span className="body2-b">{t(section.title)}</span>
					{filledCount > 0 ? (
						<div className={s.count}>
							<span className="caption-b">{filledCount}</span>
						</div>
					) : null}
					<div className={s.expand}>
						<div>
							<ArrowShortIcon
								style={{
									transform: `rotate(${expanded ? '-90deg' : '90deg'})`,
								}}
							/>
						</div>
					</div>
				</div>
			) : null}
			{expanded ? (
				<div className={s.fieldsWrapper}>
					{section?.fields?.length === 2 &&
					section?.fields[0]?.type === 'checkbox' &&
					section?.fields[1]?.type === 'select' ? (
						<FieldSection
							field={section?.fields[0]}
							key={section?.fields?.[0]?.name}
							searchFields={searchFields}
							multiple
						/>
					) : (
						section?.fields?.map((field) => (
							<FieldSection
								field={field}
								key={field.name}
								searchFields={searchFields}
							/>
						))
					)}
				</div>
			) : null}
		</div>
	)
}

export default Section

const FieldSection = ({
	field,
	searchFields,
	multiple,
}: {
	field: AttributeSelector
	searchFields: string
	multiple?: boolean
}): JSX.Element => {
	const [values] = usePortfolioStore(
		useShallow((state) => [
			(state.cardValues[state.modalType] as { [key: string]: string }) || {},
		]),
	)

	const value = ((values?.tags as unknown as string[][]) || [[]])
		?.filter((tags) => tags?.[0] === field?.name)
		?.map((tags) => tags?.[1])

	const getAttributeKey = (name: string): string | undefined =>
		Object.entries({
			...attributeBasicFields,
			...attributeSpecialFields,
			...skillFields,
		}).find(([_, value]) =>
			value?.selectors?.find((selector) => selector?.name === name),
		)?.[0]

	const onChange = (
		tags: {
			[key: string]: string | number | boolean | string[]
		},
		currentValue?: string,
	): void => {
		const { setCardValues, cardValues, updateFields } =
			usePortfolioStore.getState()
		const parsed = Object.values(tags)

		const userValue = cardValues[field?.fieldName || '']
		const prevUserValue = (Object.values(userValue)?.[0] as string[]) || []

		if (typeof parsed?.[0] === 'string' || field?.name?.includes('Tattoo')) {
			const name = field?.name
			const attributeKey = getAttributeKey(name)
			const currentItem = [
				name,
				field?.name?.includes('Tattoo')
					? (parsed as string[])?.[0]?.[(parsed as string[])?.[0]?.length - 1]
					: parsed?.[0],
			]
			const prevValue = (values?.tags as unknown as string[][])?.find(
				(tags) => tags?.[0] === name,
			)
			const newValues = [
				...(values.tags
					? (values.tags as unknown as string[][]).filter(
							(tags) => tags?.[0] !== name,
					  )
					: []),
				currentItem,
			]

			setCardValues({
				tags: newValues as unknown as string,
			})
			if (currentValue) {
				partialContentUpdate(
					values?.contentId,
					{
						labels: {
							[capitalize(PORTFOLIO_TYPES.ATTRIBUTE)]: [
								`${field?.name}:${currentValue}`,
							],
						},
					},
					`?strategy=${UPDATE_STRATEGY.ADD}`,
				)
			}
			updateFields([
				{
					Name: field.name,
					Value: field?.name?.includes('Tattoo')
						? (parsed as string[])?.[0]?.[0]
							? (currentValue as string)
							: ''
						: (currentItem?.[1] as string),
				},
			])
			setCardValues(
				{
					[field.name]: field?.name?.includes('Tattoo')
						? (parsed as string[])?.[0]?.[0]
							? (currentValue as string)
							: ''
						: (currentItem?.[1] as string),
				},
				attributeKey,
			)
			if (prevValue) {
				partialContentUpdate(
					values?.contentId,
					{
						labels: {
							[capitalize(PORTFOLIO_TYPES.ATTRIBUTE)]: [
								`${name}:${prevValue?.[1]}`,
							],
						},
					},
					`?strategy=${UPDATE_STRATEGY.REMOVE}`,
				)
			}
		} else {
			const name = field?.name
			const attributeKey = getAttributeKey(name)

			const currentItem = (parsed?.[0] as string[])?.[
				(parsed?.[0] as string[])?.length - 1
			]
			const strategy =
				currentItem === currentValue
					? UPDATE_STRATEGY.ADD
					: UPDATE_STRATEGY.REMOVE

			const newValues = [
				...((values.tags as unknown as string[]) || []).filter(
					(tags) => tags?.[0] !== name,
				),
				...(parsed?.[0] as string[]).map((v) => [name, v]),
			]

			if (values?.contentId) {
				const skills = Object.keys(skillFields)
				partialContentUpdate(
					values?.contentId,
					{
						labels: {
							[capitalize(
								skills.includes(field.fieldName || '')
									? PORTFOLIO_TYPES.SKILL
									: PORTFOLIO_TYPES.ATTRIBUTE,
							)]: [`${field?.name}:${currentValue}`],
						},
					},
					`?strategy=${strategy}`,
				)
			}
			const userValue = multiple
				? filterDuplicates(
						[
							...(prevUserValue as unknown as ValueLevel[]).filter(
								(v) => v?.Value !== currentValue,
							),
							...parsed.map((v) => ({
								Value: (v as string[])?.[
									(v as string[]).length - 1
								] as unknown as string,
								Level: '',
							})),
						],
						'Value',
				  )
				: [
						...new Set(
							[
								...(prevUserValue || []).filter((v) => v !== currentValue),
								...parsed,
							].flat(),
						),
				  ]
			updateFields([
				{
					Name: field.name,
					Value: JSON.stringify(userValue),
				},
			])
			setCardValues(
				{
					[field.name]: userValue as ValueLevel[],
				},
				attributeKey,
			)

			setCardValues({
				tags: newValues as unknown as string,
			})
		}
	}

	return !searchFields ||
		field?.name?.toLowerCase().includes(searchFields.toLowerCase()) ? (
		<FieldsSelector
			field={field}
			value={
				((field?.type === 'checkbox' ? value : value?.[0]) as string) || ''
			}
			setValue={onChange}
		/>
	) : (
		<></>
	)
}
