import { Fragment } from 'react'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import RoleCard from 'components/RoleCard'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { RELATIONS } from 'types/enums'
import { Content, Relation } from 'types/app'
import s from './index.module.scss'

export interface RelationListItemProps {
	actions?: {
		label: string
		icon: JSX.Element
		onClick: (
			content: Content,
			userId?: string,
			prodId?: string,
			relation?: Relation,
		) => void
	}[]
	showUser?: boolean
	showInfo?: boolean
	cardType?: string
}

interface Props extends RelationListItemProps {
	relations: Relation[]
	place?: 'sidebar' | 'default'
}

const RelationContentList = ({
	relations,
	actions,
	showUser = true,
	showInfo = true,
	place = 'default',
	cardType,
}: Props): JSX.Element => {
	const [search] = useAppStore(
		useShallow((state) => [state.localSearchSidebar?.search || '']),
	)

	return (
		<div className={classNames(s.container, s[place])}>
			{relations?.map((relation) => (
				<RelationList
					key={relation.sourceId + relation.targetId}
					cardType={cardType}
					relation={relation}
					showUser={showUser}
					showInfo={showInfo}
					actions={actions}
					search={search}
				/>
			))}
		</div>
	)
}

export default RelationContentList

const RelationList = ({
	relation,
	...props
}: RelationListItemProps & {
	relation: Relation
	search: string
}): JSX.Element => {
	const { content } = useContent(relation.sourceId)

	return content &&
		content?.['Globalizationen-USContentTitle']
			?.toLowerCase()
			?.includes(props.search) ? (
		<RelationListItem content={content} relation={relation} {...props} />
	) : (
		<></>
	)
}

export const RelationListItem = ({
	content,
	actions,
	relation,
	showUser,
	showInfo,
	cardType,
}: RelationListItemProps & {
	content: Content
	relation: Relation
}): JSX.Element => {
	const [projectId] = useAppStore(
		useShallow((state) => [state.localSearchSidebar?.projectId]),
	)
	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const relatedUserId = showUser ? relation?.targetId : undefined

	return !projectId || projectId === prod?.ContentId ? (
		<RoleCard
			key={content.ContentId}
			content={content}
			showInfo={showInfo}
			relatedUserId={relatedUserId}
			type={cardType ? (cardType as 'casting') : undefined}
			relation={relation}
			children={
				<>
					{actions?.map((action) => (
						<Fragment key={action?.label}>
							<div
								className="hoverButtonWrapper"
								onClick={() =>
									action.onClick(
										content,
										relatedUserId,
										prod?.ContentId,
										relation,
									)
								}>
								{action.icon}
								<span>{t(action.label)}</span>
							</div>
						</Fragment>
					))}
				</>
			}
		/>
	) : (
		<></>
	)
}
