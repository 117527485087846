import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon, InfoFilledIcon } from 'assets/icons'
import { getProfileReadiness, t } from 'helpers'
import { useUserStore } from 'store'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const ProfileReadiness = (): JSX.Element => {
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))

	const readiness = getProfileReadiness(
		user?.CustomerPortfolioReadiness ? +user?.CustomerPortfolioReadiness : 0,
	)

	const navigateToPortfolio = (): void => {
		navigate('/' + user?.CustomerRole?.toLowerCase() + '/' + PATHS.PORTFOLIO)
	}

	const explanations = [
		{
			color: 'var(--error)',
			label: 'APP_EXPLANATION_60',
		},
		{
			color: 'var(--warning)',
			label: 'APP_EXPLANATION_6090',
		},
		{
			color: 'var(--success)',
			label: 'APP_EXPLANATION_90',
		},
	]

	return (
		<div className={s.container}>
			<div className={s.lineWrapper}>
				<div className={s.titles}>
					<div className={s.label}>
						<span className="body1-b">
							{t('APP_PROFILE_COMPLETE_LABEL') + ':'}
						</span>
						<span style={{ color: 'var(--mono200)' }}>
							{readiness.value + '%'}
						</span>
					</div>
				</div>
				<div className={s.line}>
					<div
						style={{
							backgroundColor: readiness.color,
							width: readiness.value + '%',
						}}
					/>
				</div>
			</div>

			<div className={s.lineHintWrapper}>
				<div>
					<div style={{ display: 'flex' }}>
						<InfoFilledIcon />
					</div>
					<span className="body2-r" style={{ color: 'var(--mono300)' }}>
						{t('APP_PROFILE_LINE_HINT')}
					</span>
				</div>
				<div style={{ cursor: 'pointer' }} onClick={navigateToPortfolio}>
					<span style={{ color: 'var(--mono200)' }}>
						{t('APP_FINISH_PROFILE')}
					</span>
					<div style={{ display: 'flex' }}>
						<ArrowShortIcon />
					</div>
				</div>
			</div>

			<div className="grid3column">
				{explanations?.map((exp) => (
					<div key={exp.label} className={s.expWrapper}>
						<div className={s.line}>
							<div
								style={{
									backgroundColor: exp.color,
									width: '100%',
								}}
							/>
						</div>
						<span style={{ color: exp.color }}>{t(exp.label)}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default ProfileReadiness
