import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import Sidebar from 'components/Sidebar'
import ProductionCard from 'components/ProductionCard'
import useContent from 'hooks/api/useContent'
import { optionsParser, t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

const ProductionInfoSidebar = (): JSX.Element => {
	// const actor = useUserStore(
	// 	useShallow(
	// 		(state) =>
	// 			state.user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR,
	// 	),
	// )
	const [prodId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.prodInfoSidebar, state.setProdInfoSidebar]),
	)
	const { content } = useContent(prodId)

	// const prodTypes = optionsParser(DROPDOWN_OPTIONS.PRODUCTION_TYPES)

	// const rehearsalDate = content?.ContentEventRehearsalDate?.includes('[')
	// 	? (JSON.parse(content?.ContentEventRehearsalDate) as string[])
	// 	: ''
	// const filmingDate = content?.ContentEventFilmingDate?.includes('[')
	// 	? (JSON.parse(content?.ContentEventFilmingDate) as string[])
	// 	: ''

	return (
		<Sidebar
			open={!!prodId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: 'APP_PRODUCTION_INFO_SIDEBAR_TITLE',

				// title: actor
				// 	? 'APP_PRODUCTION_INFO_SIDEBAR_TITLE'
				// 	: content?.['Globalizationen-USContentTitle'] || '',
				// description: actor
				// 	? ''
				// 	: content?.['Globalizationen-USContentDescription'],
			}}>
			<>
				{/* {actor ? ( */}
				<ProductionCard
					content={content}
					showDescription
					type="aside"
					style={{
						position: 'relative',
						maxWidth: 'unset',
					}}
				/>
				{/* ) : (
					<div className={s.wrapper}>
						{content?.ContentProductionType ? (
							<div className={s.item}>
								<span className="body1-b">
									{t('APP_PRODUCTION_TYPE_LABEL')}
								</span>
								<span className="body2-s">
									{
										prodTypes.find(
											(option) =>
												option.value === content?.ContentProductionType,
										)?.label
									}
								</span>
							</div>
						) : null}
						{content?.ContentLegalNDARequired === 'true' ? (
							<div className={s.item}>
								<span className="body1-b">{`${t('APP_NDA_INPUT_LABEL')}`}</span>
							</div>
						) : null}
						{content?.ContentLabelPlace ? (
							<div className={s.item}>
								<span className="body1-b">{t('APP_LOCATION_LABEL')}</span>
								<span className="body2-s">{content?.ContentLabelPlace}</span>
							</div>
						) : null}
						{content?.ContentLabelPlace ? (
							<div className={s.item}>
								<span className="body1-b">{t('APP_DIRECTOR_NAME_LABEL')}</span>
								<span className="body2-s">{content?.ContentDirectorName}</span>
							</div>
						) : null}
						{content?.ContentImdbUrl ? (
							<div className={s.item}>
								<span className="body1-b">{t('APP_IMDBLINK_LABEL')}</span>
								<span className="body2-s">{content?.ContentImdbUrl}</span>
							</div>
						) : null}

						{content?.ContentEventRehearsalDate ? (
							<div className={s.item}>
								<span className="body1-b">{t('APP_REHEARSAL_DATE_LABEL')}</span>
								<span className="body2-s">
									{dayjs(rehearsalDate?.[0]).format('MM/DD/YYYY') +
										' - ' +
										dayjs(rehearsalDate?.[1]).format('MM/DD/YYYY')}
								</span>
							</div>
						) : null}
						{content?.ContentEventFilmingDate ? (
							<div className={s.item}>
								<span className="body1-b">{t('APP_FILMING_DATE_LABEL')}</span>
								<span className="body2-s">
									{dayjs(filmingDate?.[0]).format('MM/DD/YYYY') +
										' - ' +
										dayjs(filmingDate?.[1]).format('MM/DD/YYYY')}
								</span>
							</div>
						) : null}
					</div>
				)} */}
			</>
		</Sidebar>
	)
}

export default ProductionInfoSidebar
