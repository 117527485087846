import { useEffect, useMemo } from 'react'
import JSConfetti from 'js-confetti'
import { EventBus } from 'helpers/EventBus'

const Confetti = (): JSX.Element => {
	const jsConfetti = useMemo(() => new JSConfetti(), [])

	useEffect(() => {
		EventBus.$on('confetti', () => {
			jsConfetti.addConfetti({
				confettiColors: [
					'rgb(255, 170, 0)',
					'rgb(219, 0, 255)',
					'rgb(0, 209, 255)',
					'rgb(39, 255, 190)',
				],
			})
		})

		return () => {
			EventBus.$off('confetti')
			jsConfetti.clearCanvas()
		}
	}, [jsConfetti])

	return <></>
}

export default Confetti
