import { useShallow } from 'zustand/react/shallow'
import { useEffect, useRef } from 'react'
import Card from 'components/Wizard/Card'
import HeadshotUpload from 'components/HeadshotUpload'
import { usePortfolioStore, useUserStore } from 'store'
import { User } from 'types/user'
import s from './index.module.scss'

const Photos = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const dataLoadRef = useRef(false)
	const profileName = 'Customer:Image:SquareUrl'
	const mainName = 'Customer:Image:PortraitUrl'

	const profileValue = usePortfolioStore(
		useShallow((state) =>
			state.cardValues?.[profileName] &&
			state?.cardValues?.[profileName]?.[profileName]
				? (state.cardValues[profileName][profileName] as File)
				: undefined,
		),
	)
	const mainValue = usePortfolioStore(
		useShallow((state) =>
			state.cardValues?.[mainName] && state?.cardValues?.[mainName]
				? (state.cardValues[mainName][mainName] as File)
				: undefined,
		),
	)

	useEffect(() => {
		if (user && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setCardValues } = usePortfolioStore.getState()
			const names = ['Customer:Image:SquareUrl', 'Customer:Image:PortraitUrl']
			names.forEach((name) => {
				setCardValues(
					{
						[name]:
							user[name?.replaceAll(':', '') as keyof User]?.toString() || '',
					},
					name,
				)
			})
		}
	}, [user])

	return (
		<div className={s.container}>
			<Card
				wrapperStyle={{
					justifyContent: 'space-evenly',
				}}>
				<>
					<HeadshotUpload
						id={profileName}
						form="circle"
						itemTitle="APP_PROFILEPICTURE_TITLE"
						hintText="APP_PROFILEPICTURE_HINT"
						qualityText="APP_PROFILEPICTURE_QUALITY"
						label="APP_PROFILEPICTURE_LABEL"
						value={profileValue}
					/>
					<HeadshotUpload
						id={mainName}
						form="rectangle"
						itemTitle="APP_MAINHEADSHOT_TITLE"
						hintText="APP_MAINHEADSHOT_HINT"
						qualityText="APP_MAINHEADSHOT_QUALITY"
						label="APP_MAINHEADSHOT_LABEL"
						value={mainValue}
					/>
				</>
			</Card>
		</div>
	)
}

export default Photos
