import { useCallback } from 'react'
import ItemValue from '../ItemValue'
import { capitalize, getTaggedContent, optionsParser, t } from 'helpers'
import { useUserStore } from 'store'
import { User } from 'types/user'
import { AttributeCardType, Content, SelectOption } from 'types/app'
import s from './index.module.scss'

interface Props {
	field: AttributeCardType
	user: User
	mediaItems: Content[]
}

const ItemWrapper = ({ field, user, mediaItems }: Props): JSX.Element => {
	const value =
		user[field.selectors[0]?.name?.replaceAll(':', '') as keyof User]

	const options = optionsParser(t(field.selectors[0].optionName || ''))

	const selectedOptions =
		Object.values(options?.[0])?.filter((v) => !!v)?.length && value
			? options?.filter((v) => JSON.stringify(value).includes(v.value))
			: ([
					{
						value: value,
						label: value,
					},
			  ] as SelectOption[])

	const selected = useCallback(
		(attribute: string) =>
			mediaItems?.filter((content) => getTaggedContent(attribute, content)),
		[mediaItems],
	)

	return (
		<div className={s.container}>
			<span className="caption-m">{`${t(field.selectors[0].label)}`}</span>
			<div className={s.wrapper}>
				{selectedOptions?.map((option, index, array) => (
					<ItemValue
						key={option.value + '-' + option.label}
						index={index}
						array={array}
						option={option}
						showFlag={field?.value?.toLowerCase()?.includes('language')}
						name={field?.selectors?.[0]?.name}
						onClick={() => {
							const { setActiveAttribute } = useUserStore.getState()
							setActiveAttribute(
								`${capitalize(field.value)}:${option.value}`,
								selected(`${capitalize(field.value)}:${option.value}`),
							)
						}}
					/>
				))}
			</div>
		</div>
	)
}

export default ItemWrapper
