import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import { getDefaultUserPath, t } from 'helpers'
import { useCastingStore } from 'store'
import s from './index.module.scss'

const Back = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<div
			className={classNames(s.container, 'castingToolWrapper')}
			data-role="casting-back"
			onClick={() => {
				const { castingOverviewRedirectionLink } = useCastingStore.getState()
				navigate(castingOverviewRedirectionLink || getDefaultUserPath())
			}}>
			<div className={s.wrapper}>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon style={{ transform: 'rotate(180deg)' }} />
				</div>
				{/* <span>{t('APP_BACK')}</span> */}
			</div>
		</div>
	)
}

export default Back
