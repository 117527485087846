import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { propertiesParser } from 'helpers/propertiesParser'
import getBrowserType from 'helpers/getBrowserType'
import { useAppStore } from 'store'
import { ContentAuthorizeType } from 'types/content'
import { CollectionsType } from 'types/app'
import { KeyedMutator } from 'swr'

type ReturnData = {
	isLoading: boolean
	error: string
	data: ContentAuthorizeType | undefined
	refresh: KeyedMutator<CollectionsType>
}

export default (contentId?: string, subtype?: string): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const url = endpoints?.CustomApplicationV3AuthorizeUrl || ''

	const drmType =
		/* getBrowserType() === 'Safari' || subtype === 'LiveStream' ? 'HLS' : */ 'DASH'

	const payload = [
		{
			Value: contentId,
			CollectionName: 'Post',
			Name: 'Content:Id',
		},
		{ CollectionName: 'Post', Name: 'Content:Formats', Value: drmType },
	]

	const { data, error, isLoading, mutate } = useSwrImmutable<CollectionsType>(
		url && contentId ? url : null,
		(url) => fetcher(url, { method: 'post', body: JSON.stringify(payload) }),
	)

	const customError = data?.Error ? data.Error?.Message : null

	return {
		isLoading,
		refresh: mutate,
		error: customError || error?.message,
		data:
			!customError && data
				? {
						...(propertiesParser(data.Properties) as ContentAuthorizeType),
				  }
				: undefined,
	}
}
