import { useParams } from 'react-router-dom'
import useContent from 'hooks/api/useContent'
import useUser from 'hooks/api/useUser'
import { useAppStore } from 'store'
import s from './index.module.scss'

const CurrentRole = (): JSX.Element | null => {
	const { productionId, roleId, userId } = useParams()

	const { content: production } = useContent(productionId)

	const { content: role } = useContent(roleId)

	const { user } = useUser(userId)

	return user && role && production ? (
		<div
			className={s.wrapper}
			onClick={() => {
				const { setUserInfoSidebar } = useAppStore.getState()
				setUserInfoSidebar(user.ContentId)
			}}>
			<div className={s.avatar}>
				<img
					src={user?.CustomerImageSquareUrl || '/avatar-placeholder.png'}
					alt={user?.ContentTitle}
				/>
			</div>
			<div className={s.info}>
				<div>
					<span className="body1-b textEllipsis">
						{role?.['Globalizationen-USContentTitle']}
					</span>
					<div
						style={{
							borderRadius: '50%',
							backgroundColor: 'var(--mono100)',
							width: '4px',
							height: '4px',
						}}></div>
					<span className="textEllipsis">
						{production?.['Globalizationen-USContentTitle']}
					</span>
				</div>
				<span className="body2-r" style={{ color: 'var(--mono200)' }}>
					{user?.ContentTitle}
				</span>
			</div>
		</div>
	) : null
}

export default CurrentRole
