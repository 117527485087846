import { ChangeEvent, useMemo } from 'react'
import CountrySelector from 'components/CountrySelector'
import CheckboxSelect from 'components/UI/CheckboxSelect'
import ColorSelect from 'components/UI/ColorSelect'
import Input from 'components/UI/Input'
import PhoneInput from 'components/UI/PhoneInput'
import RangeSlider from 'components/UI/RangeSlider'
import Select from 'components/UI/Select'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { optionsParser, t } from 'helpers'
import { attributeBasicFields, contactFields } from 'pages/Portfolio/fields'
import { AttributeSelector, SelectOption } from 'types/app'

interface Props {
	field: AttributeSelector
	value: string
	setValue: (
		value: {
			[key: string]: string | number | boolean | string[]
		},
		oldValue?: string,
	) => void
}

const FieldsSelector = ({ field, value, setValue }: Props): JSX.Element => {
	const agencyField = field?.name === contactFields.agency.name
	const { data } = useCustomerSearch({
		fetch: agencyField,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
		type: 'Company',
	})

	const agencyOptions: SelectOption[] = useMemo(
		() =>
			data
				? data?.map((user) => ({
						value: user.ContentId,
						label: user.ContentTitle || user.ContentId,
				  }))
				: [],
		[data],
	)

	const component = useMemo(() => {
		const onChange = (e: ChangeEvent<HTMLInputElement>): void =>
			setValue(
				{
					[e.target.name]: e.target.value === '0' ? '' : e.target.value,
				},
				e.target.value,
			)

		const checkboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
			const v = Array.isArray(value)
				? value?.includes(e.target.value)
					? value?.filter((v) => v !== e.target.value)
					: [...value, e.target.value]
				: [e.target.value]
			setValue({ [e.target.name]: v }, e.target.value)
		}
		const fieldName = field.name?.toLowerCase()

		const components: { [key: string]: JSX.Element } = {
			input: (
				<Input
					name={field.name}
					label={field.label}
					value={value}
					onChange={onChange}
				/>
			),
			select: (
				<Select
					name={field.name}
					label={field.label}
					options={
						field?.options?.length
							? field.options
							: agencyField
							? agencyOptions
							: optionsParser(t(field.optionName || ''))
					}
					inputProps={{
						name: field.name,
						value,
					}}
					onChange={onChange}
				/>
			),
			country: (
				<CountrySelector
					name={field.name}
					label={field.label}
					value={value}
					onChange={onChange}
				/>
			),
			phone: (
				<PhoneInput
					name={field.name}
					label={field.label}
					defaultValue={value}
					value={value}
					onChange={onChange}
				/>
			),
			range: (
				<RangeSlider
					label={field.label}
					min={field?.min || 0}
					max={field?.max || 100}
					step={1}
					values={
						typeof value === 'string' && value?.startsWith('[')
							? JSON.parse(value)
							: [0, 100]
					}
					onChange={(v) =>
						onChange({
							target: {
								name: field.name,
								value: JSON.stringify(v),
							},
						} as ChangeEvent<HTMLInputElement>)
					}
					valueEnd={
						fieldName.includes('age')
							? 'yr'
							: fieldName.includes('weight')
							? 'kg'
							: 'lb'
					}
				/>
			),
			colors: (
				<ColorSelect
					name={field.name}
					label={field.label}
					value={value}
					options={optionsParser(t(field.optionName || ''))}
					onChange={onChange}
				/>
			),
			checkbox: (
				<CheckboxSelect
					name={field.name}
					label={
						[
							contactFields.travel.name,
							attributeBasicFields.body.selectors?.[3]?.name,
						].includes(field.name)
							? field.label
							: ''
					}
					value={value}
					options={optionsParser(t(field.optionName || ''))}
					onChange={checkboxChange}
				/>
			),
		}

		return components[field.type]
	}, [agencyField, agencyOptions, field, setValue, value])

	return component
}

export default FieldsSelector
