import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'
import Modal from 'components/Modal'
import { t } from 'helpers'
import { getDeviceData } from 'helpers/deviceData'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const MobileBlockModal = (): JSX.Element | null => {
	const [showMessage, setShowMessage] = useState(false)
	const { pathname } = useLocation()
	const { userId } = useParams()

	useEffect(() => {
		const isMobile =
			getDeviceData()?.deviceType === 'MOBILE' ||
			new UAParser().getDevice()?.type === 'mobile'

		const userPath = userId
			? '/' + PATHS.PROFILE?.replace(':userId', userId || '')
			: null

		if (isMobile && ![userPath, '/', '/' + PATHS.HOME].includes(pathname)) {
			setShowMessage(true)
		} else setShowMessage(false)
	}, [pathname, userId])

	return showMessage ? (
		<Modal
			onClose={() => null}
			style={{
				backgroundColor: 'var(--mono900)',
				zIndex: 9999999999,
			}}>
			<div className={s.wrapper}>
				<>
					<h4 style={{ textAlign: 'center' }}>
						{t('APP_MOBILE_DEVICE_ERROR')}
					</h4>
				</>
			</div>
		</Modal>
	) : null
}

export default MobileBlockModal
