import { SwiperSlide } from 'swiper/react'
import { CastingIcon } from 'assets/icons'
import RecentCarousel from 'components/RecentCarousel'
import RoleCard from 'components/RoleCard'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { RELATIONS } from 'types/enums'
import { Content } from 'types/app'

interface Props {
	contents: Content[]
	openCasting: (
		content: Content,
		_: string | undefined,
		prodId: string | undefined,
	) => void
}

const Recent = ({ contents, openCasting }: Props): JSX.Element => (
	<>
		<SidebarTabs
			active={0}
			options={[{ value: 0, label: 'APP_RECENT_CASTING' }]}
			onChange={() => {
				// setActiveTab(v as number)
			}}
			type="fit"
		/>
		<RecentCarousel name="" perView={4}>
			<>
				{contents?.map((content) => (
					<SwiperSlide key={content.ContentId}>
						<RecentItem content={content} openCasting={openCasting} />
					</SwiperSlide>
				))}
			</>
		</RecentCarousel>
	</>
)

export default Recent

const RecentItem = ({
	content,
	openCasting,
}: {
	content: Content
	openCasting: (
		content: Content,
		_: string | undefined,
		prodId: string | undefined,
	) => void
}): JSX.Element => {
	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	return (
		<RoleCard
			key={content.ContentId}
			content={content}
			type="horizontal"
			showInfo
			children={
				<div
					className="hoverButtonWrapper hoverButtonIcon"
					onClick={() => openCasting(content, '', prod?.ContentId)}>
					<CastingIcon />
				</div>
			}
		/>
	)
}
