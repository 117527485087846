import { createWithEqualityFn } from 'zustand/traditional'
import { updateUser } from 'api/user'
import { upload } from 'api/aws'
import { createContentUpload } from 'api/content'
import { addRelation } from 'api/relations'
import { imageUploadWorkflowId, videoUploadWorkflowId } from 'helpers/constants'
import { getVideoDuration } from 'helpers/getVideoMetadata'
import { t } from 'helpers'
import { PortfolioStore } from 'types/store'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import { PropertiesType } from 'types/app'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useUserStore } from 'store'
import { notif } from 'helpers/notif'

const useStore = createWithEqualityFn<PortfolioStore>(
	(set, get) => ({
		modalType: '',
		setModalType: (type) => set({ modalType: type }),
		cardData: null,
		setCardData: (data) => {
			const prevData = get().cardData
			if (prevData) {
				set({ cardData: null })
				setTimeout(() => {
					set({ cardData: data })
				}, 100)
			} else set({ cardData: data })
		},
		getModalProp: () => get().modalType,
		generalModalType: PORTFOLIO_TYPES.ATTRIBUTE,
		setGeneralModalType: (type) => set({ generalModalType: type }),
		clearModalType: () => set({ modalType: '' }),
		openAgencySidebar: false,
		setOpenAgencySidebar: (open) => set({ openAgencySidebar: open }),
		updateFields: async (data) => {
			// const { setLoading, clearModalType } = get()
			// setLoading(true)
			// const userId = getUser()?.['Custom:User:Id']
			const { userId } = useUserStore.getState()
			if (userId) {
				await updateUser(userId, data as PropertiesType[])
				EventBus.$emit('updatePortfolioOverall')
			}
			// setLoading(false)
			// clearModalType()
		},
		uploadFile: async (
			name: string,
			file: File,
			fileType: string,
			index?: number,
		): Promise<string | undefined> => {
			try {
				const response = await upload({
					fileObj: file,
					assetType: 'Content',
				})

				let fileDuration = '0'
				if (fileType === 'Video') fileDuration = await getVideoDuration(file)

				const content = await createContentUpload({
					...response,
					duration: +fileDuration ? +fileDuration * 1000 : 0,
					originalTitle: file.name,
					workflowId:
						fileType === 'Image'
							? imageUploadWorkflowId
							: videoUploadWorkflowId,
					originalLanguage: 'en-US',
					localizations: {
						'en-US': { name: response.originalFileName },
					},
					type: fileType,
					originalFileName: response.originalFileName,
					objectUrl: response.fileAssetUrl,
					publicUrl: response.cdnUrl,
					exhibitionWindow: {
						'--': {
							availableFrom:
								response.availableFrom?.toISOString &&
								response.availableFrom.toISOString(),
							availableUntil:
								response.availableUntil?.toISOString &&
								response.availableUntil.toISOString(),
						},
					},
					allowMinting: true,
					allowRemix: true,
					allowComments: true,
					published: true,
				})

				const portfolioMode = useAppStore.getState()?.portfolioMode?.active
				const userId = useUserStore.getState()?.userId

				await addRelation({
					relation:
						fileType === 'Image'
							? RELATIONS.IMAGE
							: RELATIONS.BEST_PERFORMANCES,
					targetId: content.id,
					sourceId: portfolioMode ? userId : undefined,
					index: index,
					type: 'Customer',
				})

				if (fileType === 'Video')
					notif('info', t('APP_CONTENT_UPLOAD_DELAY_MESSAGE'))

				return content?.id
			} catch (error) {
				console.log('error:', error)
			}
		},
		loading: false,
		setLoading: (loading) => set({ loading }),
		cardValues: {},
		setCardValues: (value, type) =>
			set((state) => ({
				cardValues: {
					...state.cardValues,
					[type || get().modalType]: {
						...state.cardValues[type || get().modalType],
						...value,
					},
				},
			})),
		galleryType: null,
		setGalleryType: (type) => set({ galleryType: type }),
		galleryValues: {},
		setGalleryValues: (contentId, propName) =>
			set((state) => ({
				galleryValues: {
					...state.galleryValues,
					[contentId]: state.galleryValues[contentId]?.includes(propName)
						? state.galleryValues[contentId]?.filter((v) => v !== propName)
						: [...(state.galleryValues[contentId] || []), propName],
				},
			})),
		setGalleryItems: (items) => set({ galleryValues: items }),
		additionalSidebar: null,
		setAdditionalSidebar: (values) => set({ additionalSidebar: values }),
		prevSidebarData: null,
		setPrevSidebarData: (data) => set({ prevSidebarData: data }),
		mediaZoomSidebar: null,
		setMediaZoomSidebar: (data) => set({ mediaZoomSidebar: data }),
		newAgency: null,
		setNewAgency: (agency) => set({ newAgency: agency }),
	}),
	Object.is,
)

export default useStore
