import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { upload } from 'api/aws'
import { addRelation } from 'api/relations'
import { updateUser } from 'api/user'
import { DownloadIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'
import DragDrop from 'components/UI/DragDrop'
import { t, textParser } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { notif } from 'helpers/notif'

interface Props {
	content?: Content
	production?: Content
	onClose: () => void
	setLoading: (l: boolean) => void
	available: boolean
	approve: boolean
	setAvailable: Dispatch<SetStateAction<boolean>>
	setApprove: Dispatch<SetStateAction<boolean>>
}

const NDA = ({
	content,
	production,
	setLoading,
	onClose,
	available,
	approve,
	setApprove,
	setAvailable,
}: Props): JSX.Element => {
	const [file, setFile] = useState<File | null>(null)
	const { search } = useLocation()

	const acceptId = new URLSearchParams(search).get('contentId')

	const ndaRequired = production?.ContentLegalNDARequired === 'true'

	const onAccept = useCallback(async (): Promise<void> => {
		if (ndaRequired && !file) {
			notif('error', t('APP_UPLOAD_NDA_ERROR'))

			return
		}
		try {
			setLoading(true)
			if (ndaRequired && file) {
				const { createAsset } = useProductionStore.getState()
				if (acceptId && file) {
					const uploadedFile = await upload({
						fileObj: file,
						assetType: 'Content',
					})
					await createAsset(
						uploadedFile,
						`NDA - ${content?.['Globalizationen-USContentTitle']} - ${file.name}`,
						uploadedFile.originalFileName,
						uploadedFile.fileAssetUrl,
						uploadedFile.cdnUrl,
						acceptId,
					)
				}
			}

			await addRelation({
				sourceId: acceptId || '',
				targetId: getUser()?.['Custom:User:Id'] || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: ndaRequired ? CASTING_STATUS.WAITING : CASTING_STATUS.READY,
			})
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Process:Status',
					Value: 'Selected',
				},
			])
			onClose()
			EventBus.$emit('refreshActorBoard')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}, [acceptId, content, file, ndaRequired, onClose, setLoading])

	const onReject = useCallback(async (): Promise<void> => {
		await addRelation({
			sourceId: acceptId || '',
			targetId: getUser()?.['Custom:User:Id'] || '',
			type: 'Content',
			relation: RELATIONS.ACCESS,
			status: CASTING_STATUS.REJECTED,
		})
		onClose()
		EventBus.$emit('refreshActorBoard')
	}, [acceptId, onClose])

	useEffect(() => {
		EventBus.$on('acceptActorInvite', () => {
			onAccept()
		})

		return () => {
			EventBus.$off('acceptActorInvite')
		}
	}, [onAccept])
	useEffect(() => {
		EventBus.$on('rejectActorInvite', () => {
			onReject()
		})

		return () => {
			EventBus.$off('rejectActorInvite')
		}
	}, [onReject])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				height: '100%',
			}}>
			{ndaRequired ? (
				<>
					{production &&
					production?.assets?.find((asset) => asset.publicUrl?.endsWith('pdf'))
						?.publicUrl ? (
						<Button
							variant="outlined"
							onClick={(e) => {
								if (
									(e.target as HTMLElement).tagName?.toLowerCase() === 'button'
								) {
									const a = (e.target as HTMLButtonElement)
										.children?.[1] as HTMLAnchorElement
									if (a) a.click()
								}
							}}
							style={{
								gap: '10px',
								borderColor: 'var(--theme-primary)',
							}}>
							<div style={{ display: 'flex' }}>
								<DownloadIcon />
							</div>
							<a
								className="body1-b"
								href={
									production?.assets?.find((asset) =>
										asset.publicUrl?.endsWith('pdf'),
									)?.publicUrl
								}
								target="_blank"
								download
								style={{
									textDecoration: 'none',
									textTransform: 'none',
									color: 'var(--theme-primary)',
								}}>
								{t('APP_DOWNLOAD_NDA')}
							</a>
						</Button>
					) : null}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							overflow: 'hidden',
							gap: '5px',
						}}>
						{textParser(t('APP_NDA_EXPLANATION'))?.map((text) => (
							<span
								className="body2-m"
								style={{ color: 'var(--mono300)' }}
								key={text}>
								{text}
							</span>
						))}
					</div>

					<DragDrop
						hint="APP_DRAG_&_DROP_ROLE_NDA"
						name="nda-attachments"
						accept=".doc, .docx, application/pdf"
						layoutStyle={{
							padding: '50px 20px',
							textAlign: 'center',
						}}
						style={{
							padding: '50px 20px',
						}}
						onChange={(e) => {
							if (e.target.files?.length) {
								setFile(e.target.files[0])
							}
						}}
					/>
					{file?.name ? <span>{file?.name}</span> : null}
				</>
			) : null}

			<CheckboxButton
				checked={available}
				onChange={() => setAvailable((prev) => !prev)}
				label="APP_SHOOTINGDAYS_ACCEPT_LABEL"
			/>
			<CheckboxButton
				checked={approve}
				onChange={() => setApprove((prev) => !prev)}
				label="APP_PERSONALDATA_ACCEPT_LABEL"
			/>
		</div>
	)
}

export default NDA
