import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Hint from 'components/Wizard/Hint'
import AttributeSidebarContent from './AttributeSidebarContent'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { optionsParser, t } from 'helpers'
import { getCardName, getRandomCardName } from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { usePortfolioStore, useUserStore } from 'store'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import { AttributeCardType, ValueLevel } from 'types/app'
import s from './index.module.scss'

interface Props {
	cardData: AttributeCardType | null
}

const AttributeSidebar = ({ cardData }: Props): JSX.Element => {
	const [loading, type, generalType, iconClose] = usePortfolioStore(
		useShallow((state) => [
			state.loading,
			state.modalType,
			state.generalModalType,
			!!state.additionalSidebar ||
				!!state.galleryType ||
				!!state.mediaZoomSidebar?.url,
		]),
	)

	const userId = useUserStore(useShallow((state) => state.userId))
	const { data } = useRelationsContent(
		[RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES],
		userId,
		undefined,
		'',
		false,
		true,
	)

	const images = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.IMAGE === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	const videos = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.BEST_PERFORMANCES === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	const values = usePortfolioStore(
		useShallow((state) => state.cardValues[type] || {}),
	)

	const selectorsData = useMemo(
		() =>
			cardData?.selectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})),
		[cardData?.selectors],
	)

	const hideVoiceSelectors = useMemo(
		() =>
			cardData?.value === 'voice' &&
			(values?.[cardData?.selectors?.[0].name] as string[])?.[0] ===
				optionsParser(cardData?.selectors?.[0]?.optionName || '')?.[0]?.value,
		[cardData?.selectors, cardData?.value, values],
	)

	const mediaType = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	const mediaCardName =
		mediaType &&
		selectorsData &&
		(getCardName(selectorsData, values) || getRandomCardName(selectorsData))

	const title =
		type && generalType
			? `APP_${
					type.toUpperCase() + '_'
			  }${generalType.toUpperCase()}_MODAL_TITLE`
			: ''

	const closeSidebar = (): void => {
		const {
			clearModalType,
			setGalleryType,
			setCardData,
			setAdditionalSidebar,
		} = usePortfolioStore.getState()
		clearModalType()
		setGalleryType(null)
		setCardData(null)
		setAdditionalSidebar(null)
	}

	const assignedMedia = useMemo(
		() =>
			images
				?.concat(videos)
				.map((content) => {
					const values = [
						content?.ContentLabelAttribute || '',
						content?.ContentLabelSkill || '',
					]
						?.filter((v) => !!v)
						.join(',')
						.split(',')
						?.filter((v) => !!v)

					return { [content.ContentId]: values }
				})
				.reduce(
					(prev, next) => ({
						...prev,
						...next,
					}),
					{},
				),
		[images, videos],
	)

	useEffect(() => {
		const { galleryValues, setGalleryItems } = usePortfolioStore.getState()
		if (
			Object.values(assignedMedia)?.length &&
			!Object.values(galleryValues)?.length
		) {
			setGalleryItems(assignedMedia)
		}
	}, [assignedMedia])

	return (
		<Sidebar
			open={!!cardData}
			onClose={() => {
				closeSidebar()
			}}
			iconClose={iconClose}
			header={{
				title: mediaType
					? t(`APP_${generalType?.toUpperCase()}_CARD`) +
					  ` #${(cardData?.index || 0) + 1}`
					: mediaCardName || t(title),
			}}
			// style={mediaType ? { height: 'unset' } : {}}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: () => {
						if (
							[PORTFOLIO_TYPES.SHOWREEL, PORTFOLIO_TYPES.PICTURE].includes(
								generalType,
							)
						) {
							const { setGalleryType, setAdditionalSidebar } =
								usePortfolioStore.getState()
							EventBus.$emit('confirmMediaUpload')
							setGalleryType(null)
							setAdditionalSidebar(null)
						} else {
							const { cardValues, setCardValues, modalType, updateFields } =
								usePortfolioStore.getState()
							const values = cardValues?.[modalType]
							const parsed = Object.entries(values)?.map(([name, v]) => {
								const value = v as ValueLevel[]
								if (value && Array.isArray(value)) {
									if (typeof value?.[0] === 'string') {
										return {
											Name: name,
											Value: JSON.stringify(value) as string,
										}
									} else {
										const newValues: ValueLevel[] = []
										value?.forEach((v) => {
											const { galleryValues } = usePortfolioStore.getState()
											const item = Object.entries(galleryValues).find(
												([_, propNames]) =>
													propNames?.includes(name + ':' + v?.Value),
											)?.[0]
											if (v?.Level && !item) {
												newValues.push({
													Value: v.Value,
													Level: '',
												})
											} else if (v.Value) newValues.push(v)
										})

										setCardValues({
											[name]: newValues,
										})

										return {
											Name: name,
											Value: JSON.stringify(newValues) as string,
										}
									}
								} else
									return {
										Name: name,
										Value: value as string,
									}
							})
							updateFields(parsed)
							closeSidebar()
						}
					},
				},
			]}>
			<>
				<Loader loading={loading} adapt />
				{cardData && selectorsData ? (
					<>
						<Hint
							hintText={t(
								`APP_${(mediaType
									? generalType
									: type
								).toUpperCase()}_MODAL_HINT`,
							)}
							style={{ marginTop: '0' }}
						/>
						<div className={cn(s.contentWrapper, 'hideScrollBar')}>
							<AttributeSidebarContent
								cardData={cardData}
								selectorsData={
									hideVoiceSelectors
										? selectorsData?.slice(0, 1)
										: selectorsData
								}
							/>
						</div>
					</>
				) : null}
			</>
		</Sidebar>
	)
}

export default AttributeSidebar
