import { AttributeSelector } from 'types/app'
import { DROPDOWN_OPTIONS } from 'types/enums'

export const productionBasic: {
	[key in
		| 'name'
		| 'description'
		| 'productionType'
		| 'seriesInfo'
		| 'nda'
		| 'locations']: AttributeSelector
} = {
	name: {
		name: 'Globalizationen-USContentTitle',
		label: 'APP_NAME_LABEL',
		type: 'input',
		options: [],
	},
	description: {
		name: 'Globalizationen-USContentDescription',
		label: 'APP_DESCRIPTION_LABEL',
		type: 'textarea',
		options: [],
	},
	productionType: {
		name: 'Content:Production:Type',
		label: 'APP_PRODUCTION_TYPE_LABEL',
		type: 'select',
		optionName: DROPDOWN_OPTIONS.PRODUCTION_TYPES,
		options: [],
	},
	seriesInfo: {
		name: 'Content:Production:SeriesInfo',
		label: 'APP_SERIES_INFO_LABEL',
		type: 'input',
		options: [],
	},
	nda: {
		name: 'Content:Legal:NDA:Required',
		label: 'APP_NDA_INPUT_LABEL',
		type: 'checkbox',
		options: [],
	},
	locations: {
		name: 'ContentLabelPlace',
		label: 'APP_LOCATIONS_LABEL',
		type: 'select',
		options: [],
	},
}

export const productionInfo: {
	[key in
		| 'directorName'
		| 'imdbLink'
		| 'rehearsal'
		| 'filming'
		| 'cover'
		| 'color'
		| 'format']: AttributeSelector
} = {
	directorName: {
		name: 'Content:Director:Name',
		label: 'APP_DIRECTOR_NAME_LABEL',
		type: 'input',
		options: [],
	},
	imdbLink: {
		name: 'Content:ImdbUrl',
		label: 'APP_IMDBLINK_LABEL',
		type: 'input',
		options: [],
	},
	rehearsal: {
		name: 'Content:Event:RehearsalDate',
		label: 'APP_REHEARSAL_DATE_LABEL',
		type: 'datepicker',
		options: [],
	},
	filming: {
		name: 'Content:Event:FilmingDate',
		label: 'APP_FILMING_DATE_LABEL',
		type: 'datepicker',
		options: [],
	},
	format: {
		name: 'Content:Format',
		label: 'APP_SCENES_TYPES_LABEL',
		type: 'switch',
		options: [],
	},
	cover: {
		name: 'Globalizationen-USContentSquareImageUrl',
		label: 'APP_PRODUCTION_COVER_LABEL',
		type: 'file',
		options: [],
	},
	color: {
		name: 'Content:Color',
		label: 'APP_COLOR_LABEL',
		type: 'switch',
		options: [],
	},
}

export const roleBasic: {
	[key in
		| 'title'
		| 'character'
		| 'type'
		| 'shootingDays'
		| 'deadline']: AttributeSelector
} = {
	title: {
		name: 'Globalizationen-USContentTitle',
		label: 'APP_ROLE_NAME_LABEL',
		type: 'input',
		options: [],
	},
	character: {
		name: 'Globalizationen-USContentDescription',
		label: 'APP_CHARACTER_LABEL',
		type: 'textarea',
		options: [],
	},
	type: {
		name: 'Content:Role:Type',
		label: 'APP_ROLE_TYPE_LABEL',
		type: 'select',
		optionName: DROPDOWN_OPTIONS.ROLE_STATUS,
		options: [],
	},
	shootingDays: {
		name: 'Content:ShootingDays',
		label: 'APP_ROLE_SHOOTING_DAYS',
		type: 'range',
		options: [],
	},
	deadline: {
		name: 'Content:Event:Deadline',
		label: 'APP_DEADLINE_LABEL',
		type: 'datepicker',
		options: [],
	},
}

export const roleInfo: {
	[key in
		| 'color'
		| 'language'
		| 'age'
		| 'gender'
		| 'height'
		| 'weight'
		| 'race']: AttributeSelector
} = {
	gender: {
		name: 'Content:Role:Gender',
		label: 'APP_GENDER_LABEL',
		optionName: DROPDOWN_OPTIONS.GENDERS,
		type: 'select',
		options: [],
	},
	color: {
		name: 'Content:Color',
		label: 'APP_PRODUCTION_COVER_LABEL',
		type: 'switch',
		options: [],
	},
	age: {
		name: 'Content:Role:Age',
		label: 'APP_ROLE_AGE_LABEL',
		type: 'range',
		options: [],
	},
	height: {
		name: 'Content:Role:Height',
		label: 'APP_HEIGHT_LABEL',
		type: 'range',
		options: [],
	},
	weight: {
		name: 'Content:Role:Weight',
		label: 'APP_WEIGHT_LABEL',
		type: 'range',
		options: [],
	},
	race: {
		name: 'Content:Role:Race',
		label: 'APP_ROLE_RACE_LABEL',
		optionName: DROPDOWN_OPTIONS.RACE,
		type: 'select',
		options: [],
	},
	language: {
		name: 'Content:Role:Dialect',
		label: 'APP_SPOKENLANGUAGE_LABEL',
		type: 'select',
		optionName: DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX,
		options: [],
	},
}
