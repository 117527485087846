import { CSSProperties } from 'react'
import cn from 'classnames'
import { t, textParser } from 'helpers'
import s from './index.module.scss'

interface Props {
	card: {
		title: string
		desc: string
		image: string
		imageStyle: CSSProperties
		main: boolean
	}
	type?: string
}

const Card = ({ card, type }: Props): JSX.Element => (
	<div className={cn(s.item, { [s['main-' + type]]: card.main })}>
		<div className={s.content}>
			{textParser(t(card.title)).map((text) => (
				<h3 key={text}>{text}</h3>
			))}
			{textParser(t(card.desc)).map((text) => (
				<span key={text}>{text}</span>
			))}
			<div className={s.backgroundShadow} />
		</div>
		<img src={card.image} alt="home-carousel" style={card.imageStyle} />
	</div>
)

export default Card
