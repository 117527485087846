import { fetcher } from 'api'
import { getUser } from 'helpers/storage'
import { CreateContent } from 'types/app'

type MeetingData = {
	attendeeId: string
	externalUserId: string
	joinToken: string
	capabilities: {
		audio: string
		content: string
		video: string
	}
	meetingId: string
	contentId: string
}

export const startRecording = (
	meetingId: string,
	contentId: string,
): Promise<void> => {
	const url =
		'https://ane.theatroo.ent360.blue/api/v4.0/LiveSession/' +
		meetingId +
		'/Record'

	const payload = {
		meetingId,
		contentId,
	}

	return fetcher(url, { method: 'post', body: JSON.stringify(payload) })
}

export const finishRecording = (meetingId: string): Promise<void> => {
	const url =
		'https://ane.theatroo.ent360.blue/api/v4.0/LiveSession/' + meetingId

	return fetcher(url, { method: 'delete' })
}

export const createRecordingContent = (): Promise<
	CreateContent & {
		id: string
		properties: {
			'Content:Process:SessionId': string
		}
	}
> => {
	const url = 'https://ane.theatroo.ent360.blue/api/v4.0/LiveSession/Create'

	const payload = {
		originalLanguage: 'en-US',
		type: 'CameraCapture',
		published: false,
		originalTitle:
			getUser()?.['Custom:User:Name'] +
			' - Validation - ' +
			new Date().toLocaleString(),
	}

	return fetcher(url, { method: 'post', body: JSON.stringify(payload) })
}

export const getMeetingData = (meetingId: string): Promise<MeetingData> => {
	const url =
		'https://ane.theatroo.ent360.blue/api/v4.0/LiveSession/' + meetingId

	return fetcher(url, { method: 'get' })
}

export const getAttendeeData = (meetingId: string): Promise<MeetingData> => {
	const url =
		'https://ane.theatroo.ent360.blue/api/v4.0/LiveSession/' +
		meetingId +
		'/Connect'

	return fetcher(url, { method: 'put' })
}
