import { lazy, useCallback, useEffect } from 'react'
// import { useShallow } from 'zustand/react/shallow'
import { useNavigate, useParams } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { createCustomer, updateUser } from 'api/user'
import { addRelation } from 'api/relations'
import StepWrapper from 'components/Wizard/StepWrapper'
import InviteToPoolSidebar from '../components/InviteToPoolSidebar'
import AddContactsSidebar from '../components/AddContactsSidebar'
import Profile from '../components/Profile'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useUser from 'hooks/api/useUser'
import { EventBus } from 'helpers/EventBus'
import contentParser from 'helpers/propertiesParser'
import { useAppStore, useNetworkStore, useUserStore } from 'store'
import { User } from 'types/user'
import { RELATIONS } from 'types/enums'
import s from '../index.module.scss'

const MyNetwork = lazy(() => import('../components/MyNetwork'))
const InviteMessage = lazy(() => import('../components/InviteMessage'))
const Review = lazy(() => import('../components/Review'))
const Upload = lazy(() => import('../components/Upload'))

export const agencyNetworkSteps = {
	opennetwork: {
		name: 'APP_OPEN_NETWORK',
		key: 'opennetwork',
		main: true,
		substeps: [
			{
				name: 'APP_OPEN_NETWORK',
				key: 'opennetwork',
				component: () => <MyNetwork source="opennetwork-agency" />,
				index: 0,
			},
		],
	},
	mynetwork: {
		name: 'APP_NETWORK_ALL',
		key: 'my',
		main: true,
		substeps: [
			{
				name: 'APP_NETWORK_ALL',
				key: 'my',
				component: () => <MyNetwork source="my-agency" />,
				index: 0,
			},
		],
	},
	uploadnetwork: {
		name: 'APP_NETWORK_UPLOAD',
		key: 'upload',
		main: true,
		substeps: [
			{
				name: 'APP_NETWORK_UPLOAD',
				key: 'upload',
				component: Upload,
				index: 0,
				disabled: true,
			},
			{
				name: 'APP_NETWORK_UPLOADLIST',
				key: 'upload',
				component: Review,
				index: 1,
				disabled: true,
			},
			{
				name: 'APP_NETWORK_MESSAGE',
				key: 'upload',
				component: InviteMessage,
				index: 2,
				disabled: true,
			},
		],
	},
}

const DirectorNetwork = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state?.user))
	const [userProfile] = useNetworkStore(
		useShallow((state) => [state.userProfile.user]),
	)
	const navigate = useNavigate()
	const { profileId } = useParams()
	const { user: fetchedUser } = useUser(
		!userProfile && profileId ? profileId : '',
	)
	const { data, isLoading } = useRelationsContent([RELATIONS.ORGANIZATION])
	const organization = data ? data[RELATIONS.ORGANIZATION][0] : undefined

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					Object.values(agencyNetworkSteps)?.[0]?.key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	const createOrganization = useCallback(async (): Promise<void> => {
		try {
			if (!user?.ContentTitle || user?.CustomerPoolDefault) return

			const response = await createCustomer(
				'Organization',
				user?.ContentTitle + ' Pool',
			)
			if (response) {
				const org = contentParser<User>(response)
				await addRelation({
					relation: RELATIONS.CREATOR,
					targetId: org.ContentId,
					type: 'Customer',
				})
				const { userId } = useUserStore.getState()
				if (userId)
					updateUser(userId, [
						{
							Name: 'Customer:Pool:Default',
							Value: org.ContentId,
						},
					])
			}
		} catch (error) {
			console.log('error:', error)
		}
	}, [user?.ContentTitle, user?.CustomerPoolDefault])

	useEffect(() => {
		if (!organization && !isLoading) createOrganization()
	}, [createOrganization, organization, isLoading])

	const showUser = userProfile || fetchedUser

	return (
		<div className={s.container}>
			<AddContactsSidebar />
			<InviteToPoolSidebar />
			{showUser && profileId ? (
				<div className={cn(s.wrapper, 'flex')}>
					<StepWrapper style={{ height: 'auto' }}>
						<Profile user={showUser} />
					</StepWrapper>
				</div>
			) : null}
			<div
				className={cn(s.wrapper, 'flex')}
				style={{
					display: profileId && showUser ? 'none' : 'flex',
				}}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					onStepChange={() => EventBus.$emit('checkProfileOpened')}
					className={s.wizard}>
					{Object.values(agencyNetworkSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								style={{ height: 'auto' }}
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
			</div>
		</div>
	)
}

export default DirectorNetwork
