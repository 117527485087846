import { t } from 'helpers'

interface Props {
	label: string
	count: string
}
const TabCircleCount = ({ label, count }: Props): JSX.Element => (
	<div
		className="body1-m"
		style={{
			display: 'flex',
			alignItems: 'center',
			gap: '10px',
			color: 'var(--mono100)',
			whiteSpace: 'nowrap',
		}}>
		{t(label)}
		<div
			style={{
				borderRadius: '50%',
				width: '24px',
				height: '24px',
				overflow: 'hidden',
				position: 'relative',
				backgroundColor: 'var(--theme-primary)',
			}}>
			<span
				className="body1-b"
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					color: 'var(--mono900)',
					fontSize: '12px',
				}}>
				{count}
			</span>
		</div>
	</div>
)

export default TabCircleCount
