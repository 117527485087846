import cn from 'classnames'
import { CSSProperties, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { ArrowRoundedIcon, CircleIcon, LikeIcon, StarIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useCastingStore } from 'store'
import s from './index.module.scss'
import style from '../../index.module.scss'

const Tutorial = (): JSX.Element | null => {
	const [show, setShow] = useCastingStore(
		useShallow((state) => [state.showTutorial, state.setShowTutorial]),
	)

	return show ? (
		<div className={cn(s.container, 'castingToolWrapper')}>
			<div className={s.header}>
				<span className="body1-r" style={{ textAlign: 'center' }}>
					{t('APP_CASTING_TUTORIAL_TITLE')}
				</span>
				<span className="body2-r" style={{ textAlign: 'center' }}>
					{t('APP_CASTING_TUTORIAL_DESCRIPTION')}
				</span>
			</div>

			<div className={s.rowWrapper}>
				<div className={s.lineWrapper}>
					<div className={style.verticalButton} style={{ left: '0' }} />
					<span
						className="caption-r textEllipsis"
						style={{ marginLeft: '20px' }}>
						{t('APP_LEFTLINE_LABEL')}
					</span>
				</div>
				<div className={s.wrapper}>
					<span className="caption-r">{t('APP_SUPERLIKE_LABEL')}</span>
					<div className={s.items}>
						<Item
							icon={
								<div
									className={s.iconWrapper}
									style={{ borderColor: 'var(--theme-primary)' }}>
									<StarIcon color="var(--theme-primary)" />
								</div>
							}
						/>
						<div
							className="grid3column"
							style={{
								gridTemplateColumns: '1fr 120px 1fr',
								alignItems: 'center',
								gap: '12px',
							}}>
							<span
								className="caption-r textEllipsis"
								style={{ marginRight: '4px', marginLeft: 'auto' }}>
								{t('APP_DISLIKE_LABEL')}
							</span>
							<div className="grid3column" style={{ gap: '12px' }}>
								<Item
									arrowStyle={{ transform: 'rotate(-90deg)' }}
									icon={
										<div
											className={s.iconWrapper}
											style={{ borderColor: 'var(--error)' }}>
											<LikeIcon
												color="var(--error)"
												style={{ transform: 'rotate(180deg)' }}
											/>
										</div>
									}
								/>
								<Item
									arrowStyle={{ transform: 'rotate(-180deg)' }}
									icon={
										<div
											className={s.iconWrapper}
											style={{ borderColor: 'var(--mono100)' }}>
											<CircleIcon color="var(--mono100)" />
										</div>
									}
								/>
								<Item
									arrowStyle={{ transform: 'rotate(90deg)' }}
									icon={
										<div
											className={s.iconWrapper}
											style={{ borderColor: 'var(--success)' }}>
											<LikeIcon color="var(--success)" />
										</div>
									}
								/>
							</div>
							<span
								className="caption-r textEllipsis"
								style={{ marginLeft: '4px' }}>
								{t('APP_LIKE_LABEL')}
							</span>
						</div>
					</div>
					<span className="caption-r">{t('APP_NEUTRAL_LABEL')}</span>
				</div>

				<div className={s.lineWrapper}>
					<div className={style.verticalButton} style={{ right: 0 }} />
					<span
						className="caption-r textEllipsis"
						style={{ marginRight: '20px' }}>
						{t('APP_RIGHTLINE_LABEL')}
					</span>
				</div>
			</div>

			<Button
				variant="outlined"
				onClick={() => {
					setShow(false)
				}}>
				{t('APP_GOT_IT_LABEL')}
			</Button>
		</div>
	) : null
}

export default Tutorial

const Item = ({
	arrowStyle,
	icon,
}: {
	arrowStyle?: CSSProperties
	icon: JSX.Element
}): JSX.Element => {
	const [hovered, setHovered] = useState(false)

	return (
		<div
			className={s.item}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}>
			{hovered ? icon : <ArrowRoundedIcon style={arrowStyle} />}
		</div>
	)
}
