import classNames from 'classnames'
import { SwiperSlide } from 'swiper/react'
import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon, MasksIcon, ProfileIcon } from 'assets/icons'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import RecentCarousel from 'components/RecentCarousel'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t } from 'helpers'
import { useCastingStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'
import s from './index.module.scss'

type Todo = {
	label: string
	hint: string
	onClick?: () => void
	icon: JSX.Element
}

const Todos = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState(0)
	const actor = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR,
		),
	)

	const { data: relDataCI } = useRelationsContent(
		actor ? [RELATIONS.CUSTOMER_INVITE] : null,
		undefined,
		undefined,
		'/Lookup',
		true,
	)
	const requests = relDataCI?.[RELATIONS.CUSTOMER_INVITE] as unknown as User[]

	const requestTodos = requests?.map((user) => ({
		label: 'APP_PROFILE_UPDATE_REQUESTS_LABEL',
		hint: user?.ContentTitle,
		onClick: () => {
			const { setOpenRequestSidebar } = useCastingStore.getState()
			setOpenRequestSidebar(user)
		},
		icon: <ProfileIcon />,
	}))

	const todos: Todo[] = [
		...(requestTodos || []),
		{
			label: 'Finish your profile',
			hint: 'Start with your skills',
			icon: <MasksIcon />,
		},
		{
			label: 'Sign the NDA',
			hint: 'Jack Dawson',
			icon: <MasksIcon />,
		},
		{
			label: 'Casting started',
			hint: 'Neo casting in-progress',
			icon: <MasksIcon />,
		},
		{
			label: 'New network invite',
			hint: 'Mark Goldberg',
			icon: <MasksIcon />,
		},
	]

	return (
		<>
			<SidebarTabs
				active={activeTab}
				options={[
					{
						value: 0,
						label: 'Todos',
					},
				]}
				onChange={(v) => {
					setActiveTab(v as number)
				}}
				type="fit"
			/>
			<RecentCarousel name="Todos">
				<>
					{todos?.map((todo) => (
						<SwiperSlide key={todo.label + todo.hint}>
							<div
								className={classNames('card', s.itemWrapper)}
								onClick={todo.onClick}>
								<div className={s.icon}>{todo.icon}</div>
								<div className={s.content}>
									<span className="body1-b">{t(todo.label)}</span>
									<span className="body2-m" style={{ color: 'var(--mono200)' }}>
										{todo.hint}
									</span>
								</div>
								<div style={{ display: 'flex', marginLeft: 'auto' }}>
									<ArrowShortIcon />
								</div>
							</div>
						</SwiperSlide>
					))}
				</>
			</RecentCarousel>
		</>
	)
}

export default Todos
