export const videoUploadWorkflowId = '0c0effac-f5f2-4444-b2bc-5dea487eaa9c'
export const imageUploadWorkflowId = 'ff66cfeb-ab74-474e-a119-d4bccc8fb1dd'
export const weightDefault = 60
export const heightDefault = 150
export const videoAspect = 1.778
export const ib = 2.2
export const ft = 0.032808399
export const imageAcceptFormats = '.jpg, .jpeg, .png, .webp'
export const defaultOrganizationId = '35ab2507-a3a1-4f9c-ae6d-4ef865655571'

// kg to ib = 1:2.2
// cm to ft = 1:0.032808399
// 60 150
