import { KeyedMutator } from 'swr'
import useSwrImmutable from 'swr/immutable'
import { multipleFetcher } from 'api'
import { useAppStore } from 'store'
import { FetchReturn, RelationListResponse, Relation } from 'types/app'
import { RELATIONS } from 'types/enums'

type ReturnData = FetchReturn & {
	data: Relation[] | undefined
	refresh: KeyedMutator<RelationListResponse[]>
}

export default (
	query = '',
	userIds: string[] = [],
	stopFetch = false,
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const relationUrls = endpoints?.CustomApplicationV3ConnectionPostUrl
		? userIds.map(
				(id) =>
					endpoints?.CustomApplicationV3ConnectionPostUrl +
					'/' +
					id +
					'/Content' +
					'/' +
					RELATIONS.ACCESS +
					query,
		  )
		: null

	const { data, isLoading, error, mutate } = useSwrImmutable<
		RelationListResponse[]
	>(relationUrls?.length && !stopFetch ? relationUrls : null, multipleFetcher, {
		shouldRetryOnError: false,
	})

	return {
		data: data?.length ? data?.map((d) => d.pageContent).flat() : undefined,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}
