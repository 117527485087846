import { RepeatIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import { usePlayerStore } from 'store'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Repeat = ({ videoElement }: Props): JSX.Element => {
	const onRepeat = (): void => {
		if (videoElement) {
			videoElement.currentTime = 0
			videoElement.play()
			const { setValue } = usePlayerStore.getState()
			setValue({ playing: true })
		}
	}

	return (
		<Tooltip title="APP_REPEAT_CONTROL">
			<div className={s.control} onClick={onRepeat}>
				<RepeatIcon />
			</div>
		</Tooltip>
	)
}

export default Repeat
