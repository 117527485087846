import { useNavigate } from 'react-router-dom'
import { PlusIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useAppStore, useModalStore, useUserStore } from 'store'

const PortfolioModeMenu = (): JSX.Element => {
	const navigate = useNavigate()

	const confirmClosePortfolioMode = (): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '400px',
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_EDIT_MODE_EXIT')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									const { setPortfolioMode, portfolioMode } =
										useAppStore.getState()
									const { setUser, setUserId } = useUserStore.getState()
									if (portfolioMode.originalUser) {
										setUser(portfolioMode.originalUser)
										setUserId(portfolioMode.originalUser.ContentId)
									}
									setPortfolioMode({
										active: false,
										originalUser: undefined,
										asUserData: undefined,
									})
									navigate(portfolioMode.redirectUrl)
									setModal(null)
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
			<span className="body1-b" style={{ color: 'var(--theme-primary)' }}>
				{t('APP_PORTFOLIO_MODE_LABEL')}
			</span>
			<div
				style={{ display: 'flex', cursor: 'pointer', marginTop: '3px' }}
				onClick={confirmClosePortfolioMode}>
				<PlusIcon style={{ transform: 'rotate(45deg)' }} />
			</div>
		</div>
	)
}

export default PortfolioModeMenu
