import { CSSProperties } from 'react'
import s from './index.module.scss'

interface Props {
	status: 'success' | 'warning' | 'error'
	size?: number
	style?: CSSProperties
}

const defaultSize = 16

const StatusCircle = ({
	status,
	size = defaultSize,
	style,
}: Props): JSX.Element => {
	const color = `var(--${status})`

	return (
		<div className={s.statusWrapper} style={style}>
			<div className={s.status}>
				<div
					className={s.animation}
					style={{
						backgroundColor: `color-mix(in srgb, ${color} 80%, transparent)`,
						padding:
							(size !== defaultSize
								? defaultSize - (defaultSize - size)
								: defaultSize) + 'px',
					}}
				/>
				<div
					style={{
						backgroundColor: color,
						width: size + 'px',
						height: size + 'px',
					}}
				/>
			</div>
		</div>
	)
}

export default StatusCircle
