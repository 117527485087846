import screenfull from 'screenfull'
import { useShallow } from 'zustand/react/shallow'
import { FullscreenIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import getBrowserType from 'helpers/getBrowserType'
import { usePlayerStore } from 'store'
import { CrossBrowserVideoElem } from 'types/content'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Fullscreen = ({ videoElement }: Props): JSX.Element => {
	const [fullscreen, setValue] = usePlayerStore(
		useShallow((state) => [state.fullscreen, state.setValue]),
	)

	const onFullscreen = async (): Promise<void> => {
		if (videoElement) {
			const videoPlayerRef = videoElement as CrossBrowserVideoElem
			try {
				if (getBrowserType() !== 'Safari' /* || window.innerWidth > 768 */) {
					if (navigator.maxTouchPoints > 0) {
						videoElement.requestFullscreen()
					} else {
						if (!screenfull.isFullscreen) {
							screenfull.request(videoElement)
							setValue({ fullscreen: true })
						} else {
							screenfull.exit()
							setValue({ fullscreen: false })
						}
					}
				} else {
					/* Safari */
					if (!videoPlayerRef.webkitDisplayingFullscreen) {
						videoPlayerRef.webkitEnterFullScreen()
						setValue({ fullscreen: true })
					} else {
						videoPlayerRef.webkitExitFullScreen()
						setValue({ fullscreen: false })
					}
				}
			} catch (error) {
				console.error(`Fullscreen error: ${error}`)
			}
		}
	}

	return (
		<Tooltip title="APP_FULLSCREEN_CONTROL">
			<div className={s.control} onClick={onFullscreen}>
				<FullscreenIcon
					color={fullscreen ? 'var(--mono100) !important' : 'var(--mono300)'}
				/>
			</div>
		</Tooltip>
	)
}

export default Fullscreen
