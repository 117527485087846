import { useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import LargeNetworkCard from 'components/LargeNetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { useNetworkStore, useUserStore } from 'store'
import { SORT_DIRECTION } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'

const DirectorsCarousel = (): JSX.Element => {
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))
	const director =
		user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.CASTING_DIRECTOR
	const actor = user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR
	const [activeTab, setActiveTab] = useState(0)
	const swiperRef = useRef<SwiperClass>()

	const { data } = useCustomerSearch({
		fetch: true,
		orders: [
			{
				sortBy: 'Customer:CreatedDate',
				sortDir: SORT_DIRECTION.RANDOM,
			},
		],
		type: director ? 'Company' : 'ProjectOrganizer',
		organizationId: false,
	})

	const { data: agencies } = useCustomerSearch({
		fetch: actor,
		orders: [
			{
				sortBy: 'Customer:CreatedDate',
				sortDir: SORT_DIRECTION.RANDOM,
			},
		],
		type: 'Company',
		organizationId: false,
	})

	const onUserClick = async (user: User, users: User[]): Promise<void> => {
		const { userProfile, setUserProfile, setNetworkRedirectionLink } =
			useNetworkStore.getState()
		setNetworkRedirectionLink(window.location.pathname + window.location.hash)
		const path = await userProfile.onUserChange(user)
		navigate(path)
		setUserProfile({ users, user, listSource: 'APP_RECENT_LIST_TITLE' })
	}

	return (
		<>
			<SidebarTabs
				active={activeTab}
				options={[
					{
						value: 0,
						label: director
							? 'APP_AGENCIES_NETWORK_TAB'
							: 'APP_CASTING_DIRECTORS_TAB',
					},
					{
						value: 1,
						label: 'APP_AGENCIES_NETWORK_TAB',
					},
				].slice(0, actor ? 2 : 1)}
				onChange={(v) => {
					setActiveTab(v as number)
					swiperRef.current?.slideTo(v as number)
				}}
				type="fit"
			/>

			<Swiper
				allowTouchMove={false}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				style={{ width: '100%' }}>
				<SwiperSlide>
					<RecentCarousel name="">
						<>
							{data?.map((user) => (
								<SwiperSlide key={user.ContentId}>
									<LargeNetworkCard
										user={user}
										onClick={() => onUserClick(user, data)}
									/>
								</SwiperSlide>
							))}
						</>
					</RecentCarousel>
				</SwiperSlide>
				<SwiperSlide>
					<RecentCarousel name="">
						<>
							{agencies?.map((user) => (
								<SwiperSlide key={user.ContentId}>
									<LargeNetworkCard
										user={user}
										onClick={() => onUserClick(user, agencies)}
									/>
								</SwiperSlide>
							))}
						</>
					</RecentCarousel>
				</SwiperSlide>
			</Swiper>
		</>
	)
}

export default DirectorsCarousel
