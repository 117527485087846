import cn from 'classnames'
import SectionWrapper from '../SectionWrapper'
import ItemWrapper from './components/ItemWrapper'
import { t, textParser } from 'helpers'
import { CUSTOMER_ROLE, User } from 'types/user'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'
import { Content } from 'types/app'

interface Props {
	user: User | undefined
	place: 'sidebar' | 'default'
	mediaItems: Content[]
}

const Personal = ({ user, place, mediaItems }: Props): JSX.Element | null => {
	const actor = user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR
	const { body, voice, hair, eyes } = attributeBasicFields
	const { ability, bodyDone, voiceSpecials, bodyWilling } =
		attributeSpecialFields
	const { actingLanguage, driving, spokenLanguage } = skillFields

	const basics = actor
		? [spokenLanguage, actingLanguage, driving]
				?.map((field, index) =>
					user?.[
						field.selectors[0]?.name?.replaceAll(':', '') as keyof User
					] ? (
						<ItemWrapper
							mediaItems={mediaItems}
							key={field.value + index}
							user={user}
							field={field}
						/>
					) : null,
				)
				.filter((v) => !!v)
		: []

	// const bodies = [body, eyes, hair, voice, ability]
	// 	?.map((field) =>
	// 		field.selectors.map((selector) => ({
	// 			...field,
	// 			selectors: [selector],
	// 		})),
	// 	)
	// 	.flat()
	// 	?.map((field, index) =>
	// 		user?.[field.selectors[0]?.name?.replaceAll(':', '') as keyof User] ? (
	// 			<ItemWrapper key={field.value + index} user={user} field={field} />
	// 		) : null,
	// 	)
	// 	.filter((v) => !!v)

	const fields = actor
		? [body, bodyDone, bodyWilling, eyes, hair, voice, voiceSpecials, ability]
				?.map((field) =>
					field.selectors.map((selector) => ({
						...field,
						selectors: [selector],
					})),
				)
				.flat()
		: []

	const parsed = actor
		? [
				[
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Height')),
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Weight')),
				],
				[
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Body:Type')),
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Hair:Type')),
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Hair:Color')),
				],
				[
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Skin:Color')),
					fields?.find((v) => v.selectors?.[0]?.name?.includes('Eye:Color')),
				],
				[
					fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Vocal')),
					fields.find((v) => v.selectors?.[0].name.includes('Special')),
				],
				[],
				[],
				[fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Modification'))],
				[fields?.find((v) => v.selectors?.[0]?.name?.endsWith('Willing'))],
				[fields?.find((v) => v.value === 'ability')],
		  ]
		: []

	return (
		<SectionWrapper style={{ gap: '24px', width: '100%' }}>
			<>
				<div className={cn(s.wrapper, s[place])}>
					{user?.CustomUserBiography ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '10px',
								gridColumn: '2 span',
							}}>
							<span className="body1-b" style={{ color: 'var(--mono200)' }}>
								{t('APP_BIOGRAPHY_TAB')}
							</span>
							{textParser(user?.CustomUserBiography || '').map((text) => (
								<p
									key={text}
									style={{ color: 'var(--mono200)' }}
									className="body2-s">
									{text}{' '}
								</p>
							))}
						</div>
					) : null}
					{basics?.length ? (
						<div
							style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
							<span className="body1-b" style={{ color: 'var(--mono200)' }}>
								{t('APP_BASIC_LABEL')}
							</span>
							{basics}
						</div>
					) : null}
				</div>
				{parsed?.length ? (
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
						<span className="body1-b" style={{ color: 'var(--mono200)' }}>
							{t('APP_BODY_LABEL')}
						</span>
						<div className={cn(s.wrapper, s[place])}>
							{parsed?.map((array, i) =>
								array?.length ? (
									<div className={`grid${array?.length}column`} key={i}>
										<>
											{array
												?.filter((f) => !!f)
												?.map((field, index) =>
													field && user ? (
														<ItemWrapper
															key={field?.value + index}
															user={user}
															field={field}
															mediaItems={mediaItems}
														/>
													) : null,
												)}
										</>
									</div>
								) : null,
							)}
						</div>
					</div>
				) : null}
				{/* {bodies?.length ? (
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
						<span className="body1-b" style={{ color: 'var(--mono200)' }}>
							{t('APP_BODY_LABEL')}
						</span>
						<div className="grid3column">{bodies}</div>
					</div>
				) : null} */}
			</>
		</SectionWrapper>
	)
}

export default Personal
