import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import dayjs from 'dayjs'
import Card from 'components/Wizard/Card'
import Input from 'components/UI/Input'
import CoverButtonLayout from 'components/UI/CoverButtonLayout'
import Datepicker from 'components/UI/Datepicker'
import RadioButton from 'components/UI/RadioButton'
import InputLabel from 'components/UI/InputLabel'
import { useOutsideClick } from 'hooks'
import { t } from 'helpers'
import { useProductionStore } from 'store'
import s from '../ProductionBasic/index.module.scss'
import useContentAccess from 'hooks/useContentAccess'
import { PROJECT_ACCESS } from 'types/enums'
import { productionInfo } from 'helpers/formFields'
import { notif } from 'helpers/notif'

const ProductionInfo = (): JSX.Element => {
	const [production, onChange, onBlur, onChangeEvent, cover] =
		useProductionStore(
			useShallow((state) => [
				state.production,
				state.onChange,
				state.onBlur,
				state.onChangeEvent,
				state.production.cover,
			]),
		)
	const [picker, setPicker] = useState<'rehearsal' | 'filming' | null>(null)
	const pickerRef = useRef(null)
	const { productionId } = useParams()

	const { access } = useContentAccess(productionId)

	const disabled = access === PROJECT_ACCESS.READ

	useOutsideClick(pickerRef, () => setPicker(null))

	return (
		<Card
			step="create_production"
			noHint
			title={t('APP_CREATE_PRODUCTION_TITLE')?.replace(
				'{ProductionName}',
				production?.name || '',
			)}>
			<>
				<div className="grid2column" style={{ position: 'relative' }}>
					<div className={s.info} style={{ gap: '20px' }}>
						<Input
							name="directorName"
							label={productionInfo.directorName.label}
							onChange={onChange}
							value={production?.directorName}
							onBlur={(e) => {
								if (productionId) onBlur(e, productionId)
								else
									notif(
										'info',
										t('APP_PLEASE_FINISH_FIRST_PROJECT_STEP_TO_PROCEED'),
									)
							}}
							disabled={disabled}
						/>

						<Input
							label={productionInfo.rehearsal.label}
							onClick={() => setPicker('rehearsal')}
							value={
								production?.rehearsal?.length
									? dayjs(production?.rehearsal[0]).format('MM/DD/YYYY') +
									  ' - ' +
									  dayjs(production?.rehearsal[1]).format('MM/DD/YYYY')
									: ''
							}
							onChange={() => null}
							disabled={disabled}
						/>

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<InputLabel
								label={productionInfo.format.label}
								status={production?.format?.length ? 'filled' : 'none'}
							/>
							<div className="grid3column">
								{['intro', 'slate', 'tape'].map((type) => (
									<RadioButton
										key={type}
										value={type}
										active={production?.format?.includes(type) ? type : ''}
										label={`APP_${type?.toUpperCase()}_SCENE_LABEL`}
										onClick={() => {
											if (disabled) return
											if (productionId)
												onChangeEvent(
													'format',
													production?.format?.includes(type)
														? production.format.filter((v) => v !== type)
														: [...production.format, type],
													productionId,
												)
											else
												notif(
													'info',
													t('APP_PLEASE_FINISH_FIRST_PROJECT_STEP_TO_PROCEED'),
												)
										}}
									/>
								))}
							</div>
						</div>
					</div>
					<div className={s.info} style={{ gap: '20px' }}>
						{picker ? (
							<div
								ref={pickerRef}
								className={cn('br', s.picker, 'grid2column')}>
								<Datepicker
									value={
										production?.[picker]?.[0]
											? new Date(production?.[picker]?.[0]).toISOString()
											: new Date().toISOString()
									}
									rangeValues={[
										production?.[picker]?.[0]
											? new Date(production?.[picker]?.[0]).toISOString()
											: new Date().toISOString(),
										production?.[picker]?.[1]
											? new Date(production?.[picker]?.[1]).toISOString()
											: new Date().toISOString(),
									]}
									onChange={(date) => {
										const newDate = dayjs(date).format('MM/DD/YYYY')
										const diff = dayjs(production?.[picker]?.[1]).diff(
											newDate,
											'day',
										)

										if (productionId)
											onChangeEvent(
												picker,
												[
													newDate as string,
													(diff > 0
														? production?.[picker]?.[1]
														: dayjs(newDate)
																.add(1, 'day')
																.format('MM/DD/YYYY')) as string,
												],
												productionId,
											)
										else
											notif(
												'info',
												t('APP_PLEASE_FINISH_FIRST_PROJECT_STEP_TO_PROCEED'),
											)
									}}
									type="range"
								/>
								<Datepicker
									value={
										production?.[picker]?.[1]
											? new Date(production?.[picker]?.[1]).toISOString()
											: new Date().toISOString()
									}
									rangeValues={[
										production?.[picker]?.[0]
											? new Date(production?.[picker]?.[0]).toISOString()
											: new Date().toISOString(),
										production?.[picker]?.[1]
											? new Date(production?.[picker]?.[1]).toISOString()
											: new Date().toISOString(),
									]}
									onChange={(date) => {
										if (productionId)
											onChangeEvent(
												picker,
												[
													production?.[picker]?.[0],
													dayjs(date).format('MM/DD/YYYY') as string,
												],
												productionId,
											)
										else
											notif(
												'info',
												t('APP_PLEASE_FINISH_FIRST_PROJECT_STEP_TO_PROCEED'),
											)
										if (production?.[picker]?.length > 1) setPicker(null)
									}}
									type="range"
								/>
							</div>
						) : null}

						<Input
							name="imdbLink"
							label={productionInfo.imdbLink.label}
							onChange={onChange}
							value={production?.imdbLink}
							onBlur={(e) => {
								if (productionId) onBlur(e, productionId)
								else
									notif(
										'info',
										t('APP_PLEASE_FINISH_FIRST_PROJECT_STEP_TO_PROCEED'),
									)
							}}
							placeholder="https://www.imdb.com/name/"
							disabled={disabled}
						/>
						<Input
							label={productionInfo.filming.label}
							onClick={() => setPicker('filming')}
							value={
								production?.filming?.length
									? dayjs(production?.filming[0]).format('MM/DD/YYYY') +
									  ' - ' +
									  dayjs(production?.filming[1]).format('MM/DD/YYYY')
									: ''
							}
							onChange={() => null}
							disabled={disabled}
						/>
						<CoverButtonLayout
							title="APP_CUSTOM_COVER_BUTTON"
							label={productionInfo.cover.label}
							status={
								production?.color || production?.cover ? 'filled' : 'none'
							}
							onClick={() => {
								if (disabled) return
								const { setOpenCoverSidebar } = useProductionStore.getState()
								setOpenCoverSidebar(true)
							}}
							image={cover}
						/>
					</div>
				</div>
			</>
		</Card>
	)
}

export default ProductionInfo
