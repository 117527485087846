import { ageFilterParser, rangeFilterParser } from 'helpers/propertiesParser'
import { attributeBasicFields } from 'pages/Portfolio/fields'
import { useMemo } from 'react'
import { useFilterStore } from 'store'
import { FilterSearch } from 'types/app'
import { FILTER_CONDITION, SORT_FORMAT } from 'types/enums'
import { useShallow } from 'zustand/react/shallow'

const useFilterParser = (): FilterSearch[] => {
	const filters = useFilterStore(useShallow((state) => state.filters))

	const ageFilter = useMemo(
		() =>
			ageFilterParser([
				{
					propName: 'Customer:Age',
					propValue: filters?.['Customer:Age'] as string,
				},
			]),
		[filters],
	)
	const heightFilter = useMemo(() => {
		const name =
			attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Height'),
			)?.name || ''

		return rangeFilterParser(
			[
				{
					propName: name,
					propValue: filters?.[name] as string,
				},
			],
			name,
		)
	}, [filters])
	const weightFilter = useMemo(() => {
		const name =
			attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Weight'),
			)?.name || ''

		return rangeFilterParser(
			[
				{
					propName: name,
					propValue: filters?.[name] as string,
				},
			],
			name,
		)
	}, [filters])
	const imdbFilter = useMemo(
		() =>
			Array.isArray(filters?.imdbLink) && filters?.imdbLink?.length
				? [
						{
							propName: 'Customer:ImdbUrl',
							propValue:
								Array.isArray(filters?.imdbLink) && filters?.imdbLink?.length
									? 'imdb'
									: '',
							cond: FILTER_CONDITION.EXISTS,
							format: SORT_FORMAT.TEXT,
						},
				  ]
				: [],
		[filters?.imdbLink],
	)

	const userStatus =
		(Object.entries(filters).find(
			([key]) => key === 'userStatus',
		)?.[1] as string) || ''

	const parsedFilters = [
		...Object.entries(filters)
			.filter(
				([key]) =>
					!key.endsWith('Age') &&
					!key.endsWith('eight') &&
					!['userStatus', 'userSearch', 'imdbLink'].includes(key),
			)
			.filter(([_, value]) => !!value)
			.map(([name, value]) => {
				return {
					propName: name,
					propValue: value
						? typeof value === 'object'
							? JSON.stringify(value)
							: value?.toString()
						: '',
					format: JSON.stringify(value)?.startsWith('[{')
						? SORT_FORMAT.OBJECT
						: JSON.stringify(value)?.startsWith('[')
						? SORT_FORMAT.LIST
						: SORT_FORMAT.TEXT,
				}
			}),
		...ageFilter,
		...imdbFilter,
		...weightFilter,
		...heightFilter,
		{
			propName: 'Customer:Process:Status',
			propValue: userStatus ? 'Invited' : '',
			cond:
				userStatus === 'Active'
					? FILTER_CONDITION.NOT_EQUAL
					: FILTER_CONDITION.EQUAL,
		},
	]

	return parsedFilters
}

export default useFilterParser
