import { useEffect, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import AttributeCard from 'pages/Portfolio/components/AttributeCard'
import { t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { attributeBasicFields, attributeSpecialFields } from '../../fields'
import { User } from 'types/user'
import { PORTFOLIO_TYPES } from 'types/enums'

const Attributes = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (user && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setCardValues } = usePortfolioStore.getState()
			const fields = Object.values(attributeBasicFields).concat(
				Object.values(attributeSpecialFields),
			)
			fields.forEach((field) =>
				field.selectors.forEach((selector) => {
					let value =
						user[
							selector.name?.replaceAll(':', '') as keyof User
						]?.toString() || ''

					if (selector.type === 'checkbox' && value && value?.startsWith('['))
						value = JSON.parse(value)
					setCardValues(
						{
							[selector.name]: value
								? value
								: selector.name?.includes('Weight')
								? 0
								: selector.name?.includes('Height')
								? 0
								: '',
						},
						field.value,
					)
				}),
			)
		}
	}, [user])

	return (
		<Card step="attributes" noTitle>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}>
				<h3 className="h3-m">{t('APP_BASIC_ATTRIBUTES_TITLE')}</h3>
				<div className="grid2column">
					{Object.values(attributeBasicFields)?.map((field) => (
						<AttributeCard
							generalType={PORTFOLIO_TYPES.ATTRIBUTE}
							key={field.value}
							type={field.value}
							cardData={field}
						/>
					))}
				</div>
				<h3 className="h3-m">{t('APP_SPECIAL_ATTRIBUTES_TITLE')}</h3>
				<div className="grid2column">
					{Object.values(attributeSpecialFields)?.map((field) => (
						<AttributeCard
							generalType={PORTFOLIO_TYPES.ATTRIBUTE}
							key={field.value}
							type={field.value}
							cardData={field}
						/>
					))}
				</div>
			</div>
		</Card>
	)
}

export default Attributes
