import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import RoleCard from 'components/RoleCard'
import Select from 'components/UI/Select'
import useCollection from 'hooks/api/useCollection'
import useContentSearch from 'hooks/api/useContentSearch'
import { t } from 'helpers'
import { roleInfo } from 'helpers/formFields'
import { EventBus } from 'helpers/EventBus'
import { useFilterStore } from 'store'
import { Content, SelectOption } from 'types/app'
import {
	attributeBasicFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'

const CastingFilter = (): JSX.Element => {
	const { contents, isLoading } = useContentSearch(true, 'Collection')
	const [prodSearch, setProdSearch] = useState('')
	const [activeRole, setActiveRole] = useState('')

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return (
		<>
			<Loader loading={isLoading} adapt />
			<Select
				name="productions"
				options={productions}
				error=""
				label="APP_PRODUCTION_SELECT_LABEL"
				onChange={(e) => {
					setProdSearch(e.target.value)
				}}
				inputProps={{
					value: prodSearch,
					placeholder: t('APP_ALL_PRODUCTIONS'),
				}}
			/>
			{contents
				?.filter((content) =>
					prodSearch ? content?.ContentId === prodSearch : content,
				)
				?.map((content) => (
					<CastingRole
						key={content?.ContentId}
						production={content}
						activeRole={activeRole}
						setActiveRole={setActiveRole}
					/>
				))}
		</>
	)
}

export default CastingFilter

const CastingRole = ({
	production,
	activeRole,
	setActiveRole,
}: {
	production: Content
	activeRole: string
	setActiveRole: (id: string) => void
}): JSX.Element => {
	const fieldsSearch = useFilterStore(useShallow((state) => state.fieldsSearch))
	const { collection } = useCollection(production)

	const roleParametersFilter = (content: Content): void => {
		const { setFilters } = useFilterStore.getState()
		const { gender, language, race, age, height, weight } = roleInfo

		const filters = {
			[personalFields.gender.name]:
				(content[
					gender.name?.replaceAll(':', '') as keyof Content
				] as string) || '',
			[skillFields.spokenLanguage.selectors[0].name]: [
				{
					Value:
						(content[
							language.name?.replaceAll(':', '') as keyof Content
						] as string) || '',
				},
			],
			[personalFields.race.name]:
				(content[race.name?.replaceAll(':', '') as keyof Content] as string) ||
				'',
			['Customer:Age']:
				(content[age.name?.replaceAll(':', '') as keyof Content] as string) ||
				'',
			[attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Height'),
			)?.name || '']:
				(content[
					height.name?.replaceAll(':', '') as keyof Content
				] as string) || '',
			[attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Weight'),
			)?.name || '']:
				(content[
					weight.name?.replaceAll(':', '') as keyof Content
				] as string) || '',
		}

		setFilters(filters)
		setActiveRole(content.ContentId)
		EventBus.$emit('openAdvancedFilter')
	}

	return (
		<>
			{collection
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						.includes(fieldsSearch?.toLowerCase()),
				)
				?.map((content) => (
					<div
						key={content?.ContentId}
						style={{
							transition: 'var(--transition)',
							borderRadius: '25px',
							border: `1px solid ${
								activeRole === content.ContentId
									? 'var(--mono300)'
									: 'transparent'
							}`,
						}}>
						<RoleCard
							content={content}
							showProd
							type="horizontal"
							onClick={() => roleParametersFilter(content)}
						/>
					</div>
				))}
		</>
	)
}
