import { useParams } from 'react-router-dom'
import { PlusIcon } from 'assets/icons'
import RoleCard from 'components/RoleCard'
import useAccessRelation from 'hooks/api/useAccessRelation'
import useCastingRelation from 'hooks/api/useCastingRelation'
import useContents from 'hooks/api/useContents'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t } from 'helpers'
import { Content } from 'types/app'
import { RELATIONS } from 'types/enums'

interface Props {
	roleSearch: string
	openMessage: (c: Content, p?: Content) => void
}

const AgencyList = ({ roleSearch, openMessage }: Props): JSX.Element => {
	const { data } = useCastingRelation('/Lookup')

	const { contents } = useContents(data?.map((rel) => rel.sourceId))

	return (
		<>
			{contents
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						?.includes(roleSearch?.toLowerCase()),
				)
				?.map((content) => (
					<RoleList
						key={content.ContentId}
						content={content}
						openMessage={openMessage}
					/>
				))}
		</>
	)
}

export default AgencyList

const RoleList = ({
	content,
	openMessage,
}: {
	content: Content
	openMessage: (c: Content, p?: Content) => void
}): JSX.Element => {
	const { profileId } = useParams()

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const { data: access } = useAccessRelation()

	const alreadyInvitedRoles = access
		?.filter((rel) => rel.targetId === profileId)
		?.map((rel) => rel.sourceId)

	return (
		<RoleCard
			key={content?.ContentId}
			content={content}
			type="horizontal"
			showProd
			children={
				<>
					{alreadyInvitedRoles?.includes(content.ContentId) ? (
						<div className="hoverButtonWrapper" style={{ cursor: 'default' }}>
							<PlusIcon style={{ transform: 'rotate(45deg)' }} />
							<span>{t('APP_ALREADY_INVITED_TO_ROLE')}</span>
						</div>
					) : (
						<div
							className="hoverButtonWrapper"
							onClick={() => openMessage(content, prod)}>
							<PlusIcon />
							<span>{t('APP_INVITE_TO_ROLE')}</span>
						</div>
					)}
				</>
			}
		/>
	)
}
