import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import { sendFeedback } from 'api/user'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Textarea from 'components/UI/Textarea'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { FEEDBACKS } from 'types/enums'
import { notif } from 'helpers/notif'

const FeedbackSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const { profileId } = useParams()
	const message = useRef<HTMLTextAreaElement>(null)
	const [openSidebar, type, setOpenSidebar] = useAppStore(
		useShallow((state) => [
			!!state.openFeedbackSidebar?.open,
			state.openFeedbackSidebar?.type,
			state.setOpenFeedbackSidebar,
		]),
	)

	const key = type || FEEDBACKS.FEEDBACK

	const sendUserFeedback = async (): Promise<void> => {
		setLoading(true)
		try {
			const { user } = useUserStore.getState()
			const body = `${user?.ContentTitle} (${user?.ContentId})<br />URL<br />${
				window.location.href
			}<br />Local Date<br />${new Date().toISOString()}${
				type === FEEDBACKS.PROFILE_REPORT && profileId
					? `<br />Reported user<br />${profileId}`
					: ''
			}<br />Message<br />${message.current?.value}`
			await sendFeedback(
				(type === FEEDBACKS.PROFILE_REPORT
					? 'Profile report by '
					: 'Feedback by ') +
					user?.ContentTitle +
					' at ' +
					dayjs().format('MM-DD-YYYY HH:mm'),
				body,
			)
			notif('success', t('APP_FEEDBACK_SUCCESS'))
		} catch (error) {
			console.log('error:', error)
			notif('error', t('APP_FEEDBACK_ERROR'))
		} finally {
			setLoading(false)
			setOpenSidebar(null)
		}
	}

	useEffect(() => {
		if (!openSidebar && message?.current) {
			message.current.value = ''
		}
	}, [openSidebar])

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(null)
			}}
			header={{
				title: `APP_${key}_SIDEBAR_TITLE`,
				description: `APP_${key}_SIDEBAR_DESCRIPTION`,
			}}
			buttons={[
				{
					children: t('APP_SEND_MESSAGE'),
					onClick: sendUserFeedback,
				},
			]}
			style={{ height: '100%' }}>
			<>
				<Loader adapt loading={loading} />
				<Textarea
					name="feedback_comment"
					label=""
					inputRef={message}
					rows={20}
					placeholder={t(`APP_APP_${key}_PLACEHOLDER`)}
				/>
			</>
		</Sidebar>
	)
}

export default FeedbackSidebar
