import { useShallow } from 'zustand/react/shallow'
import RadioButton from 'components/UI/RadioButton'
import { optionsParser, t } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { AttributeSelector } from 'types/app'
import { User } from 'types/user'

interface Props {
	selector: AttributeSelector
}

const Switch = ({ selector }: Props): JSX.Element => {
	const options = optionsParser(selector.optionName || '')

	const values = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[state.modalType] && state.cardValues[state.modalType],
		),
	)

	const value = values?.[selector.name] as string

	return (
		<>
			<span
				className="caption-m inputLabel"
				style={{ gridColumn: 'span 2', paddingBottom: '0' }}>
				{t(selector.label)}
			</span>
			{selector?.name?.includes('Pinned') ? (
				<div className="grid3column">
					{options?.map((option) => (
						<RadioButton
							icon={<></>}
							key={option.value}
							value={option.value}
							active={value}
							label={option.label}
							onClick={() => {
								// const { user } = useUserStore.getState()
								const { setCardValues, updateFields, cardData } =
									usePortfolioStore.getState()

								setCardValues({
									[selector.name]: option.value,
								})
								// const prevValue = JSON.parse(
								// 	(user?.[
								// 		selector?.name?.replaceAll(':', '') as keyof User
								// 	] as string) || '{}',
								// )
								// updateFields([
								// 	{
								// 		Name: selector.name,
								// 		Value: JSON.stringify({
								// 			...prevValue,
								// 			[option.value]: cardData?.index,
								// 		}),
								// 	},
								// ])
							}}
						/>
					))}
				</div>
			) : (
				options?.map((option) => (
					<RadioButton
						key={option.value}
						value={option.value}
						active={value}
						label={option.label}
						onClick={() => {
							const { setCardValues, setGalleryType } =
								usePortfolioStore.getState()

							setGalleryType({
								value: option.value,
								label: selector.label,
							})
							setCardValues({
								[selector.name]: option.value,
							})
						}}
					/>
				))
			)}
		</>
	)
}

export default Switch
