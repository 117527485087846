import { useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon } from 'assets/icons'
import FilterSelector from '../FilterSelector'
import FilterTable from '../FilterTable'
import { t } from 'helpers'
import { useFilterStore } from 'store'
import s from './index.module.scss'
import { FilterSection } from 'types/app'

interface Props {
	section: FilterSection
}

const Section = ({ section }: Props): JSX.Element => {
	const [expanded, setExpanded] = useState(!section?.hideByDefault)
	const headerHeight = useRef(24)

	const filledCount = useFilterStore(
		useShallow(
			(state) =>
				Object.entries(state.filters)
					?.filter(
						([key, value]) =>
							section?.fields?.map((field) => field.name)?.includes(key) &&
							!!value,
					)
					?.map(([_, value]) => value)
					?.reduce(
						(prev, next) =>
							((prev as number) || 0) +
							(Array.isArray(next) ? next?.length : 1),
						0,
					) as number,
		),
	)

	const onExpand = (): void => {
		setExpanded((prev) => !prev)
	}

	const resetSection = (): void => {
		const { setFilters } = useFilterStore.getState()
		const reseted = section.fields
			?.map((field) => ({
				[field.name]: null,
			}))
			.reduce(
				(prev, next) => ({
					...prev,
					...next,
				}),
				{},
			)
		setFilters(reseted)
	}

	useEffect(() => {
		if (section?.hideByDefault !== undefined)
			setExpanded(!section?.hideByDefault)
	}, [section?.hideByDefault])

	return (
		<div
			id={section.title + '_wrapper'}
			className={s.section}
			style={{
				height: expanded ? '100%' : headerHeight.current,
				overflow: expanded ? 'visible' : 'hidden',
				transition: 'none',
			}}>
			{section?.title ? (
				<div className={s.header}>
					<span className="body2-b" onClick={onExpand}>
						{t(section.title)}
					</span>
					{filledCount > 0 ? (
						<div className={s.count}>
							<span className="caption-b">{filledCount}</span>
						</div>
					) : null}
					<div className={s.expand}>
						{filledCount > 0 ? (
							<span className="body2-m" onClick={resetSection}>
								{t('APP_RESET')}
							</span>
						) : null}
						<div onClick={onExpand}>
							<ArrowShortIcon
								style={{
									transform: `rotate(${expanded ? '-90deg' : '90deg'})`,
								}}
							/>
						</div>
					</div>
				</div>
			) : null}
			<div className={s.fieldsWrapper}>
				{section?.fields?.length === 2 &&
				section?.fields[0]?.type === 'checkbox' &&
				section?.fields[1]?.type === 'select' ? (
					<FilterTable
						skillField={section.fields[0]}
						levelField={section.fields[1]}
					/>
				) : (
					section?.fields?.map((field) => (
						<FilterSelector field={field} key={field.name} />
					))
				)}
			</div>
		</div>
	)
}

export default Section
