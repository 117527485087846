import { useParams } from 'react-router-dom'
import ContentPageLayout from 'components/ContentPageLayout'

const Tips = (): JSX.Element => {
	const { role } = useParams()

	return <ContentPageLayout contentKey={'tips_' + role} type="collapsing" />
}

export default Tips
