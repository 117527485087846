import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HorizontalNetworkCard from 'components/HorizontalNetworkCard'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { useNetworkStore } from 'store'
import { FILTER_CONDITION, SORT_DIRECTION } from 'types/enums'
import { User } from 'types/user'

const TopPortfolios = (): JSX.Element => {
	const navigate = useNavigate()
	const [activeTab, setActiveTab] = useState(0)

	const { data } = useCustomerSearch({
		fetch: true,
		itemLimit: 9,
		filters: [
			{
				propName: 'Customer:Portfolio:Readiness',
				propValue: '90',
				cond: FILTER_CONDITION.GREATER_OR_EQUAL,
			},
		],
		orders: [
			{
				sortBy: 'Customer:CreatedDate',
				sortDir: SORT_DIRECTION.RANDOM,
			},
		],
	})

	const onUserClick = async (user: User, users: User[]): Promise<void> => {
		const { userProfile, setUserProfile, setNetworkRedirectionLink } =
			useNetworkStore.getState()
		setNetworkRedirectionLink(window.location.pathname + window.location.hash)
		const path = await userProfile.onUserChange(user)
		navigate(path)
		setUserProfile({ users, user, listSource: 'APP_RECENT_LIST_TITLE' })
	}

	return (
		<>
			<SidebarTabs
				active={activeTab}
				options={[
					{
						value: 0,
						label: 'APP_TOP_PORTFOLIOS_TAB',
					},
				]}
				onChange={(v) => {
					setActiveTab(v as number)
				}}
				type="fit"
			/>
			<div className="grid3column">
				{data?.map((user) => (
					<HorizontalNetworkCard
						user={user}
						key={user.ContentId}
						onClick={() => onUserClick(user, data)}
					/>
				))}
			</div>
		</>
	)
}

export default TopPortfolios
