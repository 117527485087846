import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import ProfileLayout from 'components/ProfileLayout'
import useUser from 'hooks/api/useUser'
import { useAppStore, useModalStore } from 'store'

const UserInfoSidebar = (): JSX.Element => {
	const [userId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.userInfoSidebar, state.setUserInfoSidebar]),
	)
	const modal = useModalStore(useShallow((state) => state.modal?.show))

	const { user } = useUser(userId)

	return (
		<Sidebar
			open={!!userId}
			iconClose={modal}
			onClose={() => setOpenSidebar('')}
			header={{ title: '' }}>
			<ProfileLayout user={user} place="sidebar" />
		</Sidebar>
	)
}

export default UserInfoSidebar
