import { ButtonHTMLAttributes } from 'react'
import { ArrowLongIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	prevText?: string
	nextText?: string
	prevClick?: () => void
	nextClick?: () => void
	nextIcon?: boolean
	centered?: boolean
	nextProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

const Controls = ({
	prevText,
	nextClick,
	prevClick,
	nextIcon = true,
	nextText,
	centered,
	nextProps,
}: Props): JSX.Element => (
	<div className={s.container}>
		<Button
			style={{
				opacity: prevClick ? '1' : '0',
				visibility: prevClick ? 'visible' : 'hidden',
				display: centered ? 'none' : 'flex',
			}}
			onClick={prevClick}>
			{t(prevText || '')}
		</Button>
		<Button
			className={s.next}
			variant="themed"
			style={{
				opacity: nextClick ? '1' : '0',
				visibility: nextClick ? 'visible' : 'hidden',
			}}
			onClick={nextClick}
			{...nextProps}>
			{t(nextText || '')}
			{nextIcon ? <ArrowLongIcon color="var(--theme-primary)" /> : null}
		</Button>
	</div>
)

export default Controls
