import cn from 'classnames'
import { useRef, useState } from 'react'
import { PlayIcon, PlusIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import Player from 'components/Player'
import useContent from 'hooks/api/useContent'
import { useOutsideClick } from 'hooks'
import { t } from 'helpers'
import { useAppStore } from 'store'
import s from './index.module.scss'

interface Props {
	step: string
}

const contents: { [key: string]: string } = {
	'basic-0': '4373c8ce-85d6-4f85-abc9-f1b908e766d0',
	'basic-1': 'f599f038-e600-4a40-b765-f75d801d9b50',
	'basic-2': '55f38439-7671-4fd7-8ca8-86ff86187b30',
	'basic-3': '67afb7b6-56a5-436e-94d6-12e24e3dd2fc',
	'basic-4': 'f7d3f264-5d4b-4ce8-99c9-8f633a1bca48',
	'pictures-0': '25bf024b-afbd-46e3-87f3-bbb302190ba3',
	'pictures-1': '25bf024b-afbd-46e3-87f3-bbb302190ba3',
	'pictures-2': '25bf024b-afbd-46e3-87f3-bbb302190ba3',
	'pictures-3': '25bf024b-afbd-46e3-87f3-bbb302190ba3',
	'showreels-0': '6f27f2cb-91b5-4740-89e2-92fb512e996a',
	'showreels-1': '6f27f2cb-91b5-4740-89e2-92fb512e996a',
	'showreels-2': '6f27f2cb-91b5-4740-89e2-92fb512e996a',
	'showreels-3': '6f27f2cb-91b5-4740-89e2-92fb512e996a',
	'attributes-0': 'edca2258-da9c-41d8-9d0b-99015154f17c',
	'attributes-1': '8418cb2d-7a3c-442c-a136-7e32ca3146cb',
}

const Tutorial = ({ step }: Props): JSX.Element | null => {
	const [showTutorial, setShowTutorial] = useState(false)
	const containerRef = useRef(null)
	const id = contents[step]
	const { content } = useContent(id)

	const openTutorial = (): void => {
		const { setShowBackgroundBlur } = useAppStore.getState()
		setShowBackgroundBlur(true)
		setShowTutorial(true)
	}

	const closeTutorial = (): void => {
		const { setShowBackgroundBlur, popupVisible } = useAppStore.getState()
		if (popupVisible) return
		setShowBackgroundBlur(false)
		setShowTutorial(false)
	}

	useOutsideClick(containerRef, closeTutorial)

	return step?.includes('summary') ? null : (
		<Card
			className={cn(s.container, {
				[s.open]: showTutorial,
				['playerLayoutContainer']: showTutorial,
			})}
			containerRef={containerRef}
			style={
				showTutorial
					? {}
					: {
							backgroundColor: content?.ContentColor,
					  }
			}>
			<>
				{content ? (
					<Player
						videoId={`tutorial-video_${step}`}
						content={content}
						active={showTutorial}
						videoProps={{
							style: {
								display: showTutorial ? 'block' : 'none',
								objectFit: 'cover',
							},
						}}
						wrapperStyles={{
							maxWidth: '100%',
						}}
					/>
				) : null}
				<div
					className={cn(s.close, { [s.showClose]: showTutorial })}
					onClick={closeTutorial}>
					<PlusIcon
						width="48"
						height="48"
						style={{ transform: 'rotate(45deg)' }}
					/>
				</div>
				<div className={cn(s.description, { [s.hideDesc]: showTutorial })}>
					<div className={s.descContent}>
						<h3
							dangerouslySetInnerHTML={{
								__html: content?.['Globalizationen-USContentTitle'] || '',
							}}></h3>
						<Button
							variant="filled"
							onClick={openTutorial}
							className="body1-m"
							style={{ gap: '8px' }}>
							{t('APP_SHOW_BUTTON')}
							<PlayIcon />
						</Button>
					</div>
				</div>
			</>
		</Card>
	)
}

export default Tutorial
