import { useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { useNetworkStore } from 'store'
import { RELATIONS } from 'types/enums'
import { User } from 'types/user'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'

const InvitesToPoolCarousel = (): JSX.Element => {
	const { data, refresh } = useRelationsContent([RELATIONS.GROUP_INVITE])
	const invites = data?.[RELATIONS.GROUP_INVITE] as unknown as User[]

	useEffect(() => {
		EventBus.$on('refreshInvitesToPool', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshInvitesToPool')
		}
	}, [refresh])

	return (
		<>
			{invites?.length ? (
				<RecentCarousel name="">
					<>
						{invites?.map((user) => (
							<SwiperSlide key={user?.ContentId}>
								<NetworkCard
									style={{ width: '100%' }}
									variant="small"
									data={user}
									onClick={() => {
										const { setAcceptInviteToPoolSidebar } =
											useNetworkStore.getState()
										setAcceptInviteToPoolSidebar(user.ContentId)
									}}
								/>
							</SwiperSlide>
						))}
					</>
				</RecentCarousel>
			) : (
				<div className="grid5column">
					<CreateCardPlaceholder label="APP_RESULT_NOT_FOUND" />
				</div>
			)}
		</>
	)
}

export default InvitesToPoolCarousel
