import { defaultCountries, parseCountry } from 'react-international-phone'
import { createWithEqualityFn } from 'zustand/traditional'
import { AppStore } from 'types/store'

const useStore = createWithEqualityFn<AppStore>(
	(set) => ({
		countries: defaultCountries.map((country) => parseCountry(country)),
		endpoints: null,
		setEndpoints: (endpoints) => set({ endpoints }),
		locales: localStorage.getItem('Locales')
			? JSON.parse(localStorage.getItem('Locales') as string)
			: null,
		setLocales: (locales) => set({ locales }),
		stepWizard: null,
		setStepWizard: (wizard) => set({ stepWizard: wizard }),
		chatId: '',
		setChatId: (id) => set({ chatId: id }),
		showBackgroundBlur: false,
		setShowBackgroundBlur: (show) => set({ showBackgroundBlur: show }),
		openQRSidebar: false,
		setOpenQRSidebar: (open) =>
			set({
				openQRSidebar: open,
			}),
		roleInfoSidebar: '',
		setRoleInfoSidebar: (id) => set({ roleInfoSidebar: id }),
		prodInfoSidebar: '',
		setProdInfoSidebar: (id) => set({ prodInfoSidebar: id }),
		userInfoSidebar: '',
		setUserInfoSidebar: (id) => set({ userInfoSidebar: id }),
		localSearchSidebar: null,
		setLocalSearchSidebar: (search) =>
			set((state) => ({
				localSearchSidebar: state.localSearchSidebar
					? {
							...state.localSearchSidebar,
							...search,
					  }
					: (search as null),
			})),
		documentViewerSidebar: null,
		setDocumentViewerSidebar: (data) => set({ documentViewerSidebar: data }),
		openFeedbackSidebar: null,
		setOpenFeedbackSidebar: (open) => set({ openFeedbackSidebar: open }),
		addSceneSidebar: {
			open: false,
			openInstructions: false,
			setOpenInstructions: (open) =>
				set((state) => ({
					addSceneSidebar: {
						...state.addSceneSidebar,
						openInstructions: open,
					},
				})),
			scene: {
				title: '',
				duration: 180,
				attachments: [],
				code: '',
				dress: '',
				instructions: [],
			},
			setScene: (value) =>
				set((state) => ({
					addSceneSidebar: {
						...state.addSceneSidebar,
						scene: {
							...state.addSceneSidebar.scene,
							...value,
						},
					},
				})),
		},
		setOpenAddSceneSidebar: (open) =>
			set((state) => ({ addSceneSidebar: { ...state.addSceneSidebar, open } })),
		addContactSidebar: {
			open: false,
			onAdd: () => null,
		},
		setAddContactSidebar: (open, onAdd, additional) =>
			set({
				addContactSidebar: {
					open,
					onAdd,
					additional,
				},
			}),
		openMessageSidebar: {
			open: false,
			key: '',
			onDone: () => null,
		},
		setOpenMessageSidebar: (open, key, onDone, value) =>
			set({
				openMessageSidebar: {
					open,
					key,
					onDone,
					value,
				},
			}),
		portfolioMode: {
			active: false,
			redirectUrl: '',
		},
		setPortfolioMode: (data) =>
			set((state) => ({
				portfolioMode: {
					...state.portfolioMode,
					...data,
				},
			})),
		addUserContactSidebar: {
			open: false,
			onAdd: null,
		},
		setUserContactSidebar: (data) => set({ addUserContactSidebar: data }),
		popupVisible: false,
		setPopupVisible: (visible) => set({ popupVisible: visible }),
	}),
	Object.is,
)

export default useStore
