import {
	CSSProperties,
	useCallback,
	useEffect,
	useMemo,
	useState,
	VideoHTMLAttributes,
} from 'react'
import { contentAuthorize } from 'api/auth'
import Controls from './components/Controls'
import Subtitles from './components/Subtitles'
import useContentHitContract from 'hooks/useContentHitContract'
import useShakaPlayer from 'hooks/useShakaPlayer'
import useContentConsumptionContract from 'hooks/useContentConsumptionContract'
import { propertiesParser } from 'helpers/propertiesParser'
import { useCastingStore } from 'store'
import { ContentAuthorizeType } from 'types/content'
import { Content } from 'types/app'

interface Props {
	active: boolean
	content: Content
	videoId: string
	videoProps?: VideoHTMLAttributes<HTMLVideoElement>
	wrapperStyles?: CSSProperties
}

const Player = ({
	active,
	content,
	videoId,
	videoProps,
	wrapperStyles,
}: Props): JSX.Element => {
	const [videoData, setVideoData] = useState<ContentAuthorizeType>()

	const { shakaInstance } = useShakaPlayer(videoData?.ContentUrl || '', videoId)

	useContentHitContract(
		active && videoData?.ContentPlaybackSessionId ? content : undefined,
		videoData?.ContentPlaybackSessionId,
	)

	const { onEnded, onPause, onPlay } = useContentConsumptionContract(
		content,
		shakaInstance,
		videoData?.ContentPlaybackSessionId,
	)

	const subtitles = useMemo(
		() =>
			videoData?.ContentPlaybackSubtitleVtt
				? [
						{ langCode: 'off', url: '' },
						...Object.values(videoData.ContentPlaybackSubtitleVtt).map(
							(url) => {
								const langCode = url.slice(-9, -4)

								return { langCode, url }
							},
						),
				  ]
				: null,
		[videoData?.ContentPlaybackSubtitleVtt],
	)

	const fetchVideoUrl = useCallback(async (): Promise<void> => {
		try {
			const response = await contentAuthorize(content.ContentId)
			if (response?.Properties) {
				const data = propertiesParser(
					response.Properties,
				) as ContentAuthorizeType
				setVideoData(data)
				const { setContentData } = useCastingStore.getState()
				setContentData({
					contentId: content.ContentId,
					assetId: data.ContentDownloadOriginalAssetId,
				})
			}
		} catch (error) {
			console.log('error:', error)
		}
	}, [content.ContentId])

	useEffect(() => {
		if (active && !videoData?.ContentUrl) {
			fetchVideoUrl()
		}
	}, [videoData?.ContentUrl, active, fetchVideoUrl])

	return (
		<>
			<div
				style={{
					width: '100%',
					height: '100%',
					...wrapperStyles,
				}}>
				<video
					id={videoId}
					data-active={active}
					onEnded={onEnded}
					onPause={onPause}
					onPlay={onPlay}
					crossOrigin="anonymous"
					{...videoProps}
					style={{
						width: '100%',
						height: '100%',
						...videoProps?.style,
					}}
				/>
			</div>
			{active ? (
				<>
					<Subtitles
						videoElement={document.getElementById(videoId) as HTMLVideoElement}
						subtitles={subtitles}
					/>
					<Controls
						shakaInstance={shakaInstance}
						active={active}
						contentId={content?.ContentId}
						assetId={videoData?.ContentDownloadOriginalAssetId}
						subtitles={subtitles}
					/>
				</>
			) : null}
		</>
	)
}

export default Player
