import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { SearchIcon } from 'assets/icons'
import Input from 'components/UI/Input'
import Section from './components/Section'
import InputLabel from 'components/UI/InputLabel'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { FilterSection } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'

const attr = PORTFOLIO_TYPES.ATTRIBUTE
const skill = PORTFOLIO_TYPES.SKILL

const values = [
	{
		type: attr,
		attribute: attributeSpecialFields.ability,
	},
	{
		type: attr,
		attribute: attributeSpecialFields.specialFeatures,
	},
	...Object.values(skillFields).map((field) => ({
		type: skill,
		attribute: field,
	})),
].map(({ type, attribute }) => ({
	title: `APP_${type}_${attribute.value}`.toUpperCase(),
	fields: attribute.selectors.map((selector) => ({
		...selector,
		fieldName: attribute.value,
	})),
}))

const filterSections: FilterSection[] = [
	{
		title: 'APP_BODY_FILTER_SECTION',
		fields: [
			...attributeBasicFields.hair.selectors.map((selector) => ({
				...selector,
				fieldName: attributeBasicFields.hair.value,
			})),
			...attributeBasicFields.eyes.selectors.map((selector) => ({
				...selector,
				fieldName: attributeBasicFields.eyes.value,
			})),
			...attributeBasicFields.body.selectors
				.filter((selector) => selector.type !== 'range')
				.map((selector) => ({
					...selector,
					fieldName: attributeBasicFields.body.value,
				})),
		],
	},
	...values,
]

const MediaTags = (): JSX.Element => {
	const [generalType] = usePortfolioStore(
		useShallow((state) => [state.generalModalType]),
	)
	const [searchFields, setSearchFields] = useState('')

	if (
		![PORTFOLIO_TYPES.PICTURE, PORTFOLIO_TYPES.SHOWREEL].includes(generalType)
	)
		return <></>

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				paddingTop: '20px',
			}}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<InputLabel label="APP_MEDIA_TAGS_LABEL" />
				<Input
					value={searchFields}
					onChange={(e) => setSearchFields(e.target.value)}
					placeholder={t('APP_SEARCH_FIELD_PLACEHOLDER')}
					variant="default"
					endAdornment={<SearchIcon color="var(--mono300)" />}
				/>
			</div>
			{filterSections.map((section) => (
				<Section
					key={section.title}
					section={{ ...section, hideByDefault: true }}
					searchFields={searchFields}
				/>
			))}
		</div>
	)
}

export default MediaTags
