import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import { t } from 'helpers'
import { useProductionStore } from 'store'
import s from './index.module.scss'
import styled from 'styled-components'
import { roleInfo } from 'helpers/formFields'
import { Content } from 'types/app'

const Color = styled('div')<{ color?: string; activeColor?: string }>(
	(props: { color?: string; activeColor?: string }) => ({
		transition: 'var(--transition)',
		padding: '11px',
		borderRadius: '50%',
		backgroundColor:
			props.activeColor === props.color
				? `color-mix(in srgb, ${props.color} 20%, transparent)`
				: 'transparent',
		'&:hover': {
			backgroundColor: `color-mix(in srgb, ${props.color} 20%, transparent)`,
		},
	}),
)

interface Props {
	onChange: (color: string, id: string) => void
	type: 'prod' | 'role'
}

const Colors = ({ type, onChange }: Props): JSX.Element => {
	const color = useProductionStore(
		useShallow((state) =>
			type === 'prod'
				? state.production?.color
				: state.roles?.[state.currentRole]?.[
						roleInfo.color.name as keyof Content
				  ],
		),
	)
	const { productionId, productionRoleId } = useParams()

	const colors = [
		{
			color: '#F33EE2',
			name: 'Enigma',
		},
		{
			color: '#30A62C',
			name: 'Verdance',
		},
		{
			color: '#6C18BF',
			name: 'Royalty',
		},
		{
			color: '#177AB6',
			name: 'Azure',
		},
		{
			color: '#F08C1B',
			name: 'Ember',
		},
		{
			color: '#F91C12',
			name: 'Blaze',
		},
		{
			color: '#9D5C63',
			name: 'Merlot',
		},
		{
			color: '#FFDD44',
			name: 'Solaris',
		},
		{
			color: '#8F9BD1',
			name: 'Mystveil',
		},
		{
			color: '#C7A3D3',
			name: 'Glimmer',
		},
		{
			color: '#584B9D',
			name: 'Duskshade',
		},
		{
			color: '#DA4B30',
			name: 'Inferno',
		},
		{
			color: '#D9D4A4',
			name: 'Moonbloom',
		},
		{
			color: '#3EA49F',
			name: 'Sylphsong',
		},
		{
			color: '#F1B0E1',
			name: 'Stardust',
		},
		{
			color: '#5B3480',
			name: 'Nightbloom',
		},
	]

	return (
		<div className={s.container}>
			<span className="inputLabel caption-m">
				{t('APP_PRODUCTION_COLOR_LABEL')}
			</span>
			<div className={s.wrapper}>
				{colors.map((item) => (
					<div
						key={item.color}
						className={s.color}
						onClick={() => {
							if (productionRoleId && type === 'role')
								onChange(item.color, productionRoleId)
							else if (productionId && type === 'prod')
								onChange(item.color, productionId)
						}}>
						<Color color={item.color} activeColor={color as string}>
							<div
								style={{
									background: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${item.color} 70%, transparent) 0%, color-mix(in srgb, ${item.color} 0%, transparent) 100%)`,
								}}
							/>
						</Color>
						<span
							className={color === item.color ? 'body2-b' : 'body2-r'}
							style={{
								color:
									color === item.color ? 'var(--mono100)' : 'var(--mono300)',
							}}>
							{item.name}
						</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default Colors
