import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { updateUser } from 'api/user'
import { addRelation, deleteRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import useUser from 'hooks/api/useUser'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { t } from 'helpers'
import { useModalStore, useNetworkStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { contactFields } from 'pages/Portfolio/fields'
import { notif } from 'helpers/notif'

const AcceptInviteToPoolSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const [inviteUserId, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.acceptInviteToPoolSidebar,
			state.setAcceptInviteToPoolSidebar,
		]),
	)

	const { user: agency } = useUser(inviteUserId)

	const acceptInvite = async (): Promise<void> => {
		if (!agency?.CustomerPoolDefault) {
			notif('info', t('APP_NO_POOL_ID'))

			return
		}
		try {
			setLoading(true)
			const response = await addRelation({
				relation: RELATIONS.ORGANIZATION,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: agency?.CustomerPoolDefault || '',
			})
			if (response) {
				await updateUser(getUser()?.['Custom:User:Id'] || '', [
					{
						Name: 'Customer:Agency:Status',
						Value: 'Approved',
					},
				])
				await deleteRelation(
					getUser()?.['Custom:User:Id'] || '',
					'Customer',
					RELATIONS.GROUP_INVITE,
					inviteUserId,
				)
			}
			EventBus.$emit('refreshInvitesToPool')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar('')
		}
	}

	const rejectInvite = async (): Promise<void> => {
		try {
			setLoading(true)
			await addRelation({
				relation: RELATIONS.GROUP_INVITE,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: inviteUserId,
				status: 'Rejected',
			})
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Agency:Status',
					Value: '',
				},
				{
					Name: contactFields.agency.name,
					Value: '',
				},
			])
			EventBus.$emit('refreshInvitesToPool')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar('')
		}
	}

	return (
		<Sidebar
			open={!!inviteUserId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: 'APP_INVITE_TO_POOL_SIDEBAR_TITLE',
				description: 'APP_INVITE_TO_POOL_SIDEBAR_DESCRIPTION',
			}}
			loading={loading}
			buttons={[
				{
					children: t('APP_REJECT'),
					onClick: rejectInvite,
				},
				{
					children: t('APP_ACCEPT'),
					onClick: () => {
						const { user } = useUserStore.getState()
						const { setModal } = useModalStore.getState()
						setModal({
							show: true,
							children: (
								<div
									className="card"
									style={{
										maxWidth: '500px',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										gap: '20px',
									}}>
									<>
										<h4 className="h4-m">
											{t('APP_ACCEPT_AGENCY_DATA_POLICY')}
										</h4>
										<span>
											{t('APP_AGENCY_DATA_POLICY')?.replace(
												'[Your Name]',
												user?.ContentTitle || '',
											)}
										</span>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<Button
												style={{ width: '100%' }}
												variant="themed"
												onClick={() => {
													acceptInvite()
													setModal(null)
												}}>
												{t('APP_AUTHORIZE')}
											</Button>
											<Button
												style={{ width: '100%' }}
												onClick={() => {
													setModal(null)
												}}>
												{t('APP_IGNORE')}
											</Button>
										</div>
									</>
								</div>
							),
						})
					},
					style: {
						marginTop: '0',
					},
				},
			]}>
			<>
				<div
					style={{
						width: '150px',
						height: '150px',
						borderRadius: '50%',
						overflow: 'hidden',
					}}>
					<img
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						src={agency?.CustomerImageSquareUrl}
						alt={agency?.ContentTitle}
					/>
				</div>
				<h2>{agency?.ContentTitle}</h2>
				<span className="body1-b">{agency?.CustomUserEmail}</span>
				<span className="body1-b">{agency?.CustomGeoLocation}</span>
			</>
		</Sidebar>
	)
}

export default AcceptInviteToPoolSidebar
