import cn from 'classnames'
import Images from './images'
import Card from '../Card'
import MobileCards from '../MobileCards'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	type: string
}

const ItemsWrapper = ({ type }: Props): JSX.Element => {
	const key = type?.toUpperCase()
	const title = `APP_${key}_CAROUSEL_TITLE`
	const description = `APP_${key}_CAROUSEL_DESCRIPTION`

	const cards = Array.from({ length: 5 }).map((_, index) => ({
		title: `APP_${key}_CARD${index + 1}_TITLE`,
		desc: `APP_${key}_CARD${index + 1}_DESCRIPTION`,
		image: (Images as { [key: string]: string })[`${type}${index + 1}`],
		main:
			type === 'castingdirector' && index === 2
				? true
				: type === 'actors' && index === 0
				? true
				: type === 'agencies' && index === 4
				? true
				: false,
		imageStyle: {
			filter:
				type === 'castingdirector' && [3].includes(index)
					? 'opacity(0.15)'
					: '',
			height:
				type === 'castingdirector' && [0, 3, 4].includes(index)
					? '100%'
					: type === 'actors' && [4, 3, 5].includes(index)
					? '100%'
					: type === 'agencies' && [1].includes(index)
					? '100%'
					: '',
		},
	}))

	return (
		<div className={s.itemWrapper}>
			<div className={s.titles}>
				<h3 style={{ fontWeight: '500' }}>{t(title)}</h3>
				<h4 style={{ color: 'var(--mono300)', fontWeight: '500' }}>
					{t(description)}
				</h4>
			</div>
			<MobileCards cards={cards} />
			<div className={cn(s.itemsLayout, s['itemsLayout-' + type])}>
				{cards?.map((card) => (
					<Card key={card.title} card={card} type={type} />
				))}
			</div>
		</div>
	)
}

export default ItemsWrapper
