import castingdirector1 from 'assets/images/home-cd-1.png'
import castingdirector2 from 'assets/images/home-cd-3.png'
import castingdirector3 from 'assets/images/home-cd-2.png'
import castingdirector4 from 'assets/images/home-cd-4.png'
import castingdirector5 from 'assets/images/home-cd-5.png'
import agencies1 from 'assets/images/home-ag-1.png'
import agencies2 from 'assets/images/home-ag-2.png'
import agencies3 from 'assets/images/home-ag-3.png'
import agencies4 from 'assets/images/home-ag-4.png'
import agencies5 from 'assets/images/home-ag-5.png'
import actors1 from 'assets/images/home-ac-1.png'
import actors2 from 'assets/images/home-ac-2.png'
import actors3 from 'assets/images/home-ac-3.png'
import actors4 from 'assets/images/home-ac-4.png'
import actors5 from 'assets/images/home-ac-5.png'

export default {
	castingdirector1,
	castingdirector2,
	castingdirector3,
	castingdirector4,
	castingdirector5,
	agencies1,
	agencies2,
	agencies3,
	agencies4,
	agencies5,
	actors1,
	actors2,
	actors3,
	actors4,
	actors5,
}
