import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import Prev from './components/Prev'
import Repeat from './components/Repeat'
import PlayPause from './components/PlayPause'
import Next from './components/Next'
import Speed from './components/Speed'
import Mute from './components/Mute'
import Visibility from './components/Visibility'
import Volume from './components/Volume'
import TimeTrack from './components/TimeTrack'
import Fullscreen from './components/Fullscreen'
import Caption from './components/Caption'
import Time from './components/Time'
import CurrentRole from './components/CurrentRole'
import useMarkers from 'hooks/api/useMarkers'
import { EventBus } from 'helpers/EventBus'
import {
	useAppStore,
	useCastingStore,
	usePlayerStore,
	useUserStore,
} from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import { PATHS } from 'types/enums'
import { Subtitle } from 'types/content'
import s from './index.module.scss'

interface Props {
	shakaInstance: shaka.Player | null
	active: boolean
	contentId?: string
	assetId?: string
	subtitles: Subtitle[] | null
}

const Controls = ({
	shakaInstance,
	active,
	contentId,
	subtitles,
}: Props): JSX.Element => {
	const castingPage = window.location.pathname
		?.split('/')
		.some((path) => path === PATHS.CASTING)
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const [showTutorial] = useCastingStore(
		useShallow((state) => [state.showTutorial]),
	)
	const { data, refresh } = useMarkers(
		active && contentId ? contentId : undefined,
	)
	const [videoElement, setVideoElement] = useState(
		shakaInstance?.getMediaElement(),
	)
	const [playing, visible] = usePlayerStore(
		useShallow((state) => [state.playing, state.visible]),
	)
	const [popupVisible] = useAppStore(
		useShallow((state) => [state.popupVisible]),
	)

	useEffect(() => {
		if (shakaInstance && !videoElement) {
			setTimeout(() => {
				setVideoElement(shakaInstance?.getMediaElement())
			}, 500)
		}
	}, [shakaInstance, videoElement])

	useEffect(() => {
		if (videoElement)
			videoElement.addEventListener('ended', () => {
				const { setValue } = usePlayerStore.getState()
				setValue({ playing: false })
			})
	}, [videoElement])

	useEffect(() => {
		EventBus.$on('refreshMarkers', () => {
			if (active) refresh()
		})

		return () => {
			EventBus.$off('refreshMarkers')
		}
	}, [refresh, active])

	useEffect(() => {
		if (videoElement && active) {
			if ((castingPage && !showTutorial) || !castingPage) {
				const { setValue } = usePlayerStore.getState()
				setValue({ playing: true })
				videoElement.play()
			}
		}
	}, [active, videoElement, showTutorial, castingPage])

	useEffect(() => {
		if (videoElement && !active && playing) {
			videoElement.pause()
			const { setValue } = usePlayerStore.getState()
			setValue({ playing: false })
		}
	}, [active, videoElement, playing])

	useEffect(() => {
		if (videoElement && !active && playing) {
			videoElement.pause()
			const { setValue } = usePlayerStore.getState()
			setValue({ playing: false })
		}
	}, [active, videoElement, playing])

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent): void => {
			const { code } = event
			const activeElement = document.activeElement

			if (
				activeElement?.tagName === 'INPUT' ||
				activeElement?.tagName === 'TEXTAREA'
			) {
				return
			}

			switch (code) {
				case 'KeyM':
					{
						const { setValue, muted } = usePlayerStore.getState()
						if (videoElement) videoElement.muted = muted
						setValue({ muted: muted })
					}
					break
				case 'Space':
					{
						const { setValue, playing } = usePlayerStore.getState()
						if (videoElement) {
							if (playing) videoElement.pause()
							else videoElement.play()
						}
						setValue({ playing: !playing })
					}
					break
				default:
					break
			}
		}
		document.addEventListener('keydown', keyDownHandler)

		return () => {
			document.removeEventListener('keydown', keyDownHandler)
		}
	}, [videoElement])

	useEffect(() => {
		return () => {
			if (videoElement) {
				videoElement.pause()
				const { setValue } = usePlayerStore.getState()
				setValue({ playing: false })
				usePlayerStore.getState().resetControls()
			}
		}
	}, [videoElement])

	return (
		<>
			{visible ? null : <div className={s.hideContent} />}
			<div
				className={classNames(s.container, 'castingToolWrapper', {
					['show']: popupVisible,
				})}
				data-role="controls">
				{active && videoElement && shakaInstance ? (
					<TimeTrack videoElement={videoElement} markers={data} />
				) : null}
				<div className={classNames(s.wrapper, 'grid3column')}>
					<div className={s.group}>
						{director && castingPage ? <CurrentRole /> : null}
					</div>

					<div className={s.group} style={{ justifyContent: 'center' }}>
						<Repeat videoElement={videoElement} />
						<Prev videoElement={videoElement} />
						<PlayPause videoElement={videoElement} />
						<Next videoElement={videoElement} />
						<Speed videoElement={videoElement} />
					</div>

					<div className={s.group} style={{ justifyContent: 'flex-end' }}>
						<Time videoElement={videoElement} />
						<Volume videoElement={videoElement} />
						<Mute videoElement={videoElement} />
						<Visibility />
						{subtitles ? (
							<Caption videoElement={videoElement} subtitles={subtitles} />
						) : null}
						<Fullscreen videoElement={videoElement} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Controls
