import { Helmet } from 'react-helmet-async'

interface Props {
	title: string
	children?: JSX.Element[]
}

const Meta = ({ title, children }: Props): JSX.Element => (
	<Helmet>
		<title>{title}</title>
		{children}
	</Helmet>
)

export default Meta
