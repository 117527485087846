export const DroidIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18.5078 18.6484C18.5352 18.7031 18.5625 18.7578 18.5625 18.8125C18.5625 19.168 18.2344 19.25 18.125 19.25H15.9375C15.6914 19.25 15.5 19.0586 15.5 18.8125V15.75L13.9961 17.2539C13.832 17.418 13.6133 17.5 13.3672 17.5H10.6055C10.3594 17.5 10.1406 17.418 9.94922 17.2539L8.47266 15.75L8.5 18.8125C8.5 19.0586 8.28125 19.25 8.0625 19.25H5.84766C5.73828 19.25 5.4375 19.168 5.4375 18.8125C5.4375 18.7578 5.4375 18.7031 5.4375 18.6484L6.28516 16.7891V11.8125C6.28516 11.1016 6.85938 10.5 7.59766 10.5H16.375C17.0859 10.5 17.6875 11.1016 17.6875 11.8125V16.7891L18.5078 18.6484ZM13.3125 14.875C13.5312 14.875 13.75 14.6836 13.75 14.4375C13.75 14.2188 13.5312 14 13.3125 14H10.6875C10.4414 14 10.25 14.2188 10.25 14.4375C10.25 14.6562 10.4414 14.875 10.6875 14.875H13.3125ZM13.3125 13.125C13.5312 13.125 13.75 12.9336 13.75 12.6875C13.75 12.4688 13.5312 12.25 13.3125 12.25H10.6875C10.4414 12.25 10.25 12.4688 10.25 12.6875C10.25 12.9336 10.4414 13.125 10.6875 13.125H13.3125ZM15.5 8.75H15.4727V9.625H8.47266V8.91406C8.47266 7.54688 9.48438 5.25 12 5.25C13.9141 5.25 15.5 6.83594 15.5 8.75ZM11.125 8.75C11.5898 8.75 12 8.36719 12 7.875C12 7.41016 11.5898 7 11.125 7C10.6328 7 10.25 7.41016 10.25 7.875C10.25 8.36719 10.6328 8.75 11.125 8.75ZM13.3125 8.75C13.5312 8.75 13.75 8.55859 13.75 8.3125C13.75 8.09375 13.5312 7.875 13.3125 7.875C13.0664 7.875 12.875 8.09375 12.875 8.3125C12.875 8.55859 13.0664 8.75 13.3125 8.75Z"
			fill={color}
		/>
	</svg>
)
