import { useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Recent from './components/Recent'

const tabs = [
	{
		label: 'APP_RECENT_LIST_TITLE',
		value: 0,
	},
]

const Carousels = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)

	return (
		<>
			<SidebarTabs
				active={activeTab}
				options={tabs}
				onChange={(v) => {
					setActiveTab(v as number)
					swiperRef.current?.slideTo(v as number)
				}}
				type="fit"
			/>
			<Swiper
				allowTouchMove={false}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				style={{ width: '100%' }}>
				<SwiperSlide>
					<Recent />
				</SwiperSlide>
			</Swiper>
		</>
	)
}

export default Carousels
