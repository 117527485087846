import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import ItemsWrapper from './components/ItemsWrapper'
import { t } from 'helpers'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const tabs = [
	{
		label: 'APP_ACTORS_CAROUSEL_TAB',
		value: 0,
		key: HOME_SECTIONS.CAROUSEL_ACTORS,
	},
	{
		label: 'APP_CASTINGDIRECTORS_CAROUSEL_TAB',
		value: 1,
		key: HOME_SECTIONS.CAROUSEL_CASTINGDIRECTOR,
	},
	{
		label: 'APP_AGENCIES_CAROUSEL_TAB',
		value: 2,
		key: HOME_SECTIONS.CAROUSEL_AGENCIES,
	},
]

const Carousel = (): JSX.Element => {
	const { hash } = useLocation()
	const swiperRef = useRef<SwiperClass>()
	const [tab, setTab] = useState(0)

	useEffect(() => {
		const carousel = HOME_SECTIONS?.CAROUSEL_ACTORS?.split('-')?.[0]
		if (hash.includes(carousel)) {
			const tab = tabs?.find((t) => t.key === hash?.slice(1))
			if (tab) {
				setTab(tab.value)
				swiperRef.current?.slideTo(tab.value)
			}
		}
	}, [hash])

	return (
		<section
			className={s.container}
			id={HOME_SECTIONS.CAROUSEL_ACTORS?.split('-')?.[0]}>
			<h2 style={{ textAlign: 'center', fontWeight: '500' }}>
				{t('APP_HOME_CAROUSEL_TITLE')}
			</h2>
			<SidebarTabs
				active={tab}
				onChange={(v) => {
					setTab(v as number)
					swiperRef.current?.slideTo(v as number)
				}}
				options={tabs}
				style={{
					overflow: 'auto',
					maxWidth: '100%',
				}}
			/>
			<Swiper
				spaceBetween={10}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				allowTouchMove={false}
				style={{ width: '100%' }}>
				{['actors', 'castingdirector', 'agencies'].map((type) => (
					<SwiperSlide key={type}>
						<ItemsWrapper type={type} />
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	)
}

export default Carousel
