import { useShallow } from 'zustand/react/shallow'
import MediaUploadCard from './components/MediaUploadCard'
import { imageAcceptFormats } from 'helpers/constants'
import { usePortfolioStore } from 'store'
import { PORTFOLIO_TYPES } from 'types/enums'

const Media = (): JSX.Element => {
	const [generalType, type] = usePortfolioStore(
		useShallow((state) => [state.generalModalType, state.modalType]),
	)

	const uploadFormat = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	return !uploadFormat ? (
		<></>
	) : (
		<MediaUploadCard
			fileType={generalType === PORTFOLIO_TYPES.PICTURE ? 'Image' : 'Video'}
			label="APP_PROFILEPICTURE_LABEL"
			form={
				generalType === PORTFOLIO_TYPES.PICTURE ? 'portrait-v' : 'rectangle-h'
			}
			name={type}
			formatsText={
				generalType === PORTFOLIO_TYPES.PICTURE
					? 'APP_PHOTO_FORMATS'
					: 'APP_VIDEO_FORMATS'
			}
			qualityText={
				generalType === PORTFOLIO_TYPES.PICTURE
					? 'APP_PICTURES_QUALITY'
					: 'APP_SHOWREELS_QUALITY'
			}
			fileInputProps={{
				accept:
					generalType === PORTFOLIO_TYPES.PICTURE
						? imageAcceptFormats
						: 'video/*',
				id: type,
			}}
		/>
	)
}

export default Media
