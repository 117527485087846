import { useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import Candidates from '../Candidates'
import Roles from '../Roles'
import { useOutsideClick } from 'hooks'
import { useCastingStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'
import style from '../../index.module.scss'

const CastingRoles = (): JSX.Element => {
	const ref = useRef(null)
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const [open, setOpen] = useCastingStore(
		useShallow((state) => [state.openCastingRoles, state.setOpenCastingRoles]),
	)

	useOutsideClick(ref, () => setOpen(false))

	const tablet = window.navigator?.maxTouchPoints > 0

	const events = {
		onClick: () => setOpen(true),
		onMouseOver: () => setOpen(true),
	}

	return (
		<>
			{director ? (
				<>
					<div
						className={classNames(style.verticalButton, {
							[style.hideVerticalButton]: open,
						})}
						style={{
							left: '-12px',
						}}
						{...(tablet ? {} : events)}
					/>
					{tablet ? (
						<div
							className={classNames(style.verticalButton, style.extendLineSize)}
							{...events}
							style={{ left: '-20px' }}
						/>
					) : null}
					<div
						ref={ref}
						style={
							open
								? {
										zIndex: 1,
								  }
								: {}
						}
						className={classNames('castingToolWrapper', s.container, {
							['show']: open,
						})}>
						<div className="grid2column" style={{ gap: '0', height: '100%' }}>
							<Roles />
							<Candidates />
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

export default CastingRoles
