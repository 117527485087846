import { InputHTMLAttributes, useEffect, useState } from 'react'
import cn from 'classnames'
import FileInputLayout from '../FileInputLayout'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	form?: FileFormVariant
	label?: string
	error?: string
	file?: File | null | undefined | string
	fileType: 'Image' | 'Video'
	update?: boolean
}

const FileInput = ({
	form = 'circle',
	error,
	file,
	label,
	fileType,
	update,
	...props
}: Props): JSX.Element => {
	const [src, setSrc] = useState({ url: '', local: true })

	useEffect(() => {
		if (file) {
			setSrc({
				url: file && file instanceof File ? URL.createObjectURL(file) : file,
				local: true,
			})
		}
	}, [file])

	return (
		<div className={cn(s.container, { [s.filled]: props.value }, s[form])}>
			<FileInputLayout
				src={src}
				setSrc={setSrc}
				file={file}
				name={props.name || props.id || ''}
				label={label}
				fileType={fileType}
				update={update}
			/>
			<input
				{...props}
				className={cn(s.fileInput, {
					[s.error]: error,
				})}
				placeholder={props.placeholder || props.name}
				type="file"
				style={src?.url && !update ? { zIndex: -1 } : {}}
			/>
		</div>
	)
}

export default FileInput
