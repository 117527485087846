import { CircleFillIcon } from 'assets/icons'
import { SummaryData } from '../../Summary'
import { getProfileReadiness } from 'helpers'
import s from './index.module.scss'

const Overall = ({ percent, filled, max }: SummaryData): JSX.Element => {
	const color = getProfileReadiness(+percent).color

	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<CircleFillIcon
					width="240"
					height="240"
					strokeFilledWidth="16"
					strokeFilledPercents={percent}
					strokeDefaultWidth="4"
					colorFilled={color}
					colorDefault="var(--elev500)"
				/>
				<div className={s.info}>
					<h2>{percent}%</h2>
					<h3 style={{ color: 'var(--mono300)' }}>{`${filled} / ${max}`}</h3>
				</div>
			</div>
		</div>
	)
}

export default Overall
