import { createWithEqualityFn } from 'zustand/traditional'
import { t } from 'helpers'
import { CastingStore } from 'types/store/castingStore'

const useStore = createWithEqualityFn<CastingStore>(
	(set) => ({
		sceneUploadContent: null,
		uploadedSceneContent: undefined,
		setSceneUploadContent: (content, uploaded) =>
			set({ sceneUploadContent: content, uploadedSceneContent: uploaded }),
		customCastingTabs: null,
		setCustomCastingTabs: (tabs) => set({ customCastingTabs: tabs }),
		openCastingChat: false,
		setOpenCastingChat: (open) => set({ openCastingChat: open }),
		openCastingRoles: false,
		setOpenCastingRoles: (open) => set({ openCastingRoles: open }),
		openReviewNdaSidebar: null,
		setOpenReviewNdaSidebar: (open) => set({ openReviewNdaSidebar: open }),
		chatContentId: '',
		setChatContentId: (id) => set({ chatContentId: id }),
		uploadedSceneIds: [],
		setUploadedSceneIds: (id, duration) =>
			set((state) => ({
				uploadedSceneIds: [...(state.uploadedSceneIds || []), { id, duration }],
			})),
		openCastingFeedbackSidebar: false,
		setOpenCastingFeedbackSidebar: (open) =>
			set({ openCastingFeedbackSidebar: open }),
		openSubmitCastingSidebar: false,
		setOpenSubmitCastingSidebar: (open) =>
			set({ openSubmitCastingSidebar: open }),
		openRejectNdaSidebar: false,
		setOpenRejectNdaSidebar: (open) => set({ openRejectNdaSidebar: open }),
		openRequestSidebar: null,
		setOpenRequestSidebar: (open) => set({ openRequestSidebar: open }),
		castingLoading: false,
		setCastingLoading: (loading) => set({ castingLoading: loading }),
		assignCastingRelation: null,
		setAssignCastingRelation: (relation) =>
			set({
				assignCastingRelation: relation,
			}),
		openCloseCastingSidebar: false,
		setOpenCloseCastingSidebar: (open) =>
			set({ openCloseCastingSidebar: open }),
		castingOverviewRedirectionLink: '',
		setCastingOverviewRedirectionLink: (link) =>
			set({ castingOverviewRedirectionLink: link }),
		tagsCount: {
			expiring: 0,
			castingClosed: 0,
			castingOpened: 0,
		},
		setTagsCount: (count) =>
			set((state) => ({ tagsCount: { ...state.tagsCount, ...count } })),
		contentData: {
			contentId: '',
			assetId: '',
		},
		setContentData: (data) =>
			set((state) => ({
				contentData: {
					...state.contentData,
					...data,
				},
			})),
		showTutorial: false,
		setShowTutorial: (show) => set({ showTutorial: show }),
		messageToChat: null,
		setMessageToChat: (message) => set({ messageToChat: message }),
		castingSubmitMessage: '',
		setCastingSubmitMessage: (message) =>
			set({ castingSubmitMessage: message }),
	}),
	Object.is,
)

export default useStore
