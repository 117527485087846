import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Logo from './Logo'
import StepTabs from './Tabs/StepTabs'
import Menu from './Menu'
import CastingOverviewTabs from './Tabs/CastingOverviewTabs'
import DashboardTabs from './Tabs/DashboardTabs'
import HomeTabs from './Tabs/HomeTabs'
import { useCastingStore } from 'store'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const Header = (): JSX.Element => {
	const { pathname } = useLocation()
	const showOnlyLogo = pathname.includes(PATHS.JOIN)
	const collapse =
		pathname?.includes(PATHS.LOGIN) || pathname?.includes(PATHS.ACCEPT)
	const home = pathname === '/' || pathname.includes(PATHS.HOME)
	const sticky =
		pathname === '/' ||
		pathname.includes(PATHS.HOME) ||
		pathname.includes(PATHS.DASHBOARD)

	return (
		<>
			<header
				id="main-header"
				className={cn(s.header, {
					[s.hide]: showOnlyLogo,
					[s.logoAlign]: collapse,
					[s.sticky]: sticky,
					[s.home]: home,
				})}>
				<Logo />
				<Tabs />
				<Menu />
			</header>
		</>
	)
}

export default Header

const Tabs = (): JSX.Element => {
	const { pathname } = useLocation()

	const customCastingTabs = useCastingStore(
		useShallow((state) => state.customCastingTabs),
	)

	const home = pathname === '/' || pathname.includes(PATHS.HOME)
	const dashboard = pathname.includes(PATHS.DASHBOARD)

	const tabComponent = useMemo(() => {
		if (home) return <HomeTabs classname={s.homeTabs} />
		else if (dashboard) return <DashboardTabs />
		else if (customCastingTabs) return <CastingOverviewTabs />
		else return <StepTabs />
	}, [customCastingTabs, dashboard, home])

	return <>{tabComponent}</>
}
