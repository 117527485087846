import { useCallback, useEffect, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { updateUser } from 'api/user'
import Card from 'components/Wizard/Card'
import Overall from './components/Overall'
import Line from './components/Line'
import Info from './components/Info'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { filterDuplicates } from 'helpers/propertiesParser'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { usePortfolioStore, useUserStore } from 'store'
import { PROFILE_READINESS, RELATIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	basicFields,
	biographyFields,
	contactFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'
import { actorPortfolioSteps } from 'pages/Portfolio/Portfolio'
import s from './index.module.scss'

export interface SummaryData {
	label: string
	filled: number
	max: number
	percent: string
	hashKey?: string
}

const mediaItemsCount = 16

const Summary = (): JSX.Element => {
	const [cardValues] = usePortfolioStore(
		useShallow((state) => [state.cardValues]),
	)

	const { data } = useRelationsContent([
		RELATIONS.IMAGE,
		RELATIONS.BEST_PERFORMANCES,
	])

	const images = useMemo(
		() =>
			data
				? filterDuplicates(
						data[RELATIONS.IMAGE]?.filter(
							(c) => c?.relationIndex !== undefined,
						),
						'relationIndex',
				  )
				: [],
		[data],
	)
	const videos = useMemo(
		() =>
			data
				? filterDuplicates(
						data[RELATIONS.BEST_PERFORMANCES]?.filter(
							(c) => c?.relationIndex !== undefined,
						),
						'relationIndex',
				  )
				: [],
		[data],
	)

	const basics = useMemo(
		() =>
			[
				{
					value: 'basic',
					selectors: Object.values(basicFields),
				},
				{
					value: 'contact',
					selectors: Object.values(contactFields),
				},
				{
					value: 'personal',
					selectors: Object.values(personalFields),
				},
				{
					value: 'biography',
					selectors: Object.values(biographyFields),
				},
			]
				.map((field) => {
					const values = cardValues?.[field.value]

					return values
				})
				?.reduce(
					(prev, next) => ({
						...prev,
						...next,
					}),
					{},
				),
		[cardValues],
	)

	const attributes = useMemo(
		() =>
			Object.values(attributeBasicFields)
				.concat(Object.values(attributeSpecialFields))
				.map((field) => {
					const values = cardValues?.[field.value]

					if (values)
						return Object.values(values)?.filter((v) =>
							Array.isArray(v) ? !!v.length : !!v,
						)?.length >= (field?.selectors?.length || 0)
							? field
							: null
				}),
		[cardValues],
	)

	const skills = useMemo(
		() =>
			Object.values(skillFields).map((field) => {
				const values = cardValues?.[field.value]

				if (values)
					return Object.values(values).filter(
						(v) =>
							Array.isArray(v) &&
							!Array.isArray(v?.[0]) &&
							Object.values(v?.[0] || {})?.filter((v) => !!v)?.length >=
								(field.selectors?.length || 0),
					)?.length
						? field
						: null
			}),
		[cardValues],
	)

	const getValues = useCallback(() => {
		const basicsFilled = Object.values(basics)?.filter((v) => !!v)?.length
		const basicsMax = Object.values(basics)?.length
		const attributesFilled = attributes?.filter((v) => !!v)?.length
		const attributesMax = attributes?.length
		const skillsFilled = skills?.filter((v) => !!v)?.length
		const skillsMax = skills?.length

		const overallMax =
			mediaItemsCount * 2 + skillsMax + attributesMax + basicsMax
		const overallCurrent =
			images?.length +
			videos?.length +
			skillsFilled +
			attributesFilled +
			basicsFilled

		return [
			{
				label: 'APP_PROFILE_BASICS',
				filled: basicsFilled,
				max: basicsMax,
				percent: ((basicsFilled * 100) / basicsMax).toFixed(),
				hashKey: actorPortfolioSteps.basic.key + '-0',
			},
			{
				label: 'APP_PROFILE_MEDIA',
				filled: images?.length,
				max: mediaItemsCount,
				percent: ((images?.length * 100) / mediaItemsCount).toFixed(),
				hashKey: actorPortfolioSteps.pictures.key + '-0',
			},
			{
				label: 'APP_PROFILE_SHOWREELS',
				filled: videos?.length,
				max: mediaItemsCount,
				percent: ((videos?.length * 100) / mediaItemsCount).toFixed(),
				hashKey: actorPortfolioSteps.showreels.key + '-0',
			},
			{
				label: 'APP_PROFILE_ATTRIBUTES',
				filled: attributesFilled,
				max: attributesMax,
				percent: ((attributesFilled * 100) / attributesMax).toFixed(),
				hashKey: actorPortfolioSteps.attributes.key + '-0',
			},
			{
				label: 'APP_PROFILE_SKILLS',
				filled: skillsFilled,
				max: skillsMax,
				percent: ((skillsFilled * 100) / skillsMax).toFixed(),
				hashKey: actorPortfolioSteps.attributes.key + '-1',
			},
			{
				label: 'APP_PROFILE_OVERALL_LABEL',
				filled: overallCurrent,
				max: overallMax,
				percent: ((overallCurrent * 100) / overallMax).toFixed(),
			},
		]
	}, [attributes, basics, images?.length, skills, videos?.length])

	const values = useMemo(() => getValues(), [getValues])

	useEffect(() => {
		EventBus.$on('updatePortfolioOverall', () => {
			const values = getValues()
			const overallValue = values?.[values?.length - 1]?.percent
			const { user } = useUserStore.getState()

			if (user && user?.CustomerPortfolioReadiness !== overallValue) {
				updateUser(user.ContentId, [
					{
						Name: 'Customer:Portfolio:Readiness',
						Value: overallValue,
					},
				])
			}
		})

		return () => {
			EventBus.$off('updatePortfolioOverall')
		}
	}, [getValues])

	const profileStatus =
		+values[5]?.percent >= PROFILE_READINESS.HIGH
			? 'APP_EXPLANATION_90'
			: +values[5]?.percent >= PROFILE_READINESS.MEDIUM
			? 'APP_EXPLANATION_6090'
			: 'APP_EXPLANATION_60'

	return (
		<Card step="summary" noHint>
			<>
				<div className="grid4column" style={{ gap: '40px' }}>
					<div className={s.linesWrapper} style={{ alignItems: 'center' }}>
						<span style={{ paddingBottom: '32px' }}>
							{t('APP_SUMMARY_OVERALL_TITLE')}
						</span>
						<Overall {...values[5]} />
					</div>
					<div className={s.linesWrapper} style={{ gridColumn: 'span 3' }}>
						<span style={{ paddingLeft: '16px', paddingBottom: '32px' }}>
							{t('APP_SUMMARY_DETAILS_TITLE')}
						</span>
						{values?.slice(0, -1)?.map((value) => (
							<Line {...value} key={value.label} />
						))}
					</div>
				</div>

				<Info profileStatusLabel={profileStatus} />
			</>
		</Card>
	)
}

export default Summary
