export const ReturnIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M5.71875 10.4453L10.5312 6.31641C10.9414 5.96094 11.625 6.26172 11.625 6.80859V9.02344C16 9.02344 19.5 9.92578 19.5 14.082C19.5 15.75 18.4062 17.418 17.2031 18.293C16.8477 18.5664 16.3008 18.2109 16.4375 17.7734C17.668 13.8086 15.8633 12.7695 11.625 12.6875V15.0938C11.625 15.668 10.9414 15.9688 10.5312 15.6133L5.71875 11.457C5.41797 11.1836 5.41797 10.7188 5.71875 10.4453Z"
			fill={color}
		/>
	</svg>
)
