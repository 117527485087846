import { useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { usePlayerStore } from 'store'
import s from './index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Volume = ({ videoElement }: Props): JSX.Element | null => {
	const rangeInput = useRef<HTMLInputElement>(null)
	const [volume, setValue] = usePlayerStore(
		useShallow((state) => [state.volume, state.setValue]),
	)

	const onVolumeChange = (v: number): void => {
		if (videoElement) videoElement.volume = v
		setValue({ volume: v })
	}

	return window?.navigator && window?.navigator?.maxTouchPoints > 0 ? null : (
		<div className={s.container}>
			<input
				min={0}
				max={10}
				ref={rangeInput}
				type="range"
				value={volume * 10}
				onChange={(e) => onVolumeChange(+e.target.value / 10)}
			/>
		</div>
	)
}

export default Volume
