import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import classNames from 'classnames'
import { CircleIcon, LikeIcon, ReturnIcon, StarIcon } from 'assets/icons'
import { addRelation } from 'api/relations'
import Tooltip from 'components/Tooltip'
import useRelation from 'hooks/api/useRelation'
import { EventBus } from 'helpers/EventBus'
import { useCastingStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import s from './index.module.scss'

const Action = styled('div')<{ color: string; active: boolean }>(
	({ active, color }) => ({
		'& > svg > path': {
			fill: active ? `${color} !important` : 'inherit',
		},
		'&:hover': {
			'& > svg > path': {
				fill: `${color} !important`,
			},
			backgroundColor: `color-mix(in srgb, ${color} 15%, transparent) !important`,
		},
	}),
)

const DecisionActions = (): JSX.Element => {
	const { roleId, userId } = useParams()
	const { relation, refresh } = useRelation(
		RELATIONS.ACCESS,
		userId || '',
		roleId || '',
	)

	const status = relation?.status

	const onActionClick = async (status: CASTING_STATUS): Promise<void> => {
		try {
			await addRelation({
				sourceId: roleId,
				targetId: userId || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: status,
			})
			const { setMessageToChat } = useCastingStore.getState()
			setMessageToChat('Marked as ' + status)
			await refresh()
		} catch (error) {
			console.log('error:', error)
		} finally {
			EventBus.$emit('refreshCastingCandidates')
		}
	}

	const openMessageSidebar = (): void => {
		const { setOpenCastingFeedbackSidebar } = useCastingStore.getState()
		setOpenCastingFeedbackSidebar(true)
	}

	const liked = status === CASTING_STATUS.LIKED
	const disliked = status === CASTING_STATUS.DISLIKED
	const fav = status === CASTING_STATUS.FAVORITE
	const neutral = status === CASTING_STATUS.NEUTRAL

	const showAnimation = (type: string): void =>
		EventBus.$emit(`casting${type}ActionAnimation`)

	return (
		<div
			className={classNames(s.container, 'castingToolWrapper')}
			data-role="decisions">
			<Tooltip title="APP_ADD_TO_DISLIKED_TOOLTIP">
				<Action
					color="var(--error)"
					active={disliked}
					className={s.control}
					onClick={() => {
						onActionClick(
							disliked ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.DISLIKED,
						)
						if (!disliked) showAnimation('Dislike')
					}}>
					<LikeIcon
						color="var(--error)"
						style={{ transform: 'rotate(180deg)' }}
					/>
				</Action>
			</Tooltip>
			<Tooltip title="APP_CASTING_FEEDBACK_TOOLTIP">
				<Action
					active={false}
					onClick={openMessageSidebar}
					color=""
					className={s.control}>
					<ReturnIcon color="var(--mono200)" />
				</Action>
			</Tooltip>
			<Tooltip
				title="APP_ADD_TO_NEUTRAL_TOOLTIP"
				wrapperStyles={{ position: 'relative' }}>
				<>
					<Action
						active={neutral}
						color="var(--mono100)"
						className={s.control}
						onClick={() =>
							neutral ? null : onActionClick(CASTING_STATUS.NEUTRAL)
						}>
						<CircleIcon />
					</Action>
					<div
						style={{
							width: '16px',
							height: '16px',
							borderRadius: '50%',
							overflow: 'hidden',
							position: 'absolute',
							backgroundColor: neutral ? 'var(--mono100)' : 'transparent',
							transition: 'var(--transition)',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: -1,
						}}
					/>
				</>
			</Tooltip>
			<Tooltip title="APP_ADD_TO_LIKED_TOOLTIP">
				<Action
					active={liked}
					color="var(--success)"
					className={s.control}
					onClick={() => {
						onActionClick(liked ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.LIKED)
						if (!liked) showAnimation('Like')
					}}>
					<LikeIcon />
				</Action>
			</Tooltip>
			<Tooltip title="APP_ADD_TO_FAVORITE_TOOLTIP">
				<Action
					active={fav}
					color="var(--warning)"
					className={s.control}
					onClick={() => {
						onActionClick(
							fav ? CASTING_STATUS.NEUTRAL : CASTING_STATUS.FAVORITE,
						)
						if (!fav) showAnimation('Fav')
					}}>
					<StarIcon />
				</Action>
			</Tooltip>
		</div>
	)
}

export default DecisionActions
