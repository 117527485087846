import { useRef } from 'react'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import Tooltip from 'components/Tooltip'
import { useOutsideClick } from 'hooks'
import { useAppStore, usePlayerStore } from 'store'
import { Subtitle } from 'types/content'
import style from './index.module.scss'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
	subtitles: Subtitle[]
}

const Caption = ({ subtitles }: Props): JSX.Element => {
	const [popupVisible, setVisible] = useAppStore(
		useShallow((state) => [state.popupVisible, state.setPopupVisible]),
	)
	const anchor = useRef<HTMLDivElement>()
	const popupRef = useRef<HTMLDivElement>(null)

	const [caption, setValue] = usePlayerStore(
		useShallow((state) => [state.caption, state.setValue]),
	)

	const onCaptionClick = (langCode: string): void => {
		setVisible(false)
		setValue({ caption: langCode })
	}

	useOutsideClick(popupRef, () => {
		setVisible(false)
	})

	return (
		<div
			style={{ position: 'relative' }}
			onMouseOver={(e) => {
				setVisible(true)
				anchor.current = e.currentTarget
			}}>
			<Tooltip title="APP_CAPTION_CONTROL">
				<div className={s.control}>
					<span
						className="body2-m"
						style={{
							color: caption === 'off' ? 'var(--mono300)' : 'var(--mono100)',
							width: '24px',
							height: '24px',
							alignContent: 'center',
							textAlign: 'center',
						}}>
						CC
					</span>
				</div>
			</Tooltip>
			{popupVisible ? (
				<BasePopup
					placement="top"
					open
					id="caption-dropdown"
					ref={popupRef}
					anchor={anchor.current}
					style={{
						zIndex: 'var(--selectPopupIndex)',
						top: '-20px',
					}}
					onMouseLeave={() => setVisible(false)}>
					<div
						className={classNames(
							style.options,
							'castingToolWrapper',
							'hideScrollBar',
						)}>
						{subtitles?.map((subtitle) => (
							<div
								className={classNames(style.option, {
									[style.selected]: caption === subtitle.langCode,
								})}
								onClick={() => onCaptionClick(subtitle.langCode)}
								key={subtitle.langCode}>
								<span>{subtitle.langCode}</span>
							</div>
						))}
					</div>
				</BasePopup>
			) : null}
		</div>
	)
}

export default Caption
