import { useShallow } from 'zustand/react/shallow'
import { ChangeEvent, useMemo } from 'react'
import Input from 'components/UI/Input'
import Select from 'components/UI/Select'
import CountrySelector from 'components/CountrySelector'
import PhoneInput from 'components/UI/PhoneInput'
import ColorSelect from 'components/UI/ColorSelect'
import RangeSlider from 'components/UI/RangeSlider'
import CheckboxSelect from 'components/UI/CheckboxSelect'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import { optionsParser, t } from 'helpers'
import { useFilterStore } from 'store'
import { attributeBasicFields, contactFields } from 'pages/Portfolio/fields'
import { AttributeSelector, SelectOption } from 'types/app'
import FieldsSelector from 'components/FieldsSelector'

interface Props {
	field: AttributeSelector
}

const FilterSelector = ({ field }: Props): JSX.Element => {
	const [value, setValue] = useFilterStore(
		useShallow((state) => [
			(state.filters[field.name] || '') as string,
			state.setFilters,
		]),
	)

	return <FieldsSelector field={field} value={value} setValue={setValue} />
}

export default FilterSelector
