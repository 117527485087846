import {
	ChangeEvent,
	CSSProperties,
	FocusEvent,
	InputHTMLAttributes,
	RefObject,
	useRef,
	useState,
} from 'react'
import { MinusIcon, PlusIcon } from 'assets/icons'
import CheckboxButton from '../CheckboxButton'
import InputLabel from '../InputLabel'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	value: number
	min: number
	max: number
	valueLabel?: string
	label?: string
	inputRef?: RefObject<HTMLInputElement>
	containerStyle?: CSSProperties
	hideLabel?: boolean
	hideControls?: boolean
	hideInput?: boolean
	checkOption?: boolean
	checkValue?: boolean
	onCheckClick?: () => void
}

const RangeInput = ({
	label = '',
	value,
	valueLabel,
	inputRef,
	containerStyle,
	hideLabel,
	hideControls,
	hideInput,
	checkOption,
	checkValue,
	onCheckClick,
	...props
}: Props): JSX.Element => {
	const rangeInput = useRef<HTMLInputElement>(null)
	const [showInput, setShowInput] = useState(checkValue)

	const ref = inputRef || rangeInput

	return (
		<div className={s.container} style={containerStyle}>
			{hideLabel ? null : (
				<div className={s.labelWrapper}>
					<div className={s.checkWrapper}>
						{checkOption ? (
							<CheckboxButton
								checked={!!checkValue}
								label={label}
								onChange={() => {
									setShowInput((prev) => !prev)
									if (onCheckClick) onCheckClick()
								}}
								disabled={props?.disabled}
							/>
						) : (
							<InputLabel label={label} status={value ? 'filled' : 'none'} />
						)}
					</div>
					{(checkOption && showInput) || !checkOption ? (
						<span className="caption-m">{valueLabel || value}</span>
					) : null}
				</div>
			)}
			<div
				className={s.wrapper}
				style={
					checkOption
						? showInput
							? { display: 'flex' }
							: { display: 'none' }
						: {}
				}>
				{hideControls ? null : (
					<div
						onClick={() => {
							if (props?.disabled) return
							if (props.onChange && value > props.min) {
								const newValue = (value - 1).toString()
								props.onChange({
									target: {
										name: props.name,
										value: newValue,
									},
								} as ChangeEvent<HTMLInputElement>)
								if (props.onBlur)
									props.onBlur({
										target: {
											name: props.name,
											value: newValue,
										},
									} as FocusEvent<HTMLInputElement, Element>)
								if (ref.current) ref.current.value = newValue
							}
						}}>
						<MinusIcon color="var(--theme-primary)" />
					</div>
				)}
				{hideInput ? null : (
					<input ref={ref} type="range" value={value} {...props} />
				)}
				{hideControls ? null : (
					<div
						onClick={() => {
							if (props?.disabled) return
							if (props.onChange && value < props.max) {
								const newValue = (value + 1).toString()
								props.onChange({
									target: {
										name: props.name,
										value: newValue,
									},
								} as ChangeEvent<HTMLInputElement>)
								if (props.onBlur)
									props.onBlur({
										target: {
											name: props.name,
											value: newValue,
										},
									} as FocusEvent<HTMLInputElement, Element>)
								if (ref.current) ref.current.value = newValue
							}
						}}>
						<PlusIcon color="var(--theme-primary)" />
					</div>
				)}
			</div>
		</div>
	)
}

export default RangeInput
