import { useEffect } from 'react'
import { updateUser } from 'api/user'
import { addRelation, deleteRelation } from 'api/relations'
import NetworkCard from 'components/NetworkCard'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import Button from 'components/UI/Button'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { t, textParser, wait } from 'helpers'
import { getUser } from 'helpers/storage'
import { notif } from 'helpers/notif'
import { useAppStore, useModalStore, useUserStore } from 'store'
import { Relation } from 'types/app'
import { RELATIONS } from 'types/enums'

interface Props {
	setInvitesCount: (count: number) => void
}

const AgencyInvites = ({ setInvitesCount }: Props): JSX.Element => {
	const { data, refresh } = useRelations(
		RELATIONS.GROUP_INVITE,
		getUser()?.['Custom:User:Id'] || '',
		'Customer',
		'/Lookup',
	)

	const { users } = useUsers(
		(data as Relation[])
			?.filter((rel) => rel?.status !== 'Rejected')
			?.map((rel) => rel.sourceId),
	)

	useEffect(() => {
		setInvitesCount(users?.length || 0)
	}, [users?.length, setInvitesCount])

	return (
		<>
			{users?.length ? (
				<div className="responsiveGrid">
					{users?.map((user) => (
						<NetworkCard
							key={user.ContentId}
							data={user}
							onClick={() => {
								const { user: currentUser } = useUserStore.getState()
								const { setModal } = useModalStore.getState()
								setModal({
									show: true,
									children: (
										<div
											className="card"
											style={{
												maxWidth: '750px',
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												gap: '20px',
												padding: '20px 50px',
											}}>
											<>
												<h4 className="h4-m" style={{ textAlign: 'center' }}>
													{t('APP_CONFIRM_USER_INVITE')}
												</h4>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '10px',
													}}>
													<Button
														style={{ width: '100%' }}
														onClick={() => {
															const { setUserInfoSidebar } =
																useAppStore.getState()
															setUserInfoSidebar(user?.ContentId)
															setModal(null)
														}}>
														{t('APP_OPEN_PROFILE')}
													</Button>
													<Button
														style={{ width: '100%' }}
														variant="themed"
														onClick={async () => {
															const poolId =
																useUserStore.getState()?.user
																	?.CustomerPoolDefault
															await addRelation({
																relation: RELATIONS.ORGANIZATION,
																type: 'Customer',
																sourceId: user?.ContentId,
																targetId: poolId || '',
																query: `?emailTemplateId=GenericMessage&subject=${t(
																	'APP_MESSAGE_AGENCY_APPROVE_SUBJECT',
																).replace(
																	'[YourName]',
																	currentUser?.ContentTitle || '',
																)}&message=${encodeURIComponent(
																	textParser(
																		t('APP_MESSAGE_AGENCY_APPROVE_BODY'),
																	)
																		.join('<br />')
																		.replace(
																			'[RecipientName]',
																			user?.ContentTitle,
																		)
																		.replace(
																			'[YourName]',
																			currentUser?.ContentTitle || '',
																		),
																)}`,
															})
															await deleteRelation(
																user?.ContentId,
																'Customer',
																RELATIONS.GROUP_INVITE,
																getUser()?.['Custom:User:Id'] || '',
															)
															await updateUser(user?.ContentId, [
																{
																	Name: 'Customer:Agency:Status',
																	Value: 'Approved',
																},
															])
															setModal(null)
															await wait(1000)
															refresh()
														}}>
														{t('APP_CONFIRM')}
													</Button>
													<Button
														style={{ width: '100%' }}
														onClick={async () => {
															const poolId =
																useUserStore.getState()?.user
																	?.CustomerPoolDefault
															if (!poolId) {
																notif('info', t('APP_NO_POOL_ID'))

																return
															}

															await addRelation({
																relation: RELATIONS.GROUP_INVITE,
																type: 'Customer',
																sourceId: user?.ContentId,
																targetId: poolId || '',
																status: 'Rejected',
																query: `?emailTemplateId=GenericMessage&subject=${t(
																	'APP_MESSAGE_AGENCY_REJECT_SUBJECT',
																).replace(
																	'[YourName]',
																	currentUser?.ContentTitle || '',
																)}&message=${encodeURIComponent(
																	textParser(
																		t('APP_MESSAGE_AGENCY_REJECT_BODY'),
																	)
																		.join('<br />')
																		.replace(
																			'[RecipientName]',
																			user?.ContentTitle,
																		)
																		.replace(
																			'[YourName]',
																			currentUser?.ContentTitle || '',
																		),
																)}`,
															})
															await updateUser(user?.ContentId, [
																{
																	Name: 'Customer:Agency:Status',
																	Value: 'Rejected',
																},
															])
															setModal(null)
															await wait(1000)
															refresh()
														}}>
														{t('APP_REJECT')}
													</Button>
													<Button
														style={{ width: '100%' }}
														onClick={() => {
															setModal(null)
														}}>
														{t('APP_CANCEL')}
													</Button>
												</div>
											</>
										</div>
									),
								})
							}}
						/>
					))}
				</div>
			) : (
				<div className="responsiveGrid">
					<CreateCardPlaceholder label="APP_RESULT_NOT_FOUND" />
				</div>
			)}
		</>
	)
}

export default AgencyInvites
