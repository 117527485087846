import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Input from 'components/UI/Input'
import Textarea from 'components/UI/Textarea'
import CheckboxButton from 'components/UI/CheckboxButton'
import Select from 'components/UI/Select'
import Access from './components/Access'
import Location from './components/Location'
import useContent from 'hooks/api/useContent'
import useContentAccess from 'hooks/useContentAccess'
import useContentHitContract from 'hooks/useContentHitContract'
import { productionBasic, productionInfo } from 'helpers/formFields'
import { optionsParser, t } from 'helpers'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { PROJECT_ACCESS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

const ProductionBasic = (): JSX.Element => {
	const [production, onChange, onBlur, onChangeEvent] = useProductionStore(
		useShallow((state) => [
			state.production,
			state.onChange,
			state.onBlur,
			state.onChangeEvent,
		]),
	)
	const { productionId } = useParams()
	const { access, role } = useContentAccess(productionId)

	const full = access === PROJECT_ACCESS.FULLACCESS
	const disabled = access === PROJECT_ACCESS.READ

	const { content } = useContent(productionId, 'bag')
	const dataLoadRef = useRef(false)

	useContentHitContract(productionId && content ? content : undefined)

	useEffect(() => {
		if (content && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setProduction } = useProductionStore.getState()
			Object.entries({ ...productionBasic, ...productionInfo })
				.map(([key, value]) => ({ key, name: value.name }))
				?.forEach(({ key, name }) => {
					let value: string | string[] =
						(content[name?.replaceAll(':', '') as keyof Content] as string) ||
						''
					if (key === 'locations') {
						value = value?.split(',')
						setProduction(key, value)
					} else if (key === 'nda') {
						setProduction(key, value === 'true')
					} else if (key === 'format') {
						if (value?.includes('[')) setProduction(key, JSON.parse(value))
					} else
						setProduction(key, value?.includes('[') ? JSON.parse(value) : value)
				})
		}
	}, [content])

	useEffect(() => {
		if (!productionId) {
			dataLoadRef.current = false
		}
	}, [productionId])

	return (
		<Card
			step="create_production"
			noHint
			title={t('APP_CREATE_PRODUCTION_TITLE')?.replace(
				'{ProductionName}',
				production?.name || '',
			)}>
			<>
				<div className="grid2column">
					<div className={s.info}>
						<Input
							name="name"
							label={productionBasic.name.label}
							onChange={onChange}
							value={production?.name}
							onBlur={(e) => {
								if (productionId) onBlur(e, productionId)
							}}
							disabled={disabled}
						/>

						<Textarea
							value={production?.description}
							name="description"
							label={productionBasic.description.label}
							onChange={onChange}
							onBlur={(e) => {
								if (productionId) onBlur(e, productionId)
							}}
							rows={15}
							limit={1000}
							disabled={disabled}
						/>
					</div>
					<div className={s.info} style={{ gap: '20px' }}>
						<div className={s.prodTypeWrapper}>
							<Select
								options={optionsParser(
									t(productionBasic.productionType.optionName),
								)}
								name="productionType"
								label={productionBasic.productionType.label}
								onChange={(e) => {
									onChangeEvent('productionType', e.target.value, productionId)
								}}
								inputProps={{
									value: production?.productionType || '',
									// defaultValue: production?.productionType || '',
									name: 'productionType',
									disabled: disabled,
								}}
							/>
							{production?.productionType?.includes('TV') ? (
								<div style={{ display: 'flex', width: '40%' }}>
									<Input
										name="seriesInfo"
										label={productionBasic.seriesInfo.label}
										value={production?.seriesInfo}
										onChange={onChange}
										onBlur={(e) => {
											onBlur(e, productionId)
										}}
										disabled={disabled}
									/>
								</div>
							) : null}
						</div>
						<Location disabled={disabled} />
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
							onClick={() => {
								const { setOpenNdaSidebar } = useProductionStore.getState()
								setOpenNdaSidebar(true)
							}}>
							<span
								className="inputLabel caption-m"
								style={{ cursor: 'pointer' }}>
								{t(productionBasic.nda.label)}
							</span>
							<CheckboxButton
								containerClassName="labelPadding"
								checked={production?.nda}
								disabled={disabled}
								onChange={() => {
									// onChangeEvent(
									// 	'nda',
									// 	!production?.nda as unknown as string,
									// 	productionId,
									// )
									// if (!production?.nda) {
									// 	const { setOpenNdaSidebar } = useProductionStore.getState()
									// 	setOpenNdaSidebar(true)
									// }
								}}
								label="APP_NDA_LABEL"
							/>
						</div>
						{productionId &&
						(full || role === CUSTOMER_ROLE.CASTING_DIRECTOR) ? (
							<Access />
						) : null}
					</div>
				</div>
			</>
		</Card>
	)
}

export default ProductionBasic
