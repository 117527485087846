import { InputHTMLAttributes, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { contentDelete } from 'api/content'
import { MagnifyingGlassIcon, ReplaceIcon } from 'assets/icons'
import FileInput from 'components/UI/FileInput'
import FileUploadHints from 'components/FileUploadHints'
import { usePortfolioStore } from 'store'
import { t } from 'helpers'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props {
	form: FileFormVariant
	label: string
	formatsText: string
	qualityText: string
	name: string
	fileType: 'Image' | 'Video'
	fileInputProps?: InputHTMLAttributes<HTMLInputElement>
}

const MediaUploadCard = ({
	label,
	form,
	formatsText,
	qualityText,
	fileType,
	name,
	fileInputProps,
}: Props): JSX.Element => {
	const [updateFile, setUpdateFile] = useState(false)
	const contentId = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[name] && (state.cardValues[name]?.contentId as string),
		),
	)
	const value = usePortfolioStore(
		useShallow((state) =>
			state.cardValues[name] && state.cardValues[name][name]
				? (state.cardValues[name][name] as File)
				: undefined,
		),
	)

	return (
		<div className={s.itemWrapper}>
			<div className={s.fileWrapper}>
				<FileInput
					{...fileInputProps}
					update={updateFile}
					form={form}
					file={value}
					label={label}
					fileType={fileType}
					onChange={(e) => {
						if (e.target.files?.length && e.target.files[0]) {
							const { setCardValues } = usePortfolioStore.getState()
							setCardValues({
								[name]: e.target.files[0],
								newUpload: true,
							})
						}
					}}
				/>

				{value ? (
					<div className={s.actions}>
						<div
							className={s.action}
							onClick={() => {
								const { setMediaZoomSidebar } = usePortfolioStore.getState()
								setMediaZoomSidebar({
									url:
										typeof value === 'string'
											? value
											: URL.createObjectURL(value),
									type: fileType,
								})
							}}>
							<div>
								<MagnifyingGlassIcon
									width="24"
									height="24"
									color="var(--mono900)"
								/>
							</div>
							<span style={{ color: 'var(--mono900)' }}>
								{t('APP_ZOOM_LABEL')}
							</span>
						</div>
						{typeof value === 'string' ||
						(value instanceof File && contentId) ? (
							<div
								className={s.action}
								onClick={() => {
									if (typeof value === 'string') {
										const v = value as string
										const id = v?.includes('/') ? v.split('/')[3] : value
										if (id) contentDelete(id)
									} else if (contentId) contentDelete(contentId)
									const { setCardValues } = usePortfolioStore.getState()
									setCardValues(
										{
											[name]: '',
										},
										name,
									)
									setUpdateFile(true)
									document.getElementById(fileInputProps?.id || '')?.click()
								}}>
								<div>
									<ReplaceIcon color="var(--mono900)" />
								</div>
								<span style={{ color: 'var(--mono900)' }}>
									{t('APP_CHANGE_LABEL')}
								</span>
							</div>
						) : null}
					</div>
				) : null}
			</div>
			<FileUploadHints
				form={form}
				formatsText={formatsText}
				qualityText={qualityText}
				hintText=""
				itemTitle=""
			/>
		</div>
	)
}

export default MediaUploadCard
