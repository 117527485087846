import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import Castings from './components/Castings'
import RoleCard from 'components/RoleCard'
import Info from './components/Info'
import useContent from 'hooks/api/useContent'
import { useAppStore, useModalStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'

const RoleInfoSidebar = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	const director =
		user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.CASTING_DIRECTOR
	const [roleId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.roleInfoSidebar, state.setRoleInfoSidebar]),
	)
	const modalOpened = useModalStore(useShallow((state) => state.modal?.show))
	const [loading, setLoading] = useState(false)

	return (
		<Sidebar
			open={!!roleId}
			onClose={() => {
				setOpenSidebar('')
			}}
			iconClose={modalOpened}
			loading={loading}
			header={{
				title: 'APP_ROLE_INFO_SIDEBAR_TITLE',
			}}>
			<>
				{roleId ? (
					<>
						{director ? (
							<>
								<Info />
								<Castings setLoading={setLoading} />
							</>
						) : (
							<ActorRoleInfo roleId={roleId} />
						)}
					</>
				) : null}
			</>
		</Sidebar>
	)
}

export default RoleInfoSidebar

const ActorRoleInfo = ({ roleId }: { roleId: string }): JSX.Element => {
	const { content } = useContent(roleId)

	return (
		<RoleCard
			content={content}
			type="aside"
			style={{ position: 'relative', maxWidth: 'unset' }}
		/>
	)
}
