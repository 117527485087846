import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { FilterIcon, PortfolioIcon, ShowreelsIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import RelationContentList from 'components/RelationContentList'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Button from 'components/UI/Button'
import useRelations from 'hooks/api/useRelations'
import useCollection from 'hooks/api/useCollection'
import { t } from 'helpers'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { PATHS } from 'types/enums'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { Content, Relation } from 'types/app'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'

const ContributorCastingDashboard = (): JSX.Element => {
	const filterActive = useAppStore(
		useShallow(
			(state) =>
				!!(
					state.localSearchSidebar?.search ||
					state.localSearchSidebar?.projectId
				),
		),
	)
	const userId = useUserStore(useShallow((state) => state.userId))

	const { data } = useRelations(RELATIONS.INVITE, userId || '', '', '/Lookup')

	useEffect(() => {
		const { setLocalSearchSidebar } = useAppStore.getState()
		setLocalSearchSidebar(null)
	}, [])

	return (
		<Card
			step="agencycasting_board"
			noHint
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
						marginLeft: 'auto',
					}}
					onClick={() => {
						const { setLocalSearchSidebar, localSearchSidebar } =
							useAppStore.getState()
						setLocalSearchSidebar({
							open: true,
							search: localSearchSidebar?.search || '',
							type: 'castings',
						})
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon
						color={filterActive ? 'var(--theme-primary)' : undefined}
					/>
				</Button>
			}
			className="cardWrapperSize">
			<>
				<SidebarTabs
					active={0}
					options={[
						{
							label: 'APP_CASTING_LABEL',
							value: 0,
						},
					]}
					onChange={() => {
						// setActiveTab(v as number)
						// swiperRef.current?.slideTo(v as number)
					}}
					type="fit"
				/>
				<div>
					{(data as Relation[])?.length ? (
						(data as Relation[])?.map((rel) => (
							<Roles
								productionId={rel.sourceId}
								key={rel.targetId + rel.sourceId}
							/>
						))
					) : (
						<div className="grid5column">
							<CreateCardPlaceholder label="APP_RESULT_NOT_FOUND" />
						</div>
					)}
				</div>
			</>
		</Card>
	)
}

export default ContributorCastingDashboard

const Roles = ({ productionId }: { productionId: string }): JSX.Element => {
	const { collection = [] } = useCollection(
		{ ContentId: productionId } as Content,
		true,
	)

	return (
		<>
			{collection?.map((content) => (
				<Castings key={content.ContentId} roleId={content.ContentId} />
			))}
		</>
	)
}

const Castings = ({ roleId }: { roleId: string }): JSX.Element => {
	const navigate = useNavigate()

	const { data } = useRelations(RELATIONS.ACCESS, roleId, 'Content')

	const openCasting = (
		content: Content,
		userId: string | undefined,
		prodId: string | undefined,
	): void => {
		// const data = {
		// 	properties: {
		// 		'Content:Casting:Status': ROLE_STATUS.CASTING,
		// 	},
		// }
		// partialContentUpdate(content.ContentId, data)

		// addRelation({
		// 	relation: RELATIONS.FAVPLAYLIST,
		// 	targetId: content.ContentId,
		// 	type: 'Customer',
		// })

		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${prodId}/${
				content.ContentId
			}${userId ? `/${userId}` : ''}`,
		)
	}

	const openProfile = (_: Content, userId?: string): void => {
		if (userId) {
			const { setUserInfoSidebar } = useAppStore.getState()
			setUserInfoSidebar(userId)
		}
	}

	return (
		<>
			<RelationContentList
				relations={(data as Relation[])?.filter((rel) =>
					[
						CASTING_STATUS.APPLIED,
						CASTING_STATUS.LIKED,
						CASTING_STATUS.DISLIKED,
						CASTING_STATUS.FAVORITE,
						CASTING_STATUS.NEUTRAL,
						CASTING_STATUS.SELECTED,
					].includes(rel.status as CASTING_STATUS),
				)}
				cardType="casting"
				actions={[
					{
						label: 'APP_OPEN_OVERVIEW',
						icon: <ShowreelsIcon />,
						onClick: openCasting,
					},
					{
						label: 'APP_OPEN_PROFILE',
						icon: <PortfolioIcon />,
						onClick: openProfile,
					},
				]}
			/>
		</>
	)
}
