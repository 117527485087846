import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNavigate } from 'react-router-dom'
import { removeCustomerSoft } from 'api/user'
import Card from 'components/Wizard/Card'
import CountrySelector from 'components/CountrySelector'
import HeadshotUpload from 'components/HeadshotUpload'
import Select from 'components/UI/Select'
import Input from 'components/UI/Input'
import Button from 'components/UI/Button'
import InputLabel from 'components/UI/InputLabel'
import RadioButton from 'components/UI/RadioButton'
import { optionsParser, t } from 'helpers'
import { getUser, logout } from 'helpers/storage'
import { useModalStore, usePortfolioStore, useUserStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { User } from 'types/user'
import s from './index.module.scss'

const openPortfolio = {
	label: 'APP_OPEN_PORTFOLIO_LABEL',
	name: 'Customer:Network:Open',
	optionName: DROPDOWN_OPTIONS.ALLOW_FREE_WORK_PORTFOLIO,
	type: 'switch',
	options: [],
}

const Basic = (): JSX.Element => {
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))
	const [location, setLocation] = useState('')
	const [openForVolunteerWork, setOpenForVolunteerWork] = useState(
		user?.[openPortfolio.name?.replaceAll(':', '') as keyof User] as string,
	)
	const profileName = 'Customer:Image:SquareUrl'
	const profileValue = usePortfolioStore(
		useShallow((state) =>
			state.cardValues?.[profileName] &&
			state?.cardValues?.[profileName]?.[profileName]
				? (state.cardValues[profileName][profileName] as File)
				: undefined,
		),
	)

	useEffect(() => {
		if (user?.CustomerLocationCountry)
			setLocation(user?.CustomerLocationCountry)
		if (user?.CustomerImageSquareUrl) {
			const { setCardValues } = usePortfolioStore.getState()
			setCardValues(
				{
					[profileName]: user?.CustomerImageSquareUrl,
				},
				profileName,
			)
		}
	}, [user?.CustomerImageSquareUrl, user?.CustomerLocationCountry])

	const onChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		const { updateFields } = usePortfolioStore.getState()
		updateFields([
			{
				Name: e.target.name,
				Value: e.target.value,
			},
		])
	}

	const removeAccount = async (): Promise<void> => {
		try {
			await removeCustomerSoft(getUser()?.['Custom:User:Id'] || '')
			const { setModal } = useModalStore.getState()
			setModal(null)
			logout()
			navigate('/')
		} catch (error) {
			console.log('error:', error)
		}
	}

	const openConfirmModal = (): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card style={{ width: '100%', maxWidth: '500px' }}>
					<>
						<h4 style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_DELETE_ACCOUNT_TITLE')}
						</h4>
						<div
							style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'space-between',
							}}>
							<Button style={{ width: '100%' }} onClick={removeAccount}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								variant="themed"
								style={{ width: '100%' }}
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	const clearCache = (): void => {
		localStorage.removeItem('provider')
		localStorage.removeItem('redirectUrl')
		localStorage.removeItem('Invitation:Code')
		localStorage.removeItem('LocalesExpiry')
		localStorage.removeItem('Locales')
		localStorage.removeItem('theme')
		window.location.reload()
	}

	return (
		<Card step="settings_profile" noHint>
			<>
				<HeadshotUpload
					id={profileName}
					form="circle"
					itemTitle="APP_PROFILEPICTURE_TITLE"
					hintText="APP_PROFILEPICTURE_HINT"
					qualityText="APP_PROFILEPICTURE_QUALITY"
					label="APP_PROFILEPICTURE_LABEL"
					value={profileValue}
				/>
				<div className="grid3column">
					<Input
						defaultValue={user?.ContentTitle}
						name="Content:Title"
						label="APP_NAME_LABEL"
						onBlur={onChange}
					/>
					<CountrySelector
						value={location || ''}
						onChange={(e) => {
							setLocation(e.target.value)
							onChange({
								target: {
									name: 'Customer:Location:Country',
									value: e.target.value,
								},
							} as ChangeEvent<HTMLInputElement>)
						}}
					/>
					<Input
						defaultValue={user?.CustomUserEmail}
						name="Custom:User:Email"
						label="APP_EMAIL_LABEL"
						onBlur={onChange}
					/>
				</div>
				<div className="grid3column">
					<Select
						options={optionsParser(t(DROPDOWN_OPTIONS.LANGUAGE))}
						name="Customer:Settings:Application:Language"
						label="APP_LANGUAGE_LABEL"
						inputProps={{
							value:
								user?.CustomerSettingsApplicationLanguage ||
								localStorage.getItem('locale') ||
								'',
						}}
						onChange={async (e) => {
							localStorage.setItem('locale', e.target.value)
							const { updateFields } = usePortfolioStore.getState()
							await updateFields([
								{
									Name: 'Customer:Settings:Application:Language',
									Value: e.target.value,
								},
							])
							window.location.reload()
						}}
					/>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<InputLabel label={openPortfolio.label} />

						<div className="grid2column">
							{optionsParser(openPortfolio.optionName || '')?.map((option) => (
								<RadioButton
									key={option.value}
									value={option.value}
									active={openForVolunteerWork}
									label={option.label}
									onClick={() => {
										const { updateFields } = usePortfolioStore.getState()
										setOpenForVolunteerWork(option.value)

										updateFields([
											{
												Name: openPortfolio.name,
												Value: option.value,
											},
										])
									}}
								/>
							))}
						</div>
					</div>
				</div>

				<div className={s.buttons}>
					<span
						className="body1-m"
						style={{ color: 'var(--theme-primary)' }}
						onClick={clearCache}>
						{t('APP_CLEAR_CACHE')}
					</span>
					<span
						className="body1-m"
						style={{ color: 'var(--error)' }}
						onClick={openConfirmModal}>
						{t('APP_REMOVE_ACCOUNT')}
					</span>
					<span className="caption-m" style={{ marginLeft: 'auto' }}>
						{'App version: ' + process.env.REACT_APP_VERSION}
					</span>
				</div>
			</>
		</Card>
	)
}

export default Basic
