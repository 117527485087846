import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Section from '../Section'
import { optionsParser, t } from 'helpers'
import { PORTFOLIO_TYPES } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	contactFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'
import { AttributeSelector, FilterSection } from 'types/app'
import { useFilterStore } from 'store'

const attr = PORTFOLIO_TYPES.ATTRIBUTE
const skill = PORTFOLIO_TYPES.SKILL

const filterSections: FilterSection[] = [
	{
		title: 'APP_CONTACTS_FILTER_SECTION',
		fields: Object.values(contactFields) as AttributeSelector[],
		hideByDefault: true,
	},
	{
		title: 'APP_PERSONAL_FILTER_SECTION',
		fields: Object.values(personalFields) as AttributeSelector[],
		hideByDefault: true,
	},
	{
		title: 'APP_BODY_FILTER_SECTION',
		fields: [
			...attributeBasicFields.hair.selectors,
			...attributeBasicFields.eyes.selectors,
			...attributeBasicFields.body.selectors,
			// ...attributeBasicFields.skin.selectors,
		],
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${skillFields.driving.value}`.toUpperCase(),
		fields: skillFields.driving.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${attributeSpecialFields.ability.value}`.toUpperCase(),
		fields: attributeSpecialFields.ability.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${attr}_${attributeBasicFields.voice.value}`.toUpperCase(),
		fields: attributeBasicFields.voice.selectors,
		hideByDefault: true,
	},
	{
		title:
			`APP_${attr}_${attributeSpecialFields.specialFeatures.value}`.toUpperCase(),
		fields: attributeSpecialFields.specialFeatures.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.athletics.value}`.toUpperCase(),
		fields: skillFields.athletics.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.specialSports.value}`.toUpperCase(),
		fields: skillFields.specialSports.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.spokenLanguage.value}`.toUpperCase(),
		fields: skillFields.spokenLanguage.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.actingLanguage.value}`.toUpperCase(),
		fields: skillFields.actingLanguage.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.singing.value}`.toUpperCase(),
		fields: skillFields.singing.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.dancing.value}`.toUpperCase(),
		fields: skillFields.dancing.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.instruments.value}`.toUpperCase(),
		fields: skillFields.instruments.selectors,
		hideByDefault: true,
	},

	{
		title: `APP_${skill}_${skillFields.otherSkill.value}`.toUpperCase(),
		fields: skillFields.otherSkill.selectors,
		hideByDefault: true,
	},
	{
		title: `APP_${skill}_${skillFields.specialSkill.value}`.toUpperCase(),
		fields: skillFields.specialSkill.selectors,
		hideByDefault: true,
	},
]

const Filter = (): JSX.Element => {
	const fieldsSearch = useFilterStore(useShallow((state) => state.fieldsSearch))

	const filters = useMemo(
		() =>
			fieldsSearch
				? filterSections?.map((section) => ({
						...section,
						fields: section?.fields?.filter(
							(field) =>
								t(field.label)
									?.toLowerCase()
									?.includes(fieldsSearch?.toLowerCase()) ||
								section?.fields
									?.map((field) => ({
										...field,
										options: optionsParser(t(field.optionName)),
									}))
									?.filter((field) =>
										field?.options?.some((option) =>
											option.label
												?.toLowerCase()
												.includes(fieldsSearch?.toLowerCase()),
										),
									)?.length,
						),
				  }))
				: filterSections,
		[fieldsSearch],
	)

	return (
		<>
			{filters
				?.filter((section) => section?.fields?.length)
				?.map((section) => (
					<Section
						section={{
							...section,
							hideByDefault: fieldsSearch ? false : section?.hideByDefault,
						}}
						key={section.title}
					/>
				))}
		</>
	)
}

export default Filter
