import { useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import InvitesToPoolCarousel from './components/InvitesToPoolCarousel'
import AcceptInviteToPoolSidebar from './components/AcceptInviteToPoolSidebar'
import Card from 'components/Wizard/Card'

const tabs = ['APP_INVITES_TO_POOL']?.map((label, index) => ({
	label,
	value: index,
}))

const ActorNetworkDashboard = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)

	return (
		<Card step="actor_network" noHint>
			<>
				<AcceptInviteToPoolSidebar />
				<SidebarTabs
					active={activeTab}
					options={tabs}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
					type="fit"
				/>
				<Swiper
					allowTouchMove={false}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					style={{ width: '100%' }}>
					<SwiperSlide>
						<InvitesToPoolCarousel />
					</SwiperSlide>
				</Swiper>
			</>
		</Card>
	)
}

export default ActorNetworkDashboard
