import { Fragment, useEffect, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { PhoneInputRefType } from 'react-international-phone'
import cn from 'classnames'
import { batchListUpload } from 'api/content'
import Input from 'components/UI/Input'
import Card from 'components/Wizard/Card'
import RangeInput from 'components/UI/RangeInput'
import PhoneInput from 'components/UI/PhoneInput'
import CheckboxButton from 'components/UI/CheckboxButton'
import Textarea from 'components/UI/Textarea'
import Agency from './components/Agency'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { usePortfolioStore, useUserStore } from 'store'
import { CUSTOMER_ROLE, User } from 'types/user'
import { NetworkCardType } from 'types/app'
import { contactFields } from '../../fields'
import s from '../Basic/index.module.scss'

// km:mile 1:0.62
const Contact = (): JSX.Element => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const user = useUserStore(useShallow((state) => state.user))
	const actor = user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR
	const [distance, travel, fields, newAgency] = usePortfolioStore(
		useShallow((state) => [
			state.cardValues['contact'][contactFields.distance.name],
			state.cardValues['contact'][contactFields.travel.name] as string,
			state.cardValues['contact'],
			state.newAgency,
		]),
	)

	const phoneSelector = contactFields.phone

	const sendMessage = async (user: NetworkCardType): Promise<void> => {
		await batchListUpload(
			[
				[
					{
						Name: 'Custom:User:Name',
						Value: user.name,
					},
					{
						Name: 'Custom:User:Email',
						Value: user.email,
					},
					{
						Name: 'Custom:User:PhoneNumber',
						Value: user.phone,
					},
					{
						Name: 'Customer:Location:Country',
						Value: (user.location as string) || '',
					},
					{
						Name: 'Customer:Location:City',
						Value: user.city || '',
					},
					{
						Name: 'Custom:User:Gender',
						Value: user.gender || '',
					},
					{
						Name: 'Custom:User:BirthDate',
						Value: user.birthday || '',
					},
					{
						Name: 'Customer:Role',
						Value: user.roleType || '',
					},
				],
			],
			'',
			textareaRef.current?.value.replace(/^\s*\n/gm, '\\n \\n') || '',
		)
	}

	useEffect(() => {
		EventBus.$on('sendNewAgency', () => {
			if (newAgency) sendMessage(newAgency)
		})

		return () => {
			EventBus.$off('sendNewAgency')
		}
	}, [newAgency])

	return newAgency ? (
		<>
			<Card step="NETWORK_MESSAGE" className="cardHeight">
				<Textarea
					label="APP_MESSAGEFIELD_DESC"
					inputRef={textareaRef}
					defaultValue={t('APP_NETWORK_DEFAULTMESSAGE_TEXT')}
					rows={14}
				/>
			</Card>
		</>
	) : (
		<Card step="contact">
			<div className={s.wrapper}>
				<div
					className={cn('grid2column')}
					style={{
						width: '100%',
					}}>
					{Object.values(contactFields)
						?.filter((field) =>
							!actor &&
							[
								contactFields.agency.name,
								contactFields.distance.name,
								contactFields.travel.name,
							].includes(field.name)
								? false
								: true,
						)
						?.map((field, index) => (
							<Fragment key={field.name}>
								{field.type === 'range' ? (
									<>
										<RangeInput
											key={field.name}
											checkOption
											checkValue={!!distance && +distance > 0}
											hideInput={travel === 'YES'}
											hideControls={travel === 'YES'}
											onCheckClick={() => {
												const { updateFields, setCardValues } =
													usePortfolioStore.getState()
												const newValue = !!distance && +distance > 0 ? '' : '1'
												setCardValues(
													{
														[field.name]: newValue,
													},
													'contact',
												)
												updateFields([{ Name: field.name, Value: newValue }])
											}}
											label={t(field.label)}
											valueLabel={
												travel === 'YES'
													? ''
													: `${distance || 0} km / ${(
															(+distance || 0) * 0.62
													  ).toFixed(0)} miles`
											}
											value={
												travel === 'YES'
													? ('' as unknown as number)
													: +distance || 0
											}
											name={field.name}
											min={0}
											max={1000}
											onBlur={(e) => {
												const { updateFields } = usePortfolioStore.getState()
												updateFields([
													{
														Name: field.name,
														Value: e.target.value,
													},
												])
											}}
											onChange={(e) => {
												const { setCardValues } = usePortfolioStore.getState()
												setCardValues(
													{
														[field.name]: e.target.value,
													},
													'contact',
												)
											}}
										/>
										{!!distance && +distance > 0 ? (
											<div
												key={contactFields.travel.name}
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignSelf: 'start',
													gridColumn: 'span 2',
												}}>
												<CheckboxButton
													label={contactFields.travel.label}
													checked={!!travel}
													onChange={() => {
														const { updateFields, setCardValues } =
															usePortfolioStore.getState()
														const newValue = travel ? '' : 'YES'
														setCardValues(
															{
																[contactFields.travel.name]: newValue,
															},
															'contact',
														)
														updateFields([
															{
																Name: contactFields.travel.name,
																Value: newValue,
															},
														])
													}}
												/>
											</div>
										) : null}
									</>
								) : field?.name === phoneSelector?.name ? (
									<PhoneInput
										key={field.name}
										label={field.label}
										name={field.name}
										onBlur={(e) => {
											const target = e.target as PhoneInputRefType
											if (
												target?.value &&
												target?.value !== '' &&
												target?.value !==
													(user ? user[field.name as keyof User] : '')
											) {
												const { updateFields } = usePortfolioStore.getState()
												updateFields([
													{
														Name: e.target.name,
														Value: target?.value,
													},
												])
											}
										}}
										defaultValue={
											user
												? user[
														field.name?.replaceAll(':', '') as keyof User
												  ]?.toString() || ''
												: ''
										}
									/>
								) : field.name?.includes('Agency') ? (
									<Agency key={field.name} />
								) : field.type === 'checkbox' ? null : (
									<Input
										key={field.name}
										name={field.name}
										label={field.label}
										placeholder={
											field.name?.endsWith('ImdbUrl')
												? 'https://www.imdb.com/name/'
												: ''
										}
										value={(fields[field?.name] as string) || ''}
										onChange={(e) => {
											const { setCardValues } = usePortfolioStore.getState()
											setCardValues(
												{
													[field.name]: e.target.value,
												},
												'contact',
											)
										}}
										onBlur={(e) => {
											if (
												e.target.value?.trim() !== '' &&
												e.target.value.trim() !== user?.CustomerName
											) {
												const { updateFields } = usePortfolioStore.getState()
												updateFields([
													{ Name: e.target.name, Value: e.target.value },
												])
											}
										}}
									/>
								)}
								{index === 0 && actor ? <span></span> : null}
							</Fragment>
						))}
				</div>
			</div>
		</Card>
	)
}

export default Contact
