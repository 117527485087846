import { ReactElement, SyntheticEvent, useState } from 'react'
import ReactCrop, {
	type Crop,
	centerCrop,
	makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

interface Props {
	id: string
	aspect: number
	imageSrc: string
	setCompletedCrop: (crop: Crop) => void
}

const ImageCrop = ({
	id,
	aspect,
	imageSrc,
	setCompletedCrop,
}: Props): ReactElement => {
	const [crop, setCrop] = useState<Crop>()

	const centerAspectCrop = (
		mediaWidth: number,
		mediaHeight: number,
		aspect: number,
	): Crop =>
		centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight,
			),
			mediaWidth,
			mediaHeight,
		)

	const onImageLoad = (e: SyntheticEvent<HTMLImageElement>): void => {
		const { width, height } = e.currentTarget
		setCrop(centerAspectCrop(width, height, aspect))
	}

	return (
		<ReactCrop
			style={{ height: '100%' }}
			aspect={aspect}
			crop={crop}
			onChange={(_, percentageCrop) => setCrop(percentageCrop)}
			onComplete={(c) => setCompletedCrop(c)}>
			<img
				style={{
					maxWidth: '100%',
				}}
				id={id}
				src={imageSrc}
				alt="avatar"
				onLoad={onImageLoad}
			/>
		</ReactCrop>
	)
}

export default ImageCrop
