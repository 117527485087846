import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import StepWrapper from 'components/Wizard/StepWrapper'
import RoleCoverSidebar from './components/RoleCoverSidebar'
import NdaSidebar from './components/NdaSidebar'
import LocationSidebar from './components/LocationSidebar'
import Loader from 'components/Loader'
import ProductionCoverSidebar from './components/ProductionCoverSidebar'
import StepControls from './components/StepControls'
import ProjectAccessSidebar from './components/ProjectAccessSidebar'
import GrantProjectAccessSidebar from './components/GrantProjectAccessSidebar'
import Aside from './components/Aside'
import MyProductions from './steps/MyProductions'
import ProductionBasic from './steps/ProductionBasic'
import ProductionInfo from './steps/ProductionInfo'
import RoleBasic from './steps/RoleBasic/RoleBasic'
import RoleScenes from './steps/RoleScenes'
import Roles from './steps/Roles'
import RoleInfo from './steps/RoleInfo'
import { useAppStore, useProductionStore, useUserStore } from 'store'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

export const projectSteps = {
	myproduction: {
		name: 'APP_PRODUCTIONS_ALL',
		key: 'production',
		main: true,
		hideSubstepsUntilActive: true,
		substeps: [
			{
				name: 'APP_PRODUCTIONS_ALL',
				key: 'production',
				component: MyProductions,
				index: 0,
			},
			{
				name: 'APP_PRODUCTION_BASIC',
				key: 'production',
				component: ProductionBasic,
				index: 1,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTION_DIRECTOR',
				key: 'production',
				component: ProductionInfo,
				index: 2,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTIONS_ROLES',
				key: 'production',
				component: Roles,
				index: 3,
				// disabled: true,
			},
		],
	},
	productionrole: {
		name: 'APP_ROLES_CREATE',
		key: 'production',
		main: true,
		dynamic: true,
		substeps: [
			{
				name: 'APP_PRODUCTIONS_ROLE_BASIC',
				key: 'production',
				component: RoleBasic,
				index: 4,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTIONS_ROLE_INFO',
				key: 'production',
				component: RoleInfo,
				index: 5,
				// disabled: true,
			},
			{
				name: 'APP_PRODUCTIONS_ROLE_SCENES',
				key: 'production',
				component: RoleScenes,
				index: 6,
				// disabled: true,
			},
		],
	},
}

const Project = (): JSX.Element => {
	const { hash, pathname } = useLocation()
	const navigate = useNavigate()
	const [loading] = useProductionStore(useShallow((state) => [state.loading]))
	const wizard = useAppStore(useShallow((state) => state.stepWizard))
	const { productionRoleId, productionId } = useParams()

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					Object.values(projectSteps)?.[0].key +
					'-0',
			)
	}, [navigate])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	/* remove role id */
	useEffect(() => {
		if (wizard?.currentStep && wizard.currentStep < 5 && productionRoleId) {
			const { user } = useUserStore.getState()

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${
					PATHS.PROJECT
				}/${productionId}` +
					(window.location.search || '') +
					window.location.hash,
			)
		}
	}, [productionId, productionRoleId, navigate, wizard?.currentStep])

	/* remove production id */
	useEffect(() => {
		if (productionId && wizard?.currentStep && wizard.currentStep === 1) {
			const { user } = useUserStore.getState()
			const { resetStore } = useProductionStore.getState()

			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.PROJECT}` +
					window.location.search +
					window.location.hash,
			)
			resetStore()
		}
	}, [navigate, hash, productionId, wizard?.currentStep])

	return (
		<div className={s.container}>
			<Loader loading={loading} visible />
			<GrantProjectAccessSidebar />
			<ProjectAccessSidebar />
			<RoleCoverSidebar />
			<NdaSidebar />
			<LocationSidebar />
			<ProductionCoverSidebar />
			<div className={cn(s.wrapper, 'flex')}>
				<StepWizard
					initialStep={0}
					instance={assignStepWizard}
					isHashEnabled={true}
					className={
						pathname?.includes(PATHS.PROJECT) && !hash?.includes('0')
							? cn(s.wizard)
							: ''
					}>
					{Object.values(projectSteps)
						?.map((step) => step.substeps)
						?.flat()
						?.map((step) => (
							<StepWrapper
								style={
									[
										'',
										`#${projectSteps.myproduction.key}-0`,
										`#${projectSteps.myproduction.key}-3`,
									].includes(hash)
										? {
												height: 'auto',
												display: 'flex',
												minHeight: 'calc(100dvh - 97px - 51px - 48px - 50px)',
										  }
										: {
												display: 'flex',
										  }
								}
								key={step.name + '_' + step.index}
								hashKey={step.key + '-' + step.index}
								stepName={step.key + '-' + step.index}>
								<step.component />
							</StepWrapper>
						))}
				</StepWizard>
				<Aside />
			</div>
			<StepControls />
		</div>
	)
}

export default Project
