import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Select from 'components/UI/Select'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeSelector } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'

interface Props {
	selector: AttributeSelector
}

const Selector = ({ selector }: Props): JSX.Element => {
	// const mediaType = usePortfolioStore(
	// 	useShallow((state) =>
	// 		[PORTFOLIO_TYPES.PICTURE, PORTFOLIO_TYPES.SHOWREEL].includes(
	// 			state.generalModalType,
	// 		),
	// 	),
	// )
	const values = usePortfolioStore(
		useShallow((state) => {
			const propName = state.getModalProp()

			return state.cardValues[propName] && state.cardValues[propName]
		}),
	)

	const value = useMemo(
		() =>
			selector?.options?.find(
				(option) =>
					values &&
					values[selector.name] &&
					values[selector.name] === option.value,
			),
		[selector.name, selector?.options, values],
	)

	return (
		<Select
			name={selector.name}
			options={selector.options}
			label={t(selector.label)}
			inputProps={{
				value: value?.value || '',
			}}
			onChange={(e) => {
				const { setCardValues, generalModalType, updateFields } =
					usePortfolioStore.getState()

				const media = [
					PORTFOLIO_TYPES.PICTURE,
					PORTFOLIO_TYPES.SHOWREEL,
				].includes(generalModalType)

				if (media) {
					// if (values?.contentId) {
					// 	partialContentUpdate(
					// 		values.contentId as string,
					// 		{
					// 			labels: {
					// 				[selector.name?.includes('Style') ? 'Style' : 'Type']: [
					// 					e.target.value,
					// 				],
					// 			},
					// 		},
					// 		'',
					// 		'?strategy=1',
					// 	)
					// }
				} else {
					// updateFields([
					// 	{
					// 		Name: selector.name,
					// 		Value: e.target.value,
					// 	},
					// ])
				}

				setCardValues({
					[selector.name]: e.target.value,
				})

				if (!media) {
					const { setGalleryType } = usePortfolioStore.getState()
					setGalleryType({
						value: e.target.value,
						label: selector.label,
					})
				}
			}}
		/>
	)
}

export default Selector
