import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import RelationContentList from 'components/RelationContentList'
import Card from 'components/Wizard/Card'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import AcceptSidebar from './components/AcceptSidebar'
import UpdateRequestSidebar from './components/UpdateRequestSidebar'
import ReadyToApply from './components/ReadyToApply'
import Invited from './components/Invited'
import Applied from './components/Applied'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import useCastingRelation from 'hooks/api/useCastingRelation'
import { useCastingStore } from 'store'
import { CASTING_STATUS } from 'types/enums'

const tabs = [
	'APP_SUBMISSION_EXPIRE_LABEL',
	'APP_INVITED_LABEL',
	'APP_REJECTED_LABEL',
	'APP_READY_TO_APPLY_LABEL',
	'APP_CASTING_LABEL',
	'APP_SELECTED_LABEL',
	'APP_ARCHIVED_LABEL',
].map((label, index) => ({
	label: label,
	value: index,
}))

const ActorCasting = (): JSX.Element => {
	const { data: castingRelations, refresh } = useCastingRelation('/Lookup')
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)
	const tagsCount = useCastingStore(useShallow((state) => state.tagsCount))

	const invited = castingRelations?.filter(
		(relation) => relation.status === CASTING_STATUS.INVITED,
	)
	const readyToApply = castingRelations?.filter((relation) =>
		[
			CASTING_STATUS.READY,
			CASTING_STATUS.UPLOADING,
			CASTING_STATUS.REAPPLIED,
		].includes(relation.status as CASTING_STATUS),
	)
	const applied = castingRelations?.filter((relation) =>
		[
			CASTING_STATUS.APPLIED,
			CASTING_STATUS.FAVORITE,
			CASTING_STATUS.LIKED,
			CASTING_STATUS.NEUTRAL,
			CASTING_STATUS.DISLIKED,
			CASTING_STATUS.RESUBMITED,
		].includes(relation.status as CASTING_STATUS),
	)
	const selected = castingRelations?.filter((relation) =>
		[CASTING_STATUS.SELECTED].includes(relation.status as CASTING_STATUS),
	)
	const rejected = castingRelations?.filter((relation) =>
		[CASTING_STATUS.REJECTED].includes(relation.status as CASTING_STATUS),
	)

	useEffect(() => {
		EventBus.$on('refreshActorBoard', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshActorBoard')
		}
	}, [refresh])

	const count: { [key: number]: string } = useMemo(
		() =>
			[
				tagsCount.expiring,
				invited?.length,
				rejected?.length,
				readyToApply?.length,
				tagsCount.castingOpened,
				selected?.length,
				tagsCount.castingClosed,
			]
				.map((count, index) => ({
					[index]: count ? ` ${count}` : '',
				}))
				?.reduce((prev, next) => ({ ...prev, ...next }), {}),
		[
			invited?.length,
			readyToApply?.length,
			selected?.length,
			rejected?.length,
			tagsCount,
		],
	)

	return (
		<>
			<AcceptSidebar />
			<UpdateRequestSidebar />
			<Card step="actor_board" className="cardWrapperSize" noHint>
				<>
					<SidebarTabs
						scroll
						active={activeTab}
						options={tabs?.map((tab, index) => ({
							...tab,
							label: count[index] ? (
								<TabCircleCount label={tab.label} count={count[index]} />
							) : (
								t(tab.label)
							),
						}))}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
						type="fit"
					/>
					<Swiper
						allowTouchMove={false}
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						style={{ width: '100%' }}>
						<SwiperSlide>
							{readyToApply?.length ? (
								<ReadyToApply relations={readyToApply} sortExpired />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{invited?.length ? (
								<Invited relations={invited} />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{rejected?.length ? (
								<RelationContentList relations={rejected} showUser={false} />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{readyToApply?.length ? (
								<ReadyToApply relations={readyToApply} />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{applied?.length ? (
								<Applied relations={applied} filter="castingOpened" />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{selected?.length ? (
								<RelationContentList relations={selected} showUser={false} />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{applied?.length ? (
								<Applied relations={applied} filter="castingClosed" />
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '165px' }}
									/>
								</div>
							)}
						</SwiperSlide>
					</Swiper>
				</>
			</Card>
		</>
	)
}

export default ActorCasting
