import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import ActionAnimation from 'components/ActionAnimation'
import Back from './components/Back'
import CastingChat from './components/CastingChat'
import CastingRoles from './components/CastingRoles/CastingRoles'
import DecisionActions from './components/DecisionActions'
import ContentActions from './components/ContentActions'
import Tutorial from './components/Tutorial'
import { useCastingStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

const CastingOverlay = (): JSX.Element => {
	const { roleId, userId } = useParams()
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const showTutorial = useCastingStore(
		useShallow((state) => state.showTutorial),
	)

	return (
		<div className={s.container} id="casting-overlay-container">
			<Tutorial />
			{showTutorial ? null : (
				<>
					<CastingRoles />
					<ActionAnimation />
					<CastingChat />
					<Back />
					{director && roleId && userId ? (
						<>
							<ContentActions />
							<DecisionActions />
						</>
					) : null}
				</>
			)}
		</div>
	)
}

export default CastingOverlay
