import { useState } from 'react'
import { PlusIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import Player from 'components/Player'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'

const UploadTutorial = (): JSX.Element => {
	const [showTutorial, setShowTutorial] = useState(false)

	const { content } = useContent('4373c8ce-85d6-4f85-abc9-f1b908e766d0')

	return (
		<>
			{showTutorial && content ? (
				<div
					className="playerLayoutContainer"
					style={{
						position: 'absolute',
						top: '0',
						left: '0',
						zIndex: 3,
						width: '100%',
						height: '100%',
						borderRadius: '15px',
						overflow: 'hidden',
						backgroundColor: 'var(--elev400)',
					}}>
					<div
						style={{
							position: 'absolute',
							top: '20px',
							right: '20px',
							color: 'var(--mono200)',
							mixBlendMode: 'difference',
							cursor: 'pointer',
							zIndex: 4,
						}}
						onClick={() => setShowTutorial(false)}>
						<PlusIcon
							width="48"
							height="48"
							style={{ transform: 'rotate(45deg)' }}
						/>
					</div>
					<Player
						active={showTutorial}
						videoId="upload-tutorial"
						content={content}
						videoProps={{
							style: {
								objectFit: 'cover',
							},
						}}
					/>
				</div>
			) : null}
			<Button
				style={{ alignItems: 'center' }}
				onClick={() => setShowTutorial(true)}>
				{t('APP_SHOW_TUTORIAL')}
			</Button>
		</>
	)
}

export default UploadTutorial
