import { InputHTMLAttributes } from 'react'
import cn from 'classnames'
import {
	ParsedCountry,
	PhoneInput as ReactPhoneInput,
} from 'react-international-phone'
import InputLabel from '../InputLabel'
import 'react-international-phone/style.css'
import inputStyle from '../Input/index.module.scss'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	defaultValue: string
	onPhoneChange?: (
		phone: string,
		meta: {
			country: ParsedCountry
			inputValue: string
		},
	) => void
	label?: string
}

const PhoneInput = ({
	defaultValue,
	onBlur,
	onPhoneChange,
	label,
	name,
}: Props): JSX.Element => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		<InputLabel label={label} status={defaultValue ? 'filled' : 'none'} />
		<ReactPhoneInput
			value={defaultValue}
			className={cn(inputStyle.container, s.container)}
			inputClassName={cn(s.input, 'body1-m')}
			onBlur={onBlur}
			onChange={onPhoneChange}
			name={name}
			inputProps={{
				autoComplete: 'new-password',
			}}
		/>
	</div>
)

export default PhoneInput
