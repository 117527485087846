import useSwrImmutable from 'swr/immutable'
import { useShallow } from 'zustand/react/shallow'
import { KeyedMutator } from 'swr'
import { fetcher, multipleFetcher } from 'api'
import contentParser, {
	getQueryFilter,
	getQueryOrder,
} from 'helpers/propertiesParser'
import { useUserStore } from 'store'
import {
	CollectionsType,
	ContentCollection,
	FetchReturn,
	FilterSearch,
	OrderSearch,
} from 'types/app'
import { User } from 'types/user'

type ReturnData = FetchReturn & {
	data: User[] | undefined
	refresh: KeyedMutator<ContentCollection>
}

interface Props {
	fetch: boolean
	itemLimit?: number
	orders?: OrderSearch[]
	filters?: FilterSearch[]
	searchText?: string
	organizationId?: string | false
	nonOrg?: boolean
	type?: string
}

export default ({
	fetch,
	itemLimit = 10000,
	orders = [
		{
			sortBy: 'Customer:RegisteredDate',
		},
	],
	filters,
	searchText = '',
	organizationId,
	nonOrg,
	type = 'Creator',
}: Props): ReturnData => {
	const poolDefault = useUserStore(
		useShallow((state) => state?.user?.CustomerPoolDefault),
	)
	const orgId = organizationId === false ? '' : organizationId || poolDefault

	const order = window.btoa(
		JSON.stringify({
			LimitItem: itemLimit,
			OrderRules: orders?.map((order) =>
				getQueryOrder(order.sortBy, order.sortDir, order.sortFormat),
			),
		}),
	)

	const filter = filters?.length
		? window.btoa(
				JSON.stringify(
					filters?.map((f) =>
						getQueryFilter(f.propName, f.propValue, f.cond, f.format),
					),
				),
		  )
		: ''

	const url = `https://bgj.theatroo.ent360.blue/api/v2.1/Customer/Query?o=${order}&f=${
		filter || 'W10'
	}${searchText ? '&q=' + searchText : ''}${
		orgId ? '&orgs=' + orgId : ''
	}&type=${type}${nonOrg && poolDefault ? '&nonOrgs=' + poolDefault : ''}`

	const { data, isLoading, error, mutate } = useSwrImmutable<ContentCollection>(
		fetch ? url : null,
		fetcher,
	)

	const userLinks = data?.ChildItems?.map((child) =>
		child?.Actions?.length ? child.Actions[0]?.Url : null,
	)?.filter((v) => !!v)

	const { data: users, isLoading: usersLoading } = useSwrImmutable<
		CollectionsType[]
	>(userLinks?.length ? userLinks : null, multipleFetcher)

	const parsed = users?.map((user) => contentParser<User>(user))

	return {
		data: parsed?.length ? parsed : undefined,
		isLoading: isLoading || usersLoading,
		refresh: mutate,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
	}
}
