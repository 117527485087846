import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useAppStore, usePortfolioStore, useUserStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import StepWizard, {
	StepWizardChildProps,
	StepWizardProps,
} from 'react-step-wizard'
import classNames from 'classnames'
import StepWrapper from 'components/Wizard/StepWrapper'
import Tutorial from './components/Tutorial'
import GallerySidebar from './components/GallerySidebar/GallerySidebar'
import AttributeSidebar from './components/AttributeSidebar'
import AdditionalSidebar from './components/AdditionalSidebar'
import AgencySidebar from './components/AgencySidebar'
import MediaZoomSidebar from './components/MediaZoomSidebar'
import Card from 'components/Wizard/Card'
import ActionAnimation from 'components/ActionAnimation'
import Loader from 'components/Loader'
import Controls from 'components/Wizard/Controls'
import Photos from './steps/Photos'
import Basic from './steps/Basic'
import Contact from './steps/Contact'
import Personal from './steps/Personal'
import Biography from './steps/Biography'
import Pictures from './steps/Pictures'
import Showreels from './steps/Showreels'
import Attributes from './steps/Attributes'
import Skills from './steps/Skills'
import Summary from './steps/Summary'
import { EventBus } from 'helpers/EventBus'
import {
	attributeBasicFields,
	attributeSpecialFields,
	basicFields,
	biographyFields,
	contactFields,
	personalFields,
	picturesFields,
	showreelsFields,
	skillFields,
} from './fields'
import { CUSTOMER_ROLE, User } from 'types/user'
import { AttributeSelector } from 'types/app'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

export const directorPortfolioSteps = {
	basic: {
		name: 'APP_PROFILE_BASIC',
		key: 'basic',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_PHOTOS',
				key: 'basic',
				component: Photos,
				index: 0,
			},
			{
				name: 'APP_PROFILE_BASIC_INFO',
				key: 'basic',
				component: Basic,
				index: 1,
			},
			{
				name: 'APP_PROFILE_CONTACT',
				key: 'basic',
				component: Contact,
				index: 2,
			},
			{
				name: 'APP_PROFILE_PERSONAL',
				key: 'basic',
				component: Personal,
				index: 3,
			},
			{
				name: 'APP_PROFILE_BIOGRAPHY',
				key: 'basic',
				component: Biography,
				index: 4,
			},
		],
	},
}

export const actorPortfolioSteps = {
	basic: directorPortfolioSteps.basic,
	pictures: {
		name: 'APP_PROFILE_MEDIA',
		key: 'pictures',
		main: true,
		substeps: [
			{
				name: '1 - 4',
				key: 'pictures',
				component: Pictures,
				index: 0,
			},
			{
				name: '5 - 8',
				key: 'pictures',
				component: Pictures,
				index: 1,
			},
			{
				name: '9 - 12',
				key: 'pictures',
				component: Pictures,
				index: 2,
			},
			{
				name: '13 - 16',
				key: 'pictures',
				component: Pictures,
				index: 3,
			},
		],
	},
	showreels: {
		name: 'APP_PROFILE_SHOWREELS',
		key: 'showreels',
		main: true,
		substeps: [
			{
				name: '1 - 4',
				key: 'showreels',
				component: Showreels,
				index: 0,
			},
			{
				name: '5 - 8',
				key: 'showreels',
				component: Showreels,
				index: 1,
			},
			{
				name: '9 - 12',
				key: 'showreels',
				component: Showreels,
				index: 2,
			},
			{
				name: '13 - 16',
				key: 'showreels',
				component: Showreels,
				index: 3,
			},
		],
	},
	attributes: {
		name: 'APP_PROFILE_ATTRIBUTES',
		key: 'attributes',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_ATTRIBUTES',
				key: 'attributes',
				component: Attributes,
				index: 0,
			},
			{
				name: 'APP_PROFILE_SKILLS',
				key: 'attributes',
				component: Skills,
				index: 1,
			},
		],
	},
	summary: {
		name: 'APP_PROFILE_SUMMARY',
		key: 'summary',
		main: true,
		substeps: [
			{
				name: 'APP_PROFILE_SUMMARY',
				key: 'summary',
				component: Summary,
				index: 0,
			},
		],
	},
}

const Portfolio = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const [storeLoading, setStoreLoading] = useState(true)
	const [profileWizard, portfolioModeActive] = useAppStore(
		useShallow((state) => [state.stepWizard, state.portfolioMode.active]),
	)
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)

	const [currentCard, newAgency, loading] = usePortfolioStore(
		useShallow((state) => [state.cardData, state.newAgency, state.loading]),
	)
	const lastStep = profileWizard?.currentStep === profileWizard?.totalSteps

	const steps = {
		[CUSTOMER_ROLE.ACTOR]: actorPortfolioSteps,
		[CUSTOMER_ROLE.AGENCY]: portfolioModeActive
			? actorPortfolioSteps
			: directorPortfolioSteps,
		[CUSTOMER_ROLE.CONTRIBUTOR]: directorPortfolioSteps,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: directorPortfolioSteps,
	}

	const currentSteps = steps[userRole as CUSTOMER_ROLE]

	useEffect(() => {
		if (!window.location.hash)
			navigate(
				window.location.pathname +
					window.location.search +
					'#' +
					currentSteps?.basic?.key +
					'-0',
			)
	}, [navigate, currentSteps])

	const assignStepWizard = (instance: StepWizardProps): void => {
		const { setStepWizard } = useAppStore.getState()
		setStepWizard(instance as StepWizardChildProps)
	}

	const nextStep = (): void => {
		profileWizard?.nextStep()
	}

	const prevStep = (): void => {
		profileWizard?.previousStep()
	}

	const showAnimation = (type: string): void =>
		EventBus.$emit(`portfolio${type}ActionAnimation`)

	const calculateSummary = (
		step: string,
		fields: {
			[key: string]: AttributeSelector
		},
	): number => {
		const { cardValues } = usePortfolioStore.getState()
		const percent =
			(Object.values(fields)
				.map((field) => cardValues?.[step]?.[field.name])
				?.filter((v) => !!v)?.length *
				100) /
			Object.values(fields).length

		return percent
	}

	const onSubmitStep = (): void => {
		if (userRole === CUSTOMER_ROLE.ACTOR) {
			const { stepWizard } = useAppStore.getState()
			const active = stepWizard?.currentStep
			let percent = 0

			if (active === 1) {
				const { cardValues } = usePortfolioStore.getState()
				const names = ['Customer:Image:SquareUrl', 'Customer:Image:PortraitUrl']
				const values = names
					?.map((name) => cardValues?.[name])
					?.filter((v) => !!v)
				percent = (values?.length * 100) / names?.length
			} else if (active && active <= 5) {
				const steps: {
					[key: number]: {
						step: string
						fields: { [key: string]: AttributeSelector }
					}
				} = {
					2: {
						step: 'basic',
						fields: basicFields,
					},
					3: {
						step: 'contact',
						fields: contactFields,
					},
					4: {
						step: 'personal',
						fields: personalFields,
					},
					5: {
						step: 'biography',
						fields: biographyFields,
					},
				}

				const current = steps[active]
				percent = calculateSummary(current.step, current.fields)
			} else if (active && active <= 9) {
				let percentages = 0

				picturesFields
					?.slice(4 * (active - 6), 4 * (active - 6) + 4)
					.forEach((field) => {
						percentages += calculateSummary(field.value, {
							[field.value]: {
								name: field.value,
							} as AttributeSelector,
						})
					})

				percent = percentages / 4
			} else if (active && active <= 13) {
				let percentages = 0

				showreelsFields
					?.slice(4 * (active - 10), 4 * (active - 10) + 4)
					.forEach((field) => {
						percentages += calculateSummary(field.value, {
							[field.value]: {
								name: field.value,
							} as AttributeSelector,
						})
					})

				percent = percentages / 4
			} else if (active && active === 14) {
				let length = 0
				let percentages = 0
				Object.values({
					...attributeBasicFields,
					...attributeSpecialFields,
				}).forEach((fields) => {
					length += fields?.selectors?.length

					fields.selectors.forEach((field) => {
						percentages += calculateSummary(fields.value, {
							[fields.value]: field,
						})
					})
				})

				percent = percentages / length
			} else if (active && active === 15) {
				let length = 0
				let percentages = 0
				Object.values(skillFields).forEach((fields) => {
					length += fields?.selectors?.length

					fields.selectors.forEach((field) => {
						percentages += calculateSummary(fields.value, {
							[fields.value]: field,
						})
					})
				})

				percent = percentages / length
			}

			if (percent > 90) showAnimation('Fulstatus')
			else if (percent > 60) showAnimation('Midstatus')
			else showAnimation('Lowstatus')
		}

		nextStep()
	}

	const refreshUserData = useCallback(async () => {
		const { refreshUser } = useUserStore.getState()
		const user = await refreshUser()

		const { setCardValues, cardValues } = usePortfolioStore.getState()

		if (user) {
			const steps = [
				{ key: 'basic', fields: basicFields },
				{ key: 'contact', fields: contactFields },
				{ key: 'personal', fields: personalFields },
				{ key: 'biography', fields: biographyFields },
			]
			steps.forEach((step) => {
				setCardValues(
					{
						...Object.values(step.fields)
							?.map((field) => ({
								[field.name]:
									(user?.[
										field.name?.replaceAll(':', '') as keyof User
									] as string) || '',
							}))
							.reduce(
								(prev, next) => ({
									...prev,
									...next,
								}),
								{},
							),
					},
					step.key,
				)
			})

			if (user && !cardValues['Custom:User:BirthDate']) {
				setCardValues(
					{
						'Custom:User:BirthDate': user?.CustomUserBirthDate || '',
					},
					'basic',
				)
			}
		}

		setStoreLoading(false)
	}, [])

	useEffect(() => {
		refreshUserData()
	}, [refreshUserData])

	const nextClick = (): void => {
		// EventBus.$emit('confetti')
		if (profileWizard?.currentStep === profileWizard?.totalSteps) {
			navigate('/' + userRole + '/' + PATHS.DASHBOARD)

			return
		}

		if (newAgency) {
			EventBus.$emit('sendNewAgency')
			const { setNewAgency } = usePortfolioStore.getState()
			setNewAgency(null)
		} else onSubmitStep()
	}

	const prevClick = (): void => {
		if (newAgency) {
			const { setNewAgency } = usePortfolioStore.getState()
			setNewAgency(null)
		} else prevStep()
	}

	return (
		<div className={s.container}>
			<div className={classNames(s.wrapper, 'flex')}>
				<Loader loading={storeLoading || loading} adapt={loading} />
				{storeLoading ? null : (
					<>
						<GallerySidebar />
						<AttributeSidebar cardData={currentCard} />
						<AdditionalSidebar />
						<AgencySidebar />
						<MediaZoomSidebar />
						<Card
							className={classNames({
								[s.wizard]: userRole === CUSTOMER_ROLE.ACTOR,
								[s.lastStep]: lastStep && userRole === CUSTOMER_ROLE.ACTOR,
							})}
							style={{ position: 'relative', padding: '0' }}>
							<>
								<ActionAnimation />
								<StepWizard
									initialStep={0}
									instance={assignStepWizard}
									isHashEnabled={true}>
									{Object.values(currentSteps)
										?.map((step) => step.substeps)
										?.flat()
										?.map((step) => (
											<StepWrapper
												key={step.name + '_' + step.index + '-' + step.key}
												hashKey={step.key + '-' + step.index}
												stepName={step.key + '-' + step.index}>
												<step.component index={step.index} />
											</StepWrapper>
										))}
								</StepWizard>
							</>
						</Card>
					</>
				)}
				{userRole === CUSTOMER_ROLE.ACTOR ? (
					<Tutorial step={hash?.slice(1)} />
				) : null}
			</div>
			<Controls
				nextClick={nextClick}
				nextText={
					profileWizard?.currentStep === profileWizard?.totalSteps
						? 'APP_COMPLETE'
						: newAgency
						? 'APP_SEND'
						: 'APP_NEXT_BUTTON'
				}
				nextIcon={profileWizard?.currentStep !== profileWizard?.totalSteps}
				prevClick={profileWizard?.currentStep === 1 ? undefined : prevClick}
				prevText="APP_BACK_BUTTON"
			/>
		</div>
	)
}

export default Portfolio
