import { useCallback, useEffect, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import { usePlayerStore } from 'store'
import { Subtitle } from 'types/content'
import s from './index.module.scss'

interface Props {
	subtitles: Subtitle[] | null
	videoElement?: HTMLVideoElement | null
}

const Subtitles = ({ subtitles, videoElement }: Props): JSX.Element => {
	const subtitle = useRef<HTMLHeadingElement>(null)
	const [caption] = usePlayerStore(useShallow((state) => [state.caption]))

	const switchSubtitle = useCallback(
		(langCode: string): void => {
			const textTracks = videoElement?.textTracks
			if (textTracks) {
				for (let i = 0; i < textTracks.length; i++) {
					if (textTracks[i].id === langCode) {
						textTracks[i].mode = 'showing'
					} else {
						textTracks[i].mode = 'disabled'
					}
				}
			}
		},
		[videoElement],
	)

	const castSubtitles = (event: Event): void => {
		if (!subtitle.current) return

		const track = event.target as TextTrack
		const activeCue = track.activeCues?.[0] as VTTCue | undefined
		if (activeCue?.text) {
			subtitle.current.innerHTML = activeCue.text.replace(/\n/g, '<br>')
			subtitle.current.style.padding = '10px'
		} else {
			subtitle.current.innerHTML = ''
			subtitle.current.style.padding = '0px'
		}
	}

	const displaySubtitles = useCallback(
		(subtitleLang: string, textTrack: TextTrack): void => {
			if (!subtitleLang) return

			let newTextTrack = textTrack
			if (videoElement && videoElement?.textTracks.length > 0) {
				const video = videoElement
				for (let i = 0; i < video.textTracks.length; i++) {
					newTextTrack = video.textTracks[i]
					if (newTextTrack) {
						if (newTextTrack.id === subtitleLang) {
							newTextTrack.addEventListener('cuechange', castSubtitles, true)
							newTextTrack.mode = 'hidden'
						} else {
							newTextTrack.mode = 'disabled'
						}
					}
				}
			}
		},
		[videoElement],
	)

	useEffect(() => {
		const selectedSubtitle = subtitles?.find(
			(item) => item.langCode === caption,
		)
		if (selectedSubtitle) {
			switchSubtitle(selectedSubtitle.langCode)
			const textTrack: any = null
			if (subtitle.current) {
				subtitle.current.innerHTML = ''
			}
			if (selectedSubtitle.langCode !== 'off') {
				displaySubtitles(selectedSubtitle.langCode, textTrack)
			}

			return () => {
				if (textTrack !== null)
					textTrack.removeEventListener('cuechange', castSubtitles)
			}
		}
	}, [caption, displaySubtitles, subtitles, switchSubtitle])

	useEffect(() => {
		if (subtitles?.length && videoElement) {
			const children = videoElement?.children?.length
			if (children !== 0) return

			for (const sub of subtitles) {
				const track = document.createElement('track')
				track.id = sub.langCode
				track.kind = 'subtitles'
				track.src = sub.url
				track.srclang = sub.langCode.slice(0, 2)
				track.label = sub.langCode
				videoElement?.appendChild(track)
			}
		}
	}, [subtitles, videoElement])

	return (
		<div
			data-role="subtitles"
			className={cn(s.container, {
				['castingToolWrapper']: caption && caption !== 'off',
			})}>
			<h2 ref={subtitle} />
		</div>
	)
}

export default Subtitles
