import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import SceneItem from '../SceneItem'
import useContentFullRelation from 'hooks/api/useContentFullRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContentRelation from 'hooks/api/useContentRelation'
import useRelation from 'hooks/api/useRelation'
import { orderScenes } from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import { t, textParser } from 'helpers'
import { useCastingStore, useUserStore } from 'store'
import { notif } from 'helpers/notif'
import { Content } from 'types/app'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

interface Props {
	content: Content | undefined
}

const Scenes = ({ content }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { roleId } = useParams()
	const { relation } = useRelation(
		RELATIONS.ACCESS,
		getUser()?.['Custom:User:Id'] || '',
		roleId || '',
	)
	const sceneSidebarOpened = useCastingStore(
		useShallow((state) => !!state.sceneUploadContent),
	)
	const { contents } = useContentFullRelation(
		content?.ContentId,
		RELATIONS.PLAYLIST,
	)
	const { data } = useRelationsContent(
		content?.ContentId ? [RELATIONS.CONTENT] : [],
		`${content?.ContentId}_Content_Access`,
		'Relationship',
	)

	const playlistContent = data?.[RELATIONS.CONTENT]?.find(
		(content) => content?.ContentOwnerId === getUser()?.['Custom:User:Id'],
	)

	const { contents: uploadedScenes, refresh: refreshUploaded } =
		useContentRelation(playlistContent?.ContentId, RELATIONS.PLAYLIST, true)

	useEffect(() => {
		const { chatContentId, setChatContentId } = useCastingStore.getState()
		if (
			playlistContent?.ContentId &&
			(!chatContentId || chatContentId !== playlistContent?.ContentId)
		)
			setChatContentId(playlistContent?.ContentId)
	}, [playlistContent?.ContentId])

	useEffect(() => {
		EventBus.$on('submitCastingScenesSidebar', async () => {
			try {
				EventBus.$emit('sendActorCastingMessage')
				const subject =
					relation?.status === CASTING_STATUS.UPLOADING
						? 'APP_MESSAGE_SUBMIT_SUBJECT'
						: 'APP_MESSAGE_RESUBMIT_SUBJECT'
				const messageKey =
					relation?.status === CASTING_STATUS.UPLOADING
						? 'APP_MESSAGE_SUBMIT_BODY'
						: 'APP_MESSAGE_RESUBMIT_BODY'
				const message = useCastingStore.getState().castingSubmitMessage

				console.log(
					'asdfasd',
					`?emailTemplateId=GenericMessage&subject=${t(subject)?.replace(
						'[RoleName]',
						content?.['Globalizationen-USContentTitle'] || '',
					)}&message=${encodeURIComponent(
						textParser(t(messageKey))
							.join('<br />')
							?.replace('[Message]', message)
							?.replace(
								'[RoleName]',
								content?.['Globalizationen-USContentTitle'] || '',
							)
							?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
					)}`,
				)

				await addRelation({
					sourceId: content?.ContentId,
					targetId: getUser()?.['Custom:User:Id'] || '',
					type: 'Content',
					relation: RELATIONS.ACCESS,
					status:
						relation?.status === CASTING_STATUS.REAPPLIED
							? CASTING_STATUS.RESUBMITED
							: CASTING_STATUS.NEUTRAL,
					query: `?emailTemplateId=GenericMessage&subject=${t(subject)?.replace(
						'[RoleName]',
						content?.['Globalizationen-USContentTitle'] || '',
					)}&message=${encodeURIComponent(
						textParser(t(messageKey))
							.join('<br />')
							?.replace('[Message]', message)
							?.replace(
								'[RoleName]',
								content?.['Globalizationen-USContentTitle'] || '',
							)
							?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
					)}`,
				})

				const { user } = useUserStore.getState()
				navigate(`/${user?.CustomerRole?.toLowerCase()}/${PATHS.DASHBOARD}`)
				const { setOpenSubmitCastingSidebar } = useCastingStore.getState()
				setOpenSubmitCastingSidebar(false)
				EventBus.$emit('confetti')
				EventBus.$off('refreshActorBoard')
			} catch (error) {
				console.log('error:', error)
			}
		})

		return () => {
			EventBus.$off('submitCastingScenesSidebar')
		}
	}, [content, navigate, relation?.status])

	useEffect(() => {
		EventBus.$on('submitCastingScenes', async () => {
			const { uploadedSceneIds } = useCastingStore.getState()
			if (
				uploadedSceneIds &&
				contents &&
				uploadedSceneIds?.length < contents?.length
			) {
				notif('info', t('APP_UPLOAD_ALL_SCENES_ERROR'))

				return
			}

			const { setOpenSubmitCastingSidebar } = useCastingStore.getState()
			setOpenSubmitCastingSidebar(true)
		})

		return () => {
			EventBus.$off('submitCastingScenes')
		}
	}, [content?.ContentId, contents])

	useEffect(() => {
		if (!sceneSidebarOpened) {
			setTimeout(() => {
				refreshUploaded()
			}, 1000)
		}
	}, [sceneSidebarOpened, refreshUploaded])

	return (
		<div className={s.wrapper}>
			{orderScenes([], contents)?.map((scene, index) => (
				<SceneItem
					key={scene.ContentId}
					scene={{
						...scene,
						relationIndex: index,
					}}
					uploadedScenes={uploadedScenes}
					relation={relation}
				/>
			))}
		</div>
	)
}

export default Scenes
