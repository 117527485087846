import { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import useSwrImmutable from 'swr/immutable'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Header from './components/Header'
import Personal from './components/Personal'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import MediaWrapper from './components/MediaWrapper'
import Skills from './components/Skills'
import MediaModal from './components/MediaModal'
import Audition from './components/Audition'
import { collectionFetcher } from 'hooks/api/useCollection'
import useCastingRelation from 'hooks/api/useCastingRelation'
import useContentHitContract from 'hooks/useContentHitContract'
import { getPinnedMedia, optionsParser } from 'helpers'
import { useModalStore, useUserStore } from 'store'
import { CUSTOMER_ROLE, User } from 'types/user'
import { Content, ValueLevel } from 'types/app'
import { skillFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	user: User | undefined
	place?: 'sidebar' | 'default'
}

const ProfileLayout = ({ user, place = 'default' }: Props): JSX.Element => {
	const currentUser = useUserStore(useShallow((state) => state.user))
	const [activeTab, setActiveTab] = useState(0)
	const swiperRef = useRef<SwiperClass>()
	const [activeAttribute, setActiveAttribute] = useUserStore(
		useShallow((state) => [state.activeAttribute, state.setActiveAttribute]),
	)

	useContentHitContract(
		user && user?.ContentId !== currentUser?.ContentId
			? ({
					ContentId: user?.ContentId,
			  } as Content)
			: undefined,
		undefined,
		'Customer',
	)

	const { data: accessRelations = [] } = useCastingRelation(
		'/Lookup',
		user?.ContentId,
		currentUser?.CustomerRole?.toLowerCase() !== CUSTOMER_ROLE.CASTING_DIRECTOR,
	)

	const { data: videosData = [] } = useSwrImmutable<Content[]>(
		user?.Actions?.RelationshipCustomerBestPerformance
			? user?.Actions?.RelationshipCustomerBestPerformance
			: null,
		collectionFetcher,
		{
			shouldRetryOnError: false,
		},
	)
	const { data: imagesData = [] } = useSwrImmutable<Content[]>(
		user?.Actions?.RelationshipCustomerImage
			? user?.Actions?.RelationshipCustomerImage
			: null,
		collectionFetcher,
		{
			shouldRetryOnError: false,
		},
	)

	const images = useMemo(() => {
		const pinned = getPinnedMedia('CustomerPhotoPinned')
		if (pinned) {
			return imagesData
				?.map((content) => ({
					...content,
					pinned: (pinned[content?.relationIndex || -1] || 100)?.toString(),
				}))
				?.sort((a, b) => {
					if (+a.pinned > +b.pinned) return 1
					else return -1
				})
		} else return imagesData
	}, [imagesData])

	const videos = useMemo(() => {
		const pinned = getPinnedMedia('CustomerShowreelPinned')
		if (pinned) {
			return videosData
				?.map((content) => ({
					...content,
					pinned: (pinned[content?.relationIndex || -1] || 100)?.toString(),
				}))
				?.sort((a, b) => {
					if (+a.pinned > +b.pinned) return 1
					else return -1
				})
		} else return videosData
	}, [videosData])

	useEffect(() => {
		const mediaItems = images?.concat(videos)
		if (activeAttribute && mediaItems?.length) {
			const { attrubuteContents } = useUserStore.getState()

			if (attrubuteContents?.length) {
				const { setModal } = useModalStore.getState()

				setModal({
					show: true,
					children: (
						<MediaModal
							onClose={() => {
								setActiveAttribute('', null)
								setModal(null)
							}}
							mediaItems={attrubuteContents}
							itemIndex={0}
						/>
					),
				})
			}
		}
	}, [activeAttribute, images, setActiveAttribute, videos])

	const skills = useMemo(
		() =>
			[...Object.values(skillFields)]
				.filter((field) => field.selectors?.length > 1)
				.map((field) => {
					const values = JSON.parse(
						(user?.[
							field.selectors[0]?.name?.replaceAll(':', '') as keyof User
						] as string) || '[]',
					) as ValueLevel[]

					const valuesOptions = optionsParser(
						field?.selectors?.[0]?.optionName || '',
					)
					const levelsOptions = optionsParser(
						field?.selectors?.[1]?.optionName || '',
					)

					return values?.map((value) => {
						const levelIndex = levelsOptions?.findIndex(
							(option) => option.value === value?.Level,
						)

						return {
							value,
							label: field.value,
							levelIndex,
							levelsOptions,
							valuesOptions,
						}
					})
				})
				?.flat(2)
				.filter(
					(v) =>
						!Array.isArray(v?.value) ||
						(Array.isArray(v?.value) &&
							Array.isArray(v?.value?.[0]) &&
							v?.value?.[0]?.length),
				)
				?.sort((a, b) => {
					if (a.levelIndex > b.levelIndex) return -1
					else return 1
				}),
		[user],
	)

	const tabs = useMemo(
		() => [
			{
				value: 0,
				label: 'APP_SKILLS_TAB',
				visible: !!skills?.length,
			},
			{
				value: 1,
				label: 'APP_PERSONAL_TAB',
				visible: user?.CustomUserBiography,
			},
			{
				value: 2,
				label:
					user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.AGENCY
						? 'APP_COLLABORATION_TAB'
						: 'APP_ROLES_TAB',
				visible: !!accessRelations?.length,
			},
		],
		[user, skills?.length, accessRelations?.length],
	)

	useEffect(() => {
		setActiveTab(0)
		swiperRef?.current?.slideTo(0)
	}, [user?.ContentId])

	return (
		<div className={cn(s.container, s[place])}>
			<div className={s.wrapper}>
				<Header user={user} place={place} />
				{images?.length ? (
					<MediaWrapper type="photos" data={images} place={place} />
				) : null}
				{videos?.length ? (
					<MediaWrapper type="reelz" data={videos} place={place} />
				) : null}
				<SidebarTabs
					type="fit"
					options={tabs
						?.filter((t) => t.visible)
						?.map((v, index) => ({
							...v,
							value: index,
						}))}
					active={activeTab}
					onChange={(t) => {
						setActiveTab(t as number)
						swiperRef.current?.slideTo(t as number)
					}}
				/>
				<Swiper
					spaceBetween={100}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					allowTouchMove={false}
					style={{ width: '100%', overflow: 'visible' }}>
					{tabs?.[0]?.visible ? (
						<SwiperSlide>
							{user ? (
								<Skills
									user={user}
									place={place}
									skills={skills}
									mediaItems={images?.concat(videos)}
								/>
							) : (
								<></>
							)}
						</SwiperSlide>
					) : null}
					{tabs?.[1]?.visible ? (
						<SwiperSlide>
							<Personal
								user={user}
								place={place}
								mediaItems={images?.concat(videos)}
							/>
						</SwiperSlide>
					) : null}
					{tabs?.[2]?.visible ? (
						<SwiperSlide>
							{user ? (
								<Audition place={place} relations={accessRelations} />
							) : (
								<></>
							)}
						</SwiperSlide>
					) : null}
				</Swiper>
			</div>
		</div>
	)
}
export default ProfileLayout
