import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ShareIcon } from 'assets/icons'
import ProductionCard from 'components/ProductionCard'
import RoleCard from 'components/RoleCard'
import Meta from 'components/Meta'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { PATHS } from 'types/enums'

const PublicShare = (): JSX.Element => {
	const { contentId } = useParams()
	const navigate = useNavigate()
	const { search } = useLocation()

	const type = new URLSearchParams(search).get('type')

	const { content, isLoading } = useContent(contentId)

	return content ? (
		<>
			<Meta title={content?.['Globalizationen-USContentTitle']}>
				<meta
					name="og:description"
					content={content?.['Globalizationen-USContentDescription']}
				/>
				<meta
					name="og:url"
					content={window.location.href + window.location.pathname}
				/>
				<meta name="og:image" content={content?.ContentSquareImageUrl} />
				<meta name="og:image:width" content="300" />
				<meta name="og:image:height" content="300" />
				<meta name="og:image:type" content="image/jpeg" />
				<meta property="og:type" content="website" />
			</Meta>
			{type === 'role' ? (
				<RoleCard
					content={content}
					type="aside"
					style={{
						left: '50%',
						transform: 'translateX(-50%)',
					}}
					// showDescription
					showData
					children={
						<>
							<div
								className="hoverButtonWrapper"
								onClick={() => {
									navigate(
										`/${PATHS.JOIN}/${PATHS.LOGIN}${window.location.search}`,
									)
								}}>
								<ShareIcon />
								<span>{t('APP_APPLY_HOVER_LABEL')}</span>
							</div>
						</>
					}
				/>
			) : (
				<ProductionCard
					content={content}
					showDescription
					type="aside"
					children={
						<>
							<div
								className="hoverButtonWrapper"
								onClick={() => {
									navigate(
										`/${PATHS.JOIN}/${PATHS.LOGIN}${window.location.search}`,
									)
								}}>
								<ShareIcon />
								<span>{t('APP_APPLY_HOVER_LABEL')}</span>
							</div>
						</>
					}
					style={{
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				/>
			)}
		</>
	) : isLoading ? (
		<></>
	) : (
		<Navigate
			to={`/${PATHS.JOIN}/${PATHS.LOGIN}${window.location.search}`}
			replace
		/>
	)
}

export default PublicShare
