import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { SearchIcon } from 'assets/icons'
import { addRelation, deleteRelation } from 'api/relations'
import { updateUser } from 'api/user'
import NetworkCard from 'components/NetworkCard'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'
import InputLabel from 'components/UI/InputLabel'
import { t, textParser } from 'helpers'
import { getUser } from 'helpers/storage'
import { notif } from 'helpers/notif'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import {
	useAppStore,
	useModalStore,
	usePortfolioStore,
	useUserStore,
} from 'store'
import { PATHS, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import { contactFields } from 'pages/Portfolio/fields'

const AgencySidebar = (): JSX.Element => {
	const [currentAgencyId, status, access] = useUserStore(
		useShallow((state) => [
			state.user?.CustomerAgencyId,
			state.user?.CustomerAgencyStatus,
			state.user?.CustomerAgencyAccess,
		]),
	)
	const [modalOpened] = useModalStore(
		useShallow((state) => [state.modal?.show]),
	)
	const addAgencyOpened = useAppStore(
		useShallow((state) => state.addUserContactSidebar.open),
	)
	const [openSidebar, setOpenSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.openAgencySidebar,
			state.setOpenAgencySidebar,
		]),
	)
	const [searchFilter, setSearchFilter] = useState('')

	const { data = [] } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
		type: 'Company',
	})

	const onAddClick = (user: User): void => {
		const { setModal } = useModalStore.getState()

		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_SENDING_INVITE_TO_AGENCY')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={async () => {
									try {
										await addRelation({
											relation: RELATIONS.GROUP_INVITE,
											type: 'Customer',
											targetId: user?.ContentId,
											sourceId: getUser()?.['Custom:User:Id'] || '',
											status: 'Invited',
											query: `?emailTemplateId=GenericMessage&subject=${t(
												'APP_MESSAGE_REQUEST_ACTOR_TO_AGENCY_SUBJECT',
											)}&message=${encodeURIComponent(
												textParser(
													t('APP_MESSAGE_REQUEST_ACTOR_TO_AGENCY_BODY'),
												)
													.join('<br />')
													.replace('[RecipientName]', user?.ContentTitle)
													.replace(
														'[YourPublicPortfolio]',
														window.location.href +
															'/' +
															PATHS.PROFILE?.replace(
																':userId',
																getUser()?.['Custom:User:Name'] || '',
															),
													)
													.replace(
														'[YourName]',
														getUser()?.['Custom:User:Name'] || '',
													),
											)}`,
										})
										updateUser(getUser()?.['Custom:User:Id'] || '', [
											{
												Name: contactFields.agency.name,
												Value: user?.ContentId,
											},
											{
												Name: 'Customer:Agency:Status',
												Value: 'Pending',
											},
										])
										const { refreshUser } = useUserStore.getState()
										refreshUser()
										setModal(null)
										setOpenSidebar(false)
										notif('success', t('APP_INVITE_SENT'))
									} catch (error) {
										console.log('error:', error)
										notif('error', t('APP_SOMETHING_WENT_WRONG'))
									}
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}
	const onRemoveClick = (agency: User): void => {
		const { setModal } = useModalStore.getState()

		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_REMOVING_AGENCY')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={async () => {
									try {
										await deleteRelation(
											getUser()?.['Custom:User:Id'] || '',
											'Customer',
											RELATIONS.GROUP_INVITE,
											agency?.ContentId,
										)
										if (agency?.CustomerPoolDefault)
											await deleteRelation(
												getUser()?.['Custom:User:Id'] || '',
												'Customer',
												RELATIONS.ORGANIZATION,
												agency?.CustomerPoolDefault,
											)
										updateUser(getUser()?.['Custom:User:Id'] || '', [
											{
												Name: contactFields.agency.name,
												Value: '',
											},
											{
												Name: 'Customer:Agency:Status',
												Value: '',
											},
										])
										const { refreshUser } = useUserStore.getState()
										refreshUser()
										setModal(null)
										setOpenSidebar(false)
										notif('success', t('APP_AGENCY_REMOVED'))
									} catch (error) {
										console.log('error:', error)
										notif('error', t('APP_SOMETHING_WENT_WRONG'))
									}
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	const currentAgency = data?.find(
		(user) => user?.ContentId === currentAgencyId,
	)

	const changeAccess = async (): Promise<void> => {
		try {
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Agency:Access',
					Value: access === 'true' ? 'false' : 'true',
				},
			])
			useUserStore.getState().refreshUser()
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			iconClose={modalOpened || addAgencyOpened}
			header={{
				title: t('APP_AGENCY_SIDEBAR_TITLE'),
			}}
			buttons={[
				{
					children: t(
						currentAgencyId ? 'APP_REMOVE_LABEL' : 'APP_ADD_AGENCY_BUTTON',
					),
					onClick: () => {
						if (currentAgency) onRemoveClick(currentAgency)
						else {
							const { setUserContactSidebar } = useAppStore.getState()
							setUserContactSidebar({
								open: true,
								onAdd: async (user) => {
									const { setNewAgency } = usePortfolioStore.getState()
									setNewAgency(user)
									setOpenSidebar(false)
								},
							})
						}
					},
				},
			]}>
			<>
				{currentAgency ? (
					<>
						<NetworkCard data={currentAgency} style={{ maxHeight: '200px' }} />

						<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							<span className="body1-b">
								{t('APP_AGENCY_STATUS_LABEL') + ': '}
							</span>
							<span
								style={{
									color:
										status === 'Pending' ? 'var(--warning)' : 'var(--success)',
								}}>
								{status}
							</span>
						</div>
						<div style={{ gridColumn: 'span 2' }}>
							<InputLabel
								label="APP_GRANT_ACCESS_TO_AGENCY_LABEL"
								status={access === 'true' ? 'filled' : 'none'}
							/>
							<CheckboxButton
								checked={access === 'true'}
								label="APP_GRANT_ACCESS_TO_AGENCY_CHECKBOX"
								onChange={changeAccess}
							/>
						</div>
					</>
				) : (
					<>
						<Input
							variant="default"
							name="filterSearch"
							placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
							value={searchFilter}
							onChange={(e) => setSearchFilter(e.target.value)}
							endAdornment={<SearchIcon color="var(--mono300)" />}
						/>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '20px',
							}}>
							{data?.map((user) => (
								<NetworkCard
									style={{
										width: '100%',
									}}
									variant="small"
									key={user?.ContentId}
									data={user}
									onClick={() => onAddClick(user)}
								/>
							))}
						</div>
					</>
				)}
			</>
		</Sidebar>
	)
}

export default AgencySidebar
