import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { t } from 'helpers'
import { useUserStore } from 'store'
import { PATHS } from 'types/enums'
import { settingsSteps } from 'pages/Settings/Settings'
import { PROFILE_VALIDATION_STATUS } from 'types/enums'
import s from './index.module.scss'

interface Props {
	profileStatusLabel: string
}

const Info = ({ profileStatusLabel }: Props): JSX.Element => {
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))

	return (
		<div className="grid3column" style={{ paddingRight: '15px' }}>
			<div className={s.statuses}>
				<span className="body1-b">{t('APP_PORTFOLIO_STATUS_LABEL')}</span>
				<span>{t(profileStatusLabel)}</span>
			</div>
			<div className={s.statuses}>
				<span className="body1-b">{t('APP_PROFILE_STATUS_LABEL')}</span>
				<span>{user?.CustomerProcessStatus}</span>
			</div>
			<div className={s.statuses}>
				<span className="body1-b">
					{t('APP_PROFILE_VALIDATION_STATUS_LABEL')}
				</span>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<span>{user?.CustomerValidateStatus}</span>
					{[
						PROFILE_VALIDATION_STATUS.OPENED,
						PROFILE_VALIDATION_STATUS.STARTED,
					].includes(
						user?.CustomerValidateStatus as PROFILE_VALIDATION_STATUS,
					) ? (
						<span
							onClick={() => {
								navigate(
									`/${PATHS.SETTINGS}#${settingsSteps?.validation?.key}-0`,
								)
							}}
							style={{ cursor: 'pointer', color: 'var(--theme-primary)' }}>
							{t('APP_VALIDATE_PROFILE')}
						</span>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Info
