import { useNavigate } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t } from 'helpers'
import { useNetworkStore } from 'store'
import { User } from 'types/user'
import { RELATIONS } from 'types/enums'

const Recent = (): JSX.Element => {
	const navigate = useNavigate()
	const { data } = useRelationsContent(
		[RELATIONS.FAVCUSTOMER],
		undefined,
		'Customer',
		'?reverse=true&orderBy=UpdatedDate&itemLimit=10',
	)

	const users = data?.[RELATIONS.FAVCUSTOMER]
		? (data?.[RELATIONS.FAVCUSTOMER] as unknown as User[])
		: undefined

	const onUserClick = async (user: User, users: User[]): Promise<void> => {
		const { setNetworkRedirectionLink } = useNetworkStore.getState()
		setNetworkRedirectionLink(window.location.pathname + window.location.hash)
		const { userProfile, setUserProfile } = useNetworkStore.getState()
		const path = await userProfile.onUserChange(user)
		navigate(path)
		setUserProfile({ users, user, listSource: 'APP_RECENT_LIST_TITLE' })
	}

	return users?.length ? (
		<>
			<RecentCarousel name="mynetworkrecent">
				<>
					{users?.map((user) => (
						<SwiperSlide key={user.ContentId}>
							<NetworkCard
								data={user}
								variant="small"
								onClick={() => onUserClick(user, users)}
							/>
						</SwiperSlide>
					))}
				</>
			</RecentCarousel>
		</>
	) : (
		<span>{t('APP_RESULT_NOT_FOUND')}</span>
	)
}

export default Recent
