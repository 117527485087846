import { Outlet } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import Header from './components/Header'
import Footer from './components/Footer'
import Nav from './components/Nav'
import GlobalModal from './components/GlobalModal'
import BlurBackground from './components/BlurBackground'
import MobileBlockModal from './components/MobileBlockModal'
import Confetti from './components/Confetti'
import NotificationSidebar from './sidebars/NotificationSidebar'
import FilterSidebar from './sidebars/FilterSidebar'
import QRCodeSidebar from './sidebars/QRCodeSidebar'
import RoleInfoSidebar from './sidebars/RoleInfoSidebar'
import ProductionInfoSidebar from './sidebars/ProductionInfoSidebar'
import LocalSearchSidebar from './sidebars/LocalSearchSidebar'
import UserInfoSidebar from './sidebars/UserInfoSidebar'
import DocumentViewerSidebar from './sidebars/DocumentViewerSidebar'
import FeedbackSidebar from './sidebars/FeedbackSidebar'
import AddSceneSidebar from './sidebars/AddSceneSidebar'
import MessageSidebar from './sidebars/MessageSidebar'
import AddContactSidebar from './sidebars/AddContactSidebar'
import AddUserSidebar from './sidebars/AddUserSidebar'
import useContent from 'hooks/api/useContent'
import { useFilterStore, useUserStore } from 'store'
import s from './index.module.scss'

const Layout = (): JSX.Element => {
	const user = useUserStore(useShallow((state) => state.user))
	// const { content } = useContent(user?.CustomerChatId)
	const openFilter = useFilterStore(
		useShallow((state) => state.openFilterSidebar),
	)

	return (
		<>
			<BlurBackground />
			<Header />
			<div className={cn(s.wrapper, { [s.reduceWidth]: openFilter })}>
				<Nav />
				{/* <main className={s.main}> */}
				<Outlet />
				{/* </main> */}
			</div>
			<Footer />
			{/* {content ? <NotificationSidebar chatData={content} /> : null} */}
			{user ? (
				<>
					<FilterSidebar />
					<MessageSidebar />
					<RoleInfoSidebar />
					<ProductionInfoSidebar />
					<UserInfoSidebar />
					<QRCodeSidebar />
					<LocalSearchSidebar />
					<DocumentViewerSidebar />
					<FeedbackSidebar />
					<AddSceneSidebar />
					<AddContactSidebar />
					<AddUserSidebar />
				</>
			) : null}
			<GlobalModal />
			<Confetti />
			<MobileBlockModal />
		</>
	)
}

export default Layout
