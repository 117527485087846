import { useShallow } from 'zustand/react/shallow'
import { MuteIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import { usePlayerStore } from 'store'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Mute = ({ videoElement }: Props): JSX.Element => {
	const [muted, setValue] = usePlayerStore(
		useShallow((state) => [state.muted, state.setValue]),
	)

	const onMute = (): void => {
		if (videoElement) videoElement.muted = !muted
		setValue({ muted: !muted })
	}

	return (
		<Tooltip title={muted ? 'APP_UNMUTE_CONTROL' : 'APP_MUTE_CONTROL'}>
			<div className={s.control} onClick={onMute}>
				<MuteIcon
					color={muted ? 'var(--mono100) !important' : 'var(--mono300)'}
				/>
			</div>
		</Tooltip>
	)
}

export default Mute
