import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { SearchIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import CDList from './components/CDList'
import AgencyList from './components/AgencyLIst'
import { prepareMessageData, t } from 'helpers'
import { useNetworkStore, useUserStore } from 'store'
import { Content } from 'types/app'
import { MESSAGE_TYPE } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'

const InviteToPoolSidebar = (): JSX.Element => {
	const role = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)
	const navigate = useNavigate()
	const { profileId } = useParams()
	const [roleSearch, setRoleSearch] = useState('')
	const [openSidebar, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.openInviteUserSidebar,
			state.setOpenInviteUserSidebar,
		]),
	)

	const openMessage = async (
		content: Content,
		production?: Content,
	): Promise<void> => {
		const { user } = useUserStore.getState()

		const data = {
			userIds: [profileId || ''],
			sourceId: content?.ContentId,
			key: 'ROLEINVITE',
			messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
				?.replace(
					'[RoleName]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[ProductionTitle]',
					production?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionTitle]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionDate]',
					content?.ContentEventDeadline
						? dayjs(content?.ContentEventDeadline || '').format('MM/DD/YYYY')
						: 'N/A',
				)
				?.replace(
					'[AuditionNDA]',
					production?.ContentLegalNDARequired === 'true' ? 'Yes' : 'No',
				)
				?.replace('[YourName]', user?.ContentTitle || ''),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})

		const { setOpenInviteUserSidebar } = useNetworkStore.getState()
		setOpenInviteUserSidebar(false)
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_NETWORK_INVITE_TO_POOL_SIDEBAR_TITLE',
				description: 'APP_NETWORK_INVITE_TO_POOL_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					<Input
						variant="default"
						name="filterSearch"
						placeholder={t('APP_SEARCH_ROLE_FILTER_PLACEHOLDER')}
						value={roleSearch}
						onChange={(e) => {
							setRoleSearch(e.target.value)
						}}
						endAdornment={<SearchIcon color="var(--mono300)" />}
					/>
					{openSidebar ? (
						role === CUSTOMER_ROLE.AGENCY ? (
							<AgencyList roleSearch={roleSearch} openMessage={openMessage} />
						) : (
							<CDList roleSearch={roleSearch} openMessage={openMessage} />
						)
					) : null}
				</div>
			</>
		</Sidebar>
	)
}

export default InviteToPoolSidebar
