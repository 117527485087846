import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import NetworkDashboard from './components/NetworkDashboard'
import ActorNetworkDashboard from './components/ActorNetworkDashboard'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'
import { DASHBOARD_SECTIONS } from 'types/enums'

const Network = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const component = useMemo(() => {
		const components = {
			[CUSTOMER_ROLE.ACTOR]: <ActorNetworkDashboard />,
			[CUSTOMER_ROLE.AGENCY]: <NetworkDashboard />,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: <NetworkDashboard />,
			[CUSTOMER_ROLE.CONTRIBUTOR]: <></>,
		}

		return userRole ? components[userRole as CUSTOMER_ROLE] : null
	}, [userRole])

	return (
		<div className={s.container} id={DASHBOARD_SECTIONS.NETWORK}>
			{component}
		</div>
	)
}

export default Network
