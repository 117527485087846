import classNames from 'classnames'
import { useMemo } from 'react'
import { HexagonIcon, InfoFilledIcon } from 'assets/icons'
import { capitalize, getTaggedContent, t } from 'helpers'
import { useUserStore } from 'store'
import { Content, SelectOption, ValueLevel } from 'types/app'
import s from './index.module.scss'

interface Props {
	value: ValueLevel
	levelIndex: number
	label: string
	valuesOptions: SelectOption[]
	levelsOptions: SelectOption[]
	mediaItems: Content[]
}

const SkillItem = ({
	value,
	levelIndex,
	levelsOptions,
	label,
	valuesOptions,
	mediaItems,
}: Props): JSX.Element => {
	const attribute = `${capitalize(label)}:${value?.Value}`

	const selected = useMemo(
		() => mediaItems?.filter((content) => getTaggedContent(attribute, content)),
		[mediaItems, attribute],
	)

	return (
		<div className={classNames(s.skill, 'hoverWrapperParent')}>
			{selected?.length ? (
				<div
					className="hoverWrapper"
					style={{
						clipPath:
							'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
						height: '100%',
						width: '95%',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}>
					<div className="hoverContent">
						<>
							<div
								className="hoverButtonWrapper"
								onClick={() => {
									const { setActiveAttribute } = useUserStore.getState()
									setActiveAttribute(attribute, selected)
								}}>
								<InfoFilledIcon />
								<span style={{ whiteSpace: 'break-spaces' }}>
									{t('APP_SHOW_ASSIGNED')}
								</span>
							</div>
						</>
					</div>
				</div>
			) : null}
			<HexagonIcon
				style={{
					zIndex: '1',
				}}
				width="106"
				height="116"
				color={
					levelIndex < 4 ? '#C0C0C0' : levelIndex < 7 ? '#0A43EC' : '#FFD700'
				}
			/>
			{selected?.length ? (
				<div className={s.hexAnimWrapper}>
					<HexagonIcon
						width="118"
						height="128"
						color={
							levelIndex < 4
								? '#C0C0C0'
								: levelIndex < 7
								? '#0A43EC'
								: '#FFD700'
						}
					/>
				</div>
			) : null}
			<div className={s.wrapper}>
				<span className="caption-r textClamp" style={{ WebkitLineClamp: '2' }}>
					{t(`APP_HEX_${label.toUpperCase()}`)}
				</span>
				<span className={s.value}>
					{
						valuesOptions?.find((option) => option.value === value?.Value)
							?.label
					}
				</span>
				<span></span>
				<span
					className="caption-r textClamp"
					style={{ WebkitLineClamp: '2', color: 'var(--mono300)' }}>
					{
						levelsOptions?.find((option) => option.value === value?.Level)
							?.label
					}
				</span>
			</div>
		</div>
	)
}

export default SkillItem
