import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { deleteRelation, getRelations } from 'api/relations'
import { contentDelete, getContent, getPlaylistItems } from 'api/content'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import List from './components/List'
import { wait } from 'helpers'
import contentParser from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { Content, Relation, SidebarTabsOptions } from 'types/app'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import s from '../Info/index.module.scss'

interface Props {
	options: ({
		values?: string[]
	} & SidebarTabsOptions)[]
	relations?: Relation[]
	users: User[]
	setLoading: (loading: boolean) => void
}

const CastingUsers = ({
	options,
	relations,
	users,
	setLoading,
}: Props): JSX.Element => {
	const [activeTab, setActiveTab] = useState(options?.[0].value)
	const swiperRef = useRef<SwiperClass>()

	const deleteCasting = async (
		user: User,
		relation: Relation,
	): Promise<void> => {
		setLoading(true)
		try {
			const relations = await getRelations(
				`${relation.sourceId}_Content_Access`,
				RELATIONS.RELATIONSHIP,
				RELATIONS.CONTENT,
			)
			const contents = await Promise.all(
				relations?.pageContent?.map((rel) => getContent(rel.targetId)),
			).then((res) => res?.map((data) => contentParser(data)))

			const playlist = contents?.find(
				(content) => content.ContentOwnerId === user?.ContentId,
			)
			const playlistId = playlist?.ContentId

			const playlistItems = await getPlaylistItems({
				ContentId: playlistId,
			} as Content)

			const chatRelations = await getRelations(
				playlistId || '',
				'Content',
				RELATIONS.CHAT,
			)
			const voiceChatId = chatRelations?.pageContent?.[0]?.targetId

			const references = await getRelations(
				voiceChatId,
				'Content',
				RELATIONS.REFERENCE,
			)
			const textChatId = references?.pageContent?.[0]?.targetId

			await Promise.all(
				playlistItems?.pageContent?.map((rel) => contentDelete(rel.targetId)),
			)
			await contentDelete(voiceChatId)
			await contentDelete(textChatId)

			if (playlistId) {
				await contentDelete(playlistId)
				await deleteRelation(playlistId, 'Content', RELATIONS.CHAT, voiceChatId)
				await deleteRelation(
					`${relation.sourceId}_Content_Access`,
					RELATIONS.RELATIONSHIP,
					RELATIONS.CONTENT,
					playlistId,
				)
			}
			await deleteRelation(
				relation.sourceId,
				relation.type as 'Content',
				relation.relation as RELATIONS,
				relation.targetId,
			)
			EventBus.$emit('refreshCDboard')
			await wait(1000)
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		return () => {
			setActiveTab(options?.[0].value)
			swiperRef.current?.slideTo(0)
		}
	}, [options])

	return (
		<>
			<SidebarTabs
				type="fit"
				active={activeTab}
				onChange={(v) => {
					setActiveTab(v as CASTING_STATUS)
					swiperRef.current?.slideTo(
						options?.findIndex((opt) => opt.value === v),
					)
				}}
				options={options}
				activeClass={s.activeTab}
			/>
			<Swiper
				allowTouchMove={false}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				style={{ width: '100%' }}>
				{options?.map((option) => (
					<SwiperSlide key={option.value}>
						<List
							relations={relations?.filter((rel) =>
								option?.values?.length
									? option.values.includes(rel.status as string)
									: rel.status === option.value,
							)}
							users={users}
							deleteCasting={deleteCasting}
							optionRelations={option.values}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	)
}

export default CastingUsers
