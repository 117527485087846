import { CircleFillIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useAppStore } from 'store'
import s from '../../index.module.scss'

const Goals = (): JSX.Element => {
	const iconProps = {
		width: '110',
		height: '110',
		strokeFilledWidth: '8',
		strokeDefaultWidth: '4',
		colorDefault: 'var(--elev500)',
	}

	return (
		<Card
			step="onboarding_goals"
			noHint
			noTitle
			style={{
				padding: '0',
			}}>
			<div className={s.onboardingWrapper}>
				<div className={s.contentWrapper}>
					<div className={s.titles}>
						<h1 className="h1-m">{t('APP_ONBOARDING_GOALS_TITLE')}</h1>
						<span className="body1-m">{t('APP_ONBOARDING_GOALS_DESC')}</span>
					</div>
					<div className="grid3column">
						<div className={s.expItem}>
							<span className="body1-b" style={{ color: 'var(--error)' }}>
								{'< 60%'}
							</span>
							<div>
								<CircleFillIcon
									{...iconProps}
									colorFilled="var(--error)"
									strokeFilledPercents="30"
								/>
							</div>
							<span>{t('APP_EXPLANATION_60')}</span>
						</div>
						<div className={s.expItem}>
							<span className="body1-b" style={{ color: 'var(--warning)' }}>
								{'60 - 90%'}
							</span>
							<div>
								<CircleFillIcon
									{...iconProps}
									colorFilled="var(--warning)"
									strokeFilledPercents="70"
								/>
							</div>
							<span>{t('APP_EXPLANATION_6090')}</span>
						</div>
						<div className={s.expItem}>
							<span className="body1-b" style={{ color: 'var(--success)' }}>
								{'> 90%'}
							</span>
							<div>
								<CircleFillIcon
									{...iconProps}
									colorFilled="var(--success)"
									strokeFilledPercents="100"
								/>
							</div>
							<span>{t('APP_EXPLANATION_90')}</span>
						</div>
					</div>
					<div style={{ maxWidth: '300px', width: '100%' }}>
						<Button
							variant="themed"
							style={{ width: '100%' }}
							onClick={() => {
								const { stepWizard } = useAppStore.getState()
								stepWizard?.nextStep()
							}}>
							{t('APP_NEXT')}
						</Button>
					</div>
				</div>
				<div className={s.imageWrapper}>
					<img src="/card-bg.png" />
				</div>
			</div>
		</Card>
	)
}

export default Goals
