import { useNavigate } from 'react-router-dom'
import Image from 'assets/images/home-ready.png'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const Ready = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<section className={s.container}>
			<div className={s.card}>
				<div className={s.wrapper}>
					<h3 style={{ fontWeight: '500' }}>{t('APP_READY_TO_GO_TITLE')}</h3>
					<span style={{ color: 'var(--mono300)', fontWeight: '500' }}>
						{t('APP_READY_TO_GO_DESCRIPTION')}
					</span>
					<Button
						onClick={() => {
							navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
						}}
						variant="outlined"
						style={{
							gap: '10px',
							color: 'var(--mono900)',
							borderColor: 'var(--theme-primary)',
							backgroundColor: 'var(--theme-primary)',
						}}>
						{t('APP_JOINFREE_LABEL')}
					</Button>
				</div>
			</div>
			<img src={Image} alt="ready-home" />
		</section>
	)
}

export default Ready
