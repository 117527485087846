import { useEffect, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { CheckIcon } from 'assets/icons'
import SceneCard from 'components/SceneCard'
import useContentRelation from 'hooks/api/useContentRelation'
import { useCastingStore, useUserStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { Content, Relation } from 'types/app'

interface Props {
	scene: Content
	uploadedScenes?: Content[]
	relation?: Relation
}

const SceneItem = ({ scene, uploadedScenes, relation }: Props): JSX.Element => {
	const [uploadedSceneIds] = useCastingStore(
		useShallow((state) => [state.uploadedSceneIds]),
	)
	const sceneSidebarOpened = useCastingStore(
		useShallow((state) => !!state.sceneUploadContent),
	)

	const { contents, refresh } = useContentRelation(
		scene?.ContentId,
		RELATIONS.PLAYLIST,
	)

	const getUploadedContent = (): Content | undefined => {
		const { userId } = useUserStore.getState()
		const allIds = contents
			?.filter((c) => c.ContentOwnerId === userId)
			?.map((c) => c.ContentId)
		const content = uploadedScenes?.find((content) =>
			allIds?.includes(content.ContentId),
		)

		return content
	}

	const reuploadContent = useMemo(() => {
		const { userId } = useUserStore.getState()
		const allIds = contents
			?.filter((c) => c.ContentOwnerId === userId)
			?.map((c) => c.ContentId)
		const content = uploadedScenes?.find((content) =>
			allIds?.includes(content.ContentId),
		)

		return content?.ContentCastingReupload === 'true'
	}, [contents, uploadedScenes])

	const onSceneClick = (content: Content): void => {
		const { setSceneUploadContent } = useCastingStore.getState()
		const upl = getUploadedContent()
		setSceneUploadContent(content, upl)
	}

	useEffect(() => {
		if (!uploadedSceneIds?.map((v) => v.id)?.includes(scene?.ContentId)) {
			const { userId } = useUserStore.getState()
			const allIds = contents
				?.filter((c) => c.ContentOwnerId === userId)
				?.map((c) => c.ContentId)

			const content = uploadedScenes?.find((c) => allIds?.includes(c.ContentId))
			if (content) {
				const { setUploadedSceneIds } = useCastingStore.getState()
				setUploadedSceneIds(
					scene?.ContentId,
					content.ContentDuration
						? +(+content.ContentDuration / 1000).toFixed(0)
						: 0,
				)
			}
		}
	}, [contents, scene?.ContentId, uploadedScenes, uploadedSceneIds])

	const uploaded = uploadedSceneIds?.find((c) => c.id === scene.ContentId)

	useEffect(() => {
		if (!sceneSidebarOpened) {
			setTimeout(() => {
				refresh()
			}, 1000)
		}
	}, [sceneSidebarOpened, refresh])

	const style = {
		maxWidth: 'unset',
		minHeight: '100px',
		border: '2px solid transparent',
	}

	return (
		<div style={{ position: 'relative' }}>
			{uploaded || reuploadContent ? (
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						zIndex: 1,
						top: '12px',
						right: '15px',
					}}>
					{reuploadContent ? (
						<span
							className="body1-b"
							style={{ color: 'var(--theme-primary)', marginRight: '10px' }}>
							!
						</span>
					) : (
						<CheckIcon color="var(--success)" />
					)}
				</div>
			) : null}
			<SceneCard
				onClick={() => {
					if (relation?.status !== CASTING_STATUS.REAPPLIED || reuploadContent)
						onSceneClick(scene)
				}}
				content={scene}
				uploaded={
					uploaded
						? ({
								ContentId: uploaded.id,
								ContentDuration: (uploaded.duration * 1000).toString(),
						  } as Content)
						: undefined
				}
				style={
					uploaded || reuploadContent
						? {
								...style,
								border: reuploadContent
									? '2px solid var(--theme-primary)'
									: '2px solid var(--success)',
						  }
						: style
				}
			/>
		</div>
	)
}

export default SceneItem
