import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import { getContent } from 'api/content'
import Item from '../Item'
import { prepareMessageData, t } from 'helpers'
import { getUser } from 'helpers/storage'
import { propertiesParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { Content, Relation } from 'types/app'
import { CASTING_STATUS, MESSAGE_TYPE } from 'types/enums'
import { User } from 'types/user'
import s from './index.module.scss'

interface Props {
	users: User[]
	relations?: Relation[]
	deleteCasting: (user: User, relation: Relation) => void
	optionRelations?: string[]
}

const List = ({
	users,
	relations,
	deleteCasting,
	optionRelations,
}: Props): JSX.Element => {
	const navigate = useNavigate()
	const [roleId] = useAppStore(useShallow((state) => [state.roleInfoSidebar]))

	const notifyOption = [CASTING_STATUS.FAVORITE, CASTING_STATUS.UPLOADING]

	const notifyUsers = (userIds: string[], status: CASTING_STATUS): void => {
		const { roleInfoSidebar, setRoleInfoSidebar } = useAppStore.getState()
		const types: {
			[key: string]: {
				messageType: MESSAGE_TYPE
				key: string
			}
		} = {
			[CASTING_STATUS.FAVORITE]: {
				messageType: MESSAGE_TYPE.NOTIFY_WINNER,
				key: 'NOTIFYWINNER',
			},
		}

		const type = types?.[status]

		const data = {
			userIds,
			sourceId: roleInfoSidebar,
			key: type?.key || 'NOTIFYUSERS',
			messageValue: encodeURIComponent(
				t(`APP_${type?.key || 'NOTIFYUSERS'}_MESSAGE_BODY`)?.replace(
					'[YourName]',
					getUser()?.['Custom:User:Name'] || '',
				),
			),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: type?.messageType || MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
		setRoleInfoSidebar('')
	}

	const inviteUsers = async (): Promise<void> => {
		try {
			const { setRoleInfoSidebar } = useAppStore.getState()
			setRoleInfoSidebar('')
			// const prod = await getContent(productionId || '')
			// const production = propertiesParser<Content>(prod?.Properties)
			const content = await getContent(roleId || '')
			const role = propertiesParser<Content>(content?.Properties)

			const data = {
				key: 'ROLEINVITE',
				messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
					?.replace(
						'[RoleName]',
						role?.['Globalizationen-USContentTitle'] || '',
					)
					?.replace('[ProductionTitle]', 'N/A')
					?.replace(
						'[AuditionTitle]',
						role?.['Globalizationen-USContentTitle'] || '',
					)
					?.replace(
						'[AuditionDate]',
						role?.ContentEventDeadline
							? dayjs(role?.ContentEventDeadline || '').format('MM/DD/YYYY')
							: 'N/A',
					)
					?.replace('[AuditionNDA]', 'N/A')
					?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
				sourceId: role.ContentId,
				redirect: window.location.pathname + window.location.hash,
				openSidebar: true,
				controlsProps: {
					prevText: 'APP_BACK',
					nextText: 'APP_SEND_NOTIF',
				},
				messageType: MESSAGE_TYPE.CASTING,
			}
			navigate(prepareMessageData(data), {
				replace: true,
			})
		} catch (error) {
			console.log('error:', error)
		}
	}

	return relations?.length ? (
		<>
			<div className={cn(s.container, 'hideScrollBar')}>
				{relations?.map((relation) => (
					<Item
						key={relation?.createdDate}
						deleteCasting={deleteCasting}
						user={users?.find((user) => user?.ContentId === relation.targetId)}
						relation={relation}
					/>
				))}
			</div>
			{optionRelations?.includes(CASTING_STATUS.INVITED) ? (
				<span onClick={inviteUsers} className={cn('body2-m', s.notify)}>
					{t('APP_INVITE_TO_ROLE')}
				</span>
			) : (
				<span
					onClick={() =>
						notifyUsers(
							relations?.map((rel) => rel.targetId),
							relations?.[0]?.status as CASTING_STATUS,
						)
					}
					className={cn('body2-m', s.notify)}>
					{notifyOption.includes(relations?.[0]?.status as CASTING_STATUS)
						? t('APP_NOTIFY_LABEL')
						: ''}
				</span>
			)}
		</>
	) : (
		<div style={{ minHeight: '87px' }}>
			<span>{t('APP_RESULT_NOT_FOUND')}</span>
		</div>
	)
}

export default List
