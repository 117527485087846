import { CSSProperties } from 'react'
import cn from 'classnames'
import { CorrectIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	checked: boolean
	onChange: () => void
	label: string
	hint?: string
	style?: CSSProperties
	onClick?: () => void
	containerClassName?: string
	labelStyle?: CSSProperties
	disabled?: boolean
}

const CheckboxButton = ({
	checked,
	onChange,
	label,
	hint,
	style,
	onClick,
	labelStyle,
	containerClassName,
	disabled,
}: Props): JSX.Element => (
	<>
		<div
			className={cn(s.container, containerClassName)}
			style={style}
			onClick={() => {
				if (disabled) return

				if (onClick) onClick()
			}}>
			<div
				className={cn(s.wrapper, { [s.checked]: checked })}
				onClick={() => {
					if (disabled) return

					onChange()
				}}>
				<CorrectIcon width="40" height="40" color="var(--theme-primary)" />
			</div>
			<div className={s.titles}>
				<span
					onClick={() => {
						if (disabled) return

						onChange()
					}}
					className="body2-b"
					style={{
						color: disabled
							? 'var(--mono400)'
							: checked
							? 'var(--mono100)'
							: 'var(--mono300)',
						...labelStyle,
					}}>
					{t(label)}
				</span>
				{hint ? (
					<span className="caption-m" style={{ color: 'var(--mono300)' }}>
						{hint}
					</span>
				) : null}
			</div>
		</div>
	</>
)

export default CheckboxButton
