import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import cn from 'classnames'
import { CircleIcon, InfoOutlinedIcon, LikeIcon, StarIcon } from 'assets/icons'
import ListWrapper from '../ListWrapper'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { optionsParser, t } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { CASTING_STATUS, DROPDOWN_OPTIONS, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import { PATHS } from 'types/enums'
import s from './index.module.scss'

const tabs = [
	{
		label: (
			<div
				style={{
					width: '24px',
					height: '24px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: 'auto',
				}}>
				<span
					style={{
						fontSize: '24px',
						marginBottom: '6px',
						transition: 'var(--transition)',
					}}>
					♔
				</span>
			</div>
		),
		value: CASTING_STATUS.SELECTED,
	},
	{
		label: <StarIcon color="var(--mono300)" />,
		value: CASTING_STATUS.FAVORITE,
	},
	{ label: <LikeIcon color="var(--mono300)" />, value: CASTING_STATUS.LIKED },
	{
		label: <CircleIcon color="var(--mono300)" />,
		value: CASTING_STATUS.NEUTRAL,
	},
	{
		label: (
			<LikeIcon
				color="var(--mono300)"
				style={{ transform: 'rotate(180deg)' }}
			/>
		),
		value: CASTING_STATUS.DISLIKED,
	},
]

const Candidates = (): JSX.Element => {
	const navigate = useNavigate()
	const { productionId, roleId, userId } = useParams()
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState('')
	const { data, refresh } = useRelations(
		RELATIONS.ACCESS,
		roleId || '',
		'Content',
	)

	const { users } = useUsers(
		data
			?.filter(
				(relation) =>
					relation.sourceId === roleId &&
					[
						CASTING_STATUS.APPLIED,
						CASTING_STATUS.FAVORITE,
						CASTING_STATUS.LIKED,
						CASTING_STATUS.NEUTRAL,
						CASTING_STATUS.DISLIKED,
						CASTING_STATUS.SELECTED,
						CASTING_STATUS.RESUBMITED,
					].includes(relation.status as CASTING_STATUS),
			)
			?.map((relation) => relation.targetId),
	)

	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const professionOptions = optionsParser(DROPDOWN_OPTIONS.PROFESSION)

	const onUserClick = (id: string): void => {
		const { setOpenCastingRoles } = useCastingStore.getState()
		setOpenCastingRoles(false)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${productionId}/${roleId}/${id}` + window.location.hash,
		)
	}

	const list = useMemo(
		() =>
			filterDuplicates(users || [], 'ContentId')
				?.filter((user) =>
					filter
						? data?.find(
								(relation) =>
									relation.status === filter &&
									user?.ContentId === relation.targetId,
						  )
						: user,
				)
				?.filter((user) =>
					user?.ContentTitle?.toLowerCase().includes(search.toLowerCase()),
				) as unknown as User[],
		[search, users, data, filter],
	)

	// useEffect(() => {
	// 	if (!userId && users?.length) {
	// 		const { user } = useUserStore.getState()
	// 		navigate(
	// 			`/${user?.CustomerRole?.toLowerCase()}/${
	// 				PATHS.CASTING
	// 			}/${productionId}/${roleId}/${users?.[0]?.ContentId}` +
	// 				window.location.hash,
	// 		)
	// 	}
	// }, [users, userId, navigate, roleId, productionId])

	useEffect(() => {
		EventBus.$on('refreshCastingCandidates', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshCastingCandidates')
		}
	}, [refresh])

	return (
		<ListWrapper
			type="users"
			users={list}
			tabs={tabs}
			filter={filter}
			setFilter={setFilter}
			setSearch={setSearch}>
			<>
				{list?.length ? (
					list?.map((user, index) => (
						<div
							className={cn(s.user, {
								[s.active]: userId === user.ContentId,
							})}
							key={user?.ContentId + index}>
							<div
								className={s.avatar}
								onClick={() => onUserClick(user.ContentId)}>
								<img
									src={user?.CustomerImageSquareUrl || '/placeholder.png'}
									alt={user?.ContentTitle}
								/>
							</div>
							<div
								className={s.info}
								onClick={() => onUserClick(user.ContentId)}>
								<span className="body1-m textEllipsis">
									{user?.ContentTitle}
								</span>
								<span className="body2-r" style={{ color: 'var(--mono200)' }}>
									{[
										professionOptions?.find(
											(option) => option.value === user?.CustomerProfession,
										)?.label,
										genderOptions?.find(
											(option) => option.value === user?.CustomUserGender,
										)?.label,
										dayjs().diff(dayjs(user?.CustomUserBirthDate), 'year'),
									]
										.filter((v) => !!v)
										.join(', ')}
								</span>
							</div>
							{userId === user.ContentId ? (
								<div
									onClick={() => {
										const { setUserInfoSidebar } = useAppStore.getState()
										setUserInfoSidebar(user.ContentId)
									}}
									style={{
										display: 'flex',
										cursor: 'pointer',
										marginLeft: 'auto',
									}}>
									<InfoOutlinedIcon />
								</div>
							) : null}
						</div>
					))
				) : (
					<span>{t('APP_RESULT_NOT_FOUND')}</span>
				)}
			</>
		</ListWrapper>
	)
}

export default Candidates
