import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import ColorSelectButton from 'components/UI/ColorSelectButton'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeSelector } from 'types/app'
import { attributeBasicFields } from 'pages/Portfolio/fields'

interface Props {
	selector: AttributeSelector
}

const ColorsSelector = ({ selector }: Props): JSX.Element => {
	const hairColorSelector =
		selector.name === attributeBasicFields.hair?.selectors?.[1]?.name

	const cardValues = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[state.modalType] && state.cardValues[state.modalType],
		),
	)

	const value = useMemo(() => {
		if (hairColorSelector) {
			const parsed = cardValues[selector.name]
				? typeof cardValues[selector.name] === 'string' &&
				  (cardValues[selector.name] as string)?.startsWith('[')
					? (JSON.parse(cardValues[selector.name] as string) as string[])
					: (cardValues[selector.name] as string[])
				: []

			return selector?.options?.filter((option) =>
				parsed?.includes(option.value),
			)
		} else {
			return selector?.options?.find(
				(option) =>
					cardValues[selector.name] &&
					cardValues[selector.name] === option.value,
			)
		}
	}, [hairColorSelector, cardValues, selector.name, selector?.options])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}>
			<span className="caption-m inputLabel">{t(selector.label)}</span>
			<div
				style={{
					width: '100%',
					gap: '12px',
					display: 'grid',
					gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
				}}>
				{selector?.options?.map((option) => (
					<ColorSelectButton
						key={option.value}
						checked={
							Array.isArray(value)
								? !!value?.find((v) => v?.value === option.value)
								: value?.value === option?.value
						}
						onChange={() => {
							const { setCardValues, setGalleryType } =
								usePortfolioStore.getState()
							setGalleryType({
								value: option.value,
								label: option.label,
							})

							let newValue = option.value
							if (hairColorSelector) {
								const array = Array.isArray(value) ? value : []
								const checked = array?.find((v) => v?.value === option.value)
								const newValues = checked
									? array
											?.map((a) => a.value)
											?.filter((v) => v !== option.value)
									: [...array.map((a) => a.value), option.value]

								newValue = JSON.stringify(newValues)
							}

							// updateFields([
							// 	{
							// 		Name: selector.name,
							// 		Value: newValue,
							// 	},
							// ])

							setCardValues({
								[selector.name]: newValue,
							})
						}}
						label={option.label}
						hint={option.hint}
						color={option.color}
					/>
				))}
			</div>
		</div>
	)
}

export default ColorsSelector
