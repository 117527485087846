import { useShallow } from 'zustand/react/shallow'
import Tooltip from 'components/Tooltip'
import { usePlayerStore } from 'store'
import { PLAYER_SPEED } from 'types/enums'
import s from '../../index.module.scss'

interface Props {
	videoElement: HTMLMediaElement | null | undefined
}

const Speed = ({ videoElement }: Props): JSX.Element => {
	const [speed, setValue] = usePlayerStore(
		useShallow((state) => [state.speed, state.setValue]),
	)

	const onSpeedClick = (): void => {
		const current = PLAYER_SPEED.findIndex((v) => v === speed)
		let next = speed
		if (PLAYER_SPEED[current + 1]) next = PLAYER_SPEED[current + 1]
		else next = PLAYER_SPEED[0]

		if (videoElement) videoElement.playbackRate = next
		setValue({ speed: next })
	}

	return (
		<Tooltip title="APP_SPEED_CONTROL">
			<div className={s.control} onClick={onSpeedClick}>
				<span
					className="body2-m"
					style={{
						color: 'var(--mono300)',
						width: '24px',
						height: '24px',
						alignContent: 'center',
						textAlign: 'center',
					}}>
					{`${speed}x`}
				</span>
			</div>
		</Tooltip>
	)
}

export default Speed
