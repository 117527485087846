import { CSSProperties, useEffect, useRef } from 'react'
import { useOutsideClick } from 'hooks'
import { useAppStore } from 'store'
import s from './index.module.scss'

interface Props {
	onClose: () => void
	children: JSX.Element
	style?: CSSProperties
}

const Modal = ({ children, onClose, style }: Props): JSX.Element => {
	const modalRef = useRef(null)

	useOutsideClick(modalRef, () => {
		const { popupVisible } = useAppStore.getState()
		if (!popupVisible) onClose()
	})

	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = 'initial'
		}
	}, [])

	return (
		<div className={s.container} style={style}>
			<div className={s.wrapper} ref={modalRef}>
				{children}
			</div>
		</div>
	)
}

export default Modal
