import { RefObject, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { generateAIImages } from 'api/content'
import Input from 'components/UI/Input'
import Button from 'components/UI/Button'
import Textarea from 'components/UI/Textarea'
import Tooltip from 'components/Tooltip'
import { t } from 'helpers'
import { notif } from 'helpers/notif'
import s from './index.module.scss'

interface Props {
	predefinedText?: string
	onChange: (fileUrl: string) => void
	inputType?: 'input' | 'textarea'
	textareaRows?: number
	setLoading: (loading: boolean) => void
}

const GenerateImage = ({
	predefinedText,
	inputType = 'input',
	onChange,
	textareaRows,
	setLoading,
}: Props): JSX.Element => {
	const generateRef = useRef<HTMLInputElement>(null)
	const [images, setImages] = useState<{ url: string }[]>([])

	const generate = async (keyword: string): Promise<void> => {
		try {
			setLoading(true)
			const response = await generateAIImages(keyword)
			if (!response.isError) setImages(response.data?.map((d) => d))
			else notif('error', response.error?.toString() || '')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (predefinedText && generateRef.current) {
				generateRef.current.value = predefinedText
			}
		}, 500)
	}, [predefinedText])

	return (
		<div className={s.container}>
			<div className={s.inputWrapper}>
				{inputType === 'input' ? (
					<Input
						name="key-word"
						label="APP_GENERATE_IMAGE"
						inputRef={generateRef}
					/>
				) : (
					<Tooltip title="APP_GENERATEIMAGES_TEXTAREA_HINT">
						<Textarea
							inputRef={
								generateRef as unknown as RefObject<HTMLTextAreaElement>
							}
							name="key-word"
							label="APP_GENERATE_IMAGE"
							rows={textareaRows || 5}
						/>
					</Tooltip>
				)}
				<Tooltip title="APP_GENERATEIMAGES_BUTTON_HINT" placement="top">
					<Button
						variant="themed"
						onClick={() => {
							if (
								generateRef.current?.value &&
								generateRef.current?.value?.length >= 3
							) {
								generate(generateRef.current.value)
							} else {
								notif('info', t('APP_FILL_FIELDS'))
							}
						}}
						style={{
							margin: 'auto',
						}}>
						{t('APP_GENERATE')}
					</Button>
				</Tooltip>
			</div>
			<div className={cn(s.wrapper)}>
				<div className="grid2column">
					{images?.map((image) => (
						<div
							className={s.image}
							key={image.url}
							onClick={() => {
								if (!image.url.includes('placeholder')) onChange(image.url)
							}}>
							<div>
								<span>{'+ ' + t('APP_SELECT_IMAGE')}</span>
							</div>
							<img src={image?.url} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default GenerateImage
