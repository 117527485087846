import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import cn from 'classnames'
import { useRef } from 'react'
import Card from '../Card'
import { t } from 'helpers'
import s from '../Card/index.module.scss'

interface Props {
	cards: {
		title: string
		desc: string
		image: string
		main: boolean
		imageStyle: {
			filter: string
			height: string
		}
	}[]
}

const MobileCards = ({ cards }: Props): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()

	const mainCard = cards?.find((card) => card.main)

	return (
		<>
			{mainCard ? (
				<div className={cn(s.item, s.mainCard)}>
					<div className={s.content}>
						<h3>{t(mainCard.title)}</h3>
						<img src={mainCard.image} alt="home-carousel" />
						<span>{t(mainCard.desc)}</span>
					</div>
				</div>
			) : null}
			<Swiper
				spaceBetween={20}
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper
				}}
				slidesPerView={1.1}
				style={{ width: '100%' }}
				className={s.swiper}>
				{cards
					?.filter((card) => !card.main)
					.map((card) => (
						<SwiperSlide key={card.title}>
							<Card card={card} />
						</SwiperSlide>
					))}
			</Swiper>
		</>
	)
}

export default MobileCards
