import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { sendEmail } from 'api/auth'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import PhoneInput from 'components/UI/PhoneInput'
import { t } from 'helpers'
import { basicFields, contactFields } from 'pages/Portfolio/fields'
import { notif } from 'helpers/notif'

const agencyFields = [
	basicFields.name,
	contactFields.email,
	contactFields.website,
	contactFields.phone,
]

const directorFields = [
	basicFields.name,
	contactFields.imdb,
	contactFields.email,
	contactFields.phone,
]

const RegisterAsSidebar = (): JSX.Element => {
	const { search } = useLocation()
	const navigate = useNavigate()
	const [values, setValues] = useState<{ [key: string]: string }>({})

	const type = new URLSearchParams(search).get('as')

	const fields = type === 'agency' ? agencyFields : directorFields

	useEffect(() => {
		if (!type) setValues({})
	}, [type])

	return (
		<Sidebar
			open={type === 'agency' || type === 'director'}
			onClose={() => {
				navigate(window.location.pathname)
			}}
			header={{
				title: `APP_REGISTER_AS_${type?.toUpperCase()}_SIDEBAR_TITLE`,
				description: `APP_REGISTER_AS_${type?.toUpperCase()}_SIDEBAR_DESCRIPTION`,
			}}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: async () => {
						try {
							const body = `New ${type} registration request<br />Local Date<br />${new Date().toISOString()}${fields?.map(
								(field) => `<br />${t(field.label)}<br />${values[field.name]}`,
							)}`
							await sendEmail(
								'New registration request' +
									' at ' +
									dayjs().format('MM-DD-YYYY HH:mm'),
								body,
							)
							notif('success', t('APP_SENDED_REQUEST_SUCCESSFULLY'))
						} catch (error) {
							console.log('error:', error)
							notif('error', t('APP_SOMETHING_WENT_WRONG'))
						} finally {
							navigate(window.location.pathname)
						}
					},
					disabled:
						Object.values(values)?.filter((v) => !!v)?.length !==
						fields?.length,
				},
			]}>
			<>
				{fields?.map((field) =>
					field.type === 'phone' ? (
						<PhoneInput
							key={field.name}
							label={field.label}
							name={field.name}
							onPhoneChange={(phone) => {
								if (phone && phone !== '') {
									setValues((prev) => ({
										...prev,
										[field.name]: phone === '+' ? '' : phone,
									}))
								}
							}}
							defaultValue={''}
						/>
					) : (
						<Input
							key={field.name}
							name={field.name}
							label={field.label}
							onChange={(e) => {
								setValues((prev) => ({
									...prev,
									[field.name]: e.target.value,
								}))
							}}
						/>
					),
				)}
			</>
		</Sidebar>
	)
}

export default RegisterAsSidebar
