import { FileIcon, HDIcon } from 'assets/icons'
import Hint from 'components/Wizard/Hint'
import { t } from 'helpers'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props {
	form: FileFormVariant
	itemTitle: string
	hintText: string
	formatsText: string
	qualityText: string
}

const FileUploadHints = ({
	form,
	itemTitle,
	hintText,
	formatsText,
	qualityText,
}: Props): JSX.Element => {
	const verticalInfo = ['circle-s', 'landscape', 'portrait'].includes(form)

	return (
		<div
			className={s.info}
			style={
				['rectangle-h', 'portrait-v'].includes(form)
					? {
							width: '100%',
					  }
					: {}
			}>
			<h3 className="h3-m">{t(itemTitle)}</h3>
			<div
				className={s.desc}
				style={
					verticalInfo
						? { flexDirection: 'column', alignItems: 'flex-start' }
						: ['rectangle-h', 'portrait-v'].includes(form)
						? {
								justifyContent: 'space-around',
						  }
						: {}
				}>
				<div>
					<div style={{ display: 'flex' }}>
						<HDIcon />
					</div>
					<span className="caption-m">{t(qualityText)}</span>
				</div>
				<div>
					<div style={{ display: 'flex' }}>
						<FileIcon />
					</div>
					<span className="caption-m">{t(formatsText)}</span>
				</div>
			</div>

			<Hint hintText={t(hintText)} />
		</div>
	)
}

export default FileUploadHints
